import React, { Component, Fragment } from "react";
import Global from "../Global.json";
import Img from 'react-cool-img'
import Block from '../Pages/ProductThumb';
import Skeleton from 'react-loading-skeleton';
import swal from 'sweetalert';
export default class Search extends Component {
  constructor(props) {
    super(props)
    // the initial application state
    this.state = {
      rating: 3,
      DataArray: [],
      user_id: localStorage.getItem('user_id') ? localStorage.getItem('user_id') : 0,
      isPageLoad: true,
      Key: props.match.params.key,
      CatID: props.match.params.CatID,
      Search: [],
      Options: [],
      language: localStorage.getItem('language') ? localStorage.getItem('language') : "english",
      //SearchKey: localStorage.getItem("SearchKey") ? localStorage.getItem("SearchKey") : "Sooq",

    }
  }

  componentWillMount() {
    if (this.state.language != "english") {
      this.setState({
        i18n: Global.ar
      });
    } else {
      this.setState({
        i18n: Global.en
      });
    } 

    this.Search()
  }


  Search() {
    /** get SearchR esult */
    fetch(Global.api_link + "getSearchResult", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        token: Global.token,
        lang: this.state.language,
        key: this.state.Key,
        CatID: this.state.CatID,
        user_id: this.state.user_id
      })
    })
      .then(response => response.json())
      .then(result => {
        this.setState(
          {
            Search: result.data,
            isPageLoad: false
          },
          () => {
            
          }
        );
      });
    /** get SearchR esult */
  }
  /** Convert Content to HTML */
  MyComponent(desc) {
    return <div dangerouslySetInnerHTML={this.createMarkup(desc)} />;
  }

  createMarkup(desc) {
    return { __html: desc };
  }
  /** Convert Content to HTML End*/
  render() {
    return (
      <div> {/** Wrapper Start */}
        <div>
          <div className="container"> {/** Categories Section  Start*/}
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 no_pad SectionSearch">
                <div>
                  <a><h1 style={{textAlign: "center"}}>{this.state.Key}</h1></a>
                </div>

                {this.state.isPageLoad ?
                  <>
                    <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 CardProduct">
                      <Fragment>
                        <Skeleton height={280} />
                        <Skeleton height={50} />
                      </Fragment>
                    </div>

                    <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 CardProduct">
                      <Fragment>
                        <Skeleton height={280} />
                        <Skeleton height={50} />
                      </Fragment>
                    </div>


                    <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 CardProduct">
                      <Fragment>
                        <Skeleton height={280} />
                        <Skeleton height={50} />
                      </Fragment>
                    </div>


                    <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 CardProduct">
                      <Fragment>
                        <Skeleton height={280} />
                        <Skeleton height={50} />
                      </Fragment>
                    </div>


                    <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 CardProduct">
                      <Fragment>
                        <Skeleton height={280} />
                        <Skeleton height={50} />
                      </Fragment>
                    </div>


                    <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 CardProduct">
                      <Fragment>
                        <Skeleton height={280} />
                        <Skeleton height={50} />
                      </Fragment>
                    </div>

                    <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 CardProduct">
                      <Fragment>
                        <Skeleton height={280} />
                        <Skeleton height={50} />
                      </Fragment>
                    </div>

                    <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 CardProduct">
                      <Fragment>
                        <Skeleton height={280} />
                        <Skeleton height={50} />
                      </Fragment>
                    </div>
                  </>

                  :
                  this.state.Search.length > 0 ?
                    this.state.Search.map(offers => {
                      return (
                        <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 CardProduct">
                          <Block id={offers.id} title={offers.title} favor={offers.is_favor} price={offers.price} image={offers.image} onPress={() => {
                            this.setState({
                              Options: []
                            }, () => {
                              this.Options(offers.id);
                            })
                          }} onClickFavor={() => {
                            this.AddToFavour(offers.id)
                          }} />
                        </div>
                      )
                    }) : <h2 className="NoData text-center">{this.state.i18n.NoData}</h2>}


                {/** Add To Cart Modal Start */}

                <div className="modal fade CartModal" id="myModal">
                  <div className="modal-dialog">
                    <div className="modal-content">
                      <div className="modal-header">
                        <button
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-hidden="true"
                        >
                          ×
                                                         </button>
                        <h5 className="modal-title">
                          {this.state.i18n.AddToCart}
                        </h5>
                      </div>
                      <div className="container"></div>
                      <div className="modal-body">
                        <ul className="OptionCart">
                          {this.state.Options.map(
                            (option) => {
                              return (
                                <div>
                                  <li key={option.id}>
                                    <h5>
                                      {option.title}
                                      <br /> <br /> 
                                    </h5>
                                    <a
                                      onClick={() =>
                                        this.state.user_id !== "0" ? this.AddToCart(option.pid) : window.location.href="/Login" 
                                      }
                                    >
                                      <div>
                                        {/* {this.state.i18n.JOD} */}
                                        {localStorage.getItem("Currency")}
                                        {(option.price*localStorage.getItem("CurrentCurrency")).toFixed(2)}
                                      </div>
                                    </a>
                                  </li>
                                </div>
                              );
                            }
                          )}
                        </ul>
                      </div>

                      <div className="modal-footer">
                        <a
                          className="btn btn-primary ResPas"
                          data-dismiss="modal"
                        >
                          {this.state.i18n.ContinueShopping}
                        </a>

                        <a
                          className="btn btn-primary ResPas"
                          onClick={() => window.location.href = "/Cart"}
                        >
                          {this.state.i18n.GoToCart}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                {/** ِAdd To Cart  Modal End */}


              </div>
            </div>
          </div>
        </div> {/** Categories Section  End*/}
      </div> /** Wrapper End */
    );
  }




  /************ Functions ***********/
  Options(PID) {
    fetch(Global.api_link + "OfferDetails", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: Global.token,
        lang: this.state.language,
        offer_id: PID,
        user_id: this.state.user_id,
      }),
    })
      .then((response) => response.json())
      .then((result) => {
        this.setState({
          Options: result.Options,
          isPageLoad: false,
          PID: PID,
        });
        //console.log(result);
      });
  }


  AddToFavour(id) {
    fetch(Global.api_link + "AddRemoveFavorite", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        token: Global.token,
        pid: id,
        uid: this.state.user_id
      })
    })
      .then(response => response.json())
      .then(result => {
        swal({
          title: this.state.i18n.DoneSuccess,
          text: this.state.i18n.AddedToFavourite,
          icon: "success",
          button: this.state.i18n.OK,
        });
        this.Search()
        setTimeout(100);
      });
  }

  CartCount() {
    fetch(Global.api_link + "getCartCount", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        token: Global.token,
        user_id: this.state.user_id
      })
    })
      .then(response => response.json())
      .then(result => {
        this.setState(
          {
            cartNum: result.data
          },
          () => { }
        );
      });
  }

  AddToCart(id) {
    fetch(Global.api_link + "addToCart", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        token: Global.token,
        product_id: id,
        user_id: this.state.user_id,
        qty: 1
      })
    })
      .then(response => response.json())
      .then(result => {
        swal({
          title: this.state.i18n.DoneSuccess,
          text: this.state.i18n.ProductCart,
          icon: "success",
          button: this.state.i18n.OK,
        })

        this.CartCount()
      });
  }


}/** Home Page End */


