import React, { Component } from "react";
import Img from "react-cool-img";
import Global from "../Global.json";
import swal from "sweetalert";
import { GoogleApiWrapper, Map, Marker, InfoWindow } from "google-maps-react";
import LocationPicker from "react-location-picker";
import Skeleton from "react-loading-skeleton";

/* Default position */
let defaultPosition = {
  lat: 31.963158,
  lng: 35.930359,
};
let isLoadMap = true;
export class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user_id: localStorage.getItem("user_id")
        ? localStorage.getItem("user_id")
        : 0,
      Done2: false,
      Fill2: false,
      CityAreas: [],
      Fill: false,
      address: "Jordan",
      area: [],
      Cities: [],
      position: {
        lat: 0,
        lng: 0,
      },
      AddLoc: false,
      area: [],
      Cities: [],
      IsCartDone: true,
      ShowAlert: false,
      Latitude: 31.963158,
      Long: 35.930359,
      AllCart: [],
      AllOrders: [],
      OrderDetails: [],
      TotalPrice: 0,
      QTYCart: 1,
      AllFav: [],
      Options: [],
      IFavDone: true,
      OrderIDDetails: 0,
      language: localStorage.getItem("language")
        ? localStorage.getItem("language")
        : "english",
    };

    // binding this to event-handler functions
    this.onMarkerClick = this.onMarkerClick.bind(this);
    this.onMapClick = this.onMapClick.bind(this);
    // Bind
    this.handleLocationChange = this.handleLocationChange.bind(this);
  }

  Change() {
    localStorage.removeItem("language");
    localStorage.setItem("language", "arabic");
    window.location.reload();
  }

  ChangeEn() {
    localStorage.removeItem("language");
    localStorage.setItem("language", "english");
    window.location.reload();
  }

  handleLocationChange({ position, address, places }) {
    // Set new location
    this.setState({ position, address });
    // console.log(this.state.position)
  }

  onMarkerClick = (props, marker, e) => {
    this.setState({
      selectedPlace: props,
      activeMarker: marker,
      showingInfoWindow: true,
      windowProps: props,
    });
  };
  onMapClick = (props) => {
    if (this.state.showingInfoWindow) {
      this.setState({
        showingInfoWindow: false,
        activeMarker: null,
      });
    }
  };

  componentWillMount() {
    if (
      this.state.user_id != 0 &&
      this.state.user_id &&
      this.state.user_id != null &&
      this.state.user_id != "null"
    ) {
      if (this.state.language != "english") {
        this.setState({
          i18n: Global.ar,
        });
      } else {
        this.setState({
          i18n: Global.en,
        });
      }
      this.getUser();
    } else {
      localStorage.removeItem("user_id");
      localStorage.setItem("user_id", 0);
      window.location.href = "/Login";
    }
  }

  getUser() {
    fetch(Global.api_link + "getUser", {
      method: "POST",
      headers: {
        Accept: "application/json",
      },
      body: JSON.stringify({
        token: Global.token,
        user_id: this.state.user_id,
      }),
    })
      .then((response) => response.json())
      .then((result) => {
        this.setState({
          name: result.data.name,
          email: result.data.email,
          image: result.data.image,
          phone: result.data.phone,
          Gender: result.data.gender,
          face_image: result.data.face_image,
          //DOB: result.data.dob,
          imageOld: result.data.image,
          isPageLoad: false,
        });

        this.getUserArea();
        this.getCities();
      })
      .catch((error) => {
        console.error(error);
      });
  }

  getUserArea() {
    fetch(Global.api_link + "getUserArea", {
      method: "POST",
      headers: {
        Accept: "application/json",
      },
      body: JSON.stringify({
        token: Global.token,
        user_id: this.state.user_id,
        lang: this.state.language,
      }),
    })
      .then((response) => response.json())
      .then((result) => {
        this.setState({
          area: result.data,
        });
      })
      .catch((error) => {
        //console.error(error);
      });
  }

  getCities() {
    fetch(Global.api_link + "getCities", {
      method: "POST",
      headers: {
        Accept: "application/json",
      },
      body: JSON.stringify({
        token: Global.token,
        lang: this.state.language,
      }),
    })
      .then((response) => response.json())
      .then((result) => {
        this.setState({
          Cities: result.data,
        });
      })
      .catch((error) => {
        //console.error(error);
      });
  }

  /** Convert Content to HTML */
  MyComponent(desc) {
    return <div dangerouslySetInnerHTML={this.createMarkup(desc)} />;
  }

  createMarkup(desc) {
    return { __html: desc };
  }
  /** Convert Content to HTML End*/

  render() {
    return (
      <div className="profile-page-container">
        {" "}
        {/** Wrapper Div  Start */}
        <div>
          {" "}
          {/** Profile Section Start */}
          <div className="container">
            <div className="row">
              <div className="col-lg-12  col-md-12 col-sm-12 col-xs-12  Profile no_pad">
                <div className="ProfileBackImage">
                  <div>
                    <Img
                      src={
                        this.state.image !== null && this.state.image !== ""
                          ? Global.image_link + this.state.image
                          : this.state.face_image !== null &&
                            this.state.face_image !== ""
                          ? this.state.face_image
                          : "img/nopic.png"
                      }
                    />
                  </div>
                  <h2>{this.state.name}</h2>
                </div>

                <div className="col-lg-12  col-md-12 col-sm-12 col-xs-12  no_pad">
                  <div className="">
                    <div className="tab-content " id="tabs">
                      {" "}
                      {/**  Tabs Contect Start  */}
                      <div className=" tab-pane active Information" id="info">
                        {" "}
                        {/** Informaion Tab Start  */}
                        <div className="col-md-9 col-sm-9 col-xs-12 no_pad">
                          <div className="BoxData">
                            <h3>{this.state.i18n.MYINFORMATION}</h3>
                            <div className="RowFormInfo">
                              <div className=" form-group">
                                <label for="exampleInputEmail1">
                                  {this.state.i18n.Name}
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="exampleInputEmail1"
                                  value={this.state.name}
                                  aria-describedby="emailHelp"
                                  placeholder={this.state.i18n.Name}
                                  onChange={(name) => {
                                    this.setState({ name: name.target.value });
                                  }}
                                />
                              </div>

                              <div className=" form-group">
                                <label>{this.state.i18n.Phone} </label>
                                <input
                                  className="form-control"
                                  type="text"
                                  name="phone"
                                  placeholder={this.state.i18n.Phone}
                                  value={this.state.phone}
                                  onChange={(phone) => {
                                    this.setState({
                                      phone: phone.target.value,
                                    });
                                  }}
                                />
                              </div>
                            </div>

                            <div className="RowFormInfo">
                              <div className=" form-group">
                                <label>{this.state.i18n.Email}</label>
                                <input
                                  className="form-control"
                                  type="email"
                                  ref="email"
                                  placeholder="name@example.com"
                                  name="email"
                                  value={this.state.email}
                                  onChange={(email) => {
                                    this.setState({
                                      email: email.target.value,
                                    });
                                  }}
                                />
                              </div>

                              <div className=" form-group">
                                <label>{this.state.i18n.ProfileImage}</label>
                                <div className="custom-file">
                                  <input
                                    type="file"
                                    className="custom-file-input"
                                    id="inputGroupFile02"
                                    name="file"
                                    onChange={(e) => {
                                      this.setState({
                                        file: e.target.files[0],
                                        image: e.target.value,
                                      });
                                    }}
                                  />
                                  <label
                                    className="custom-file-label"
                                    for="inputGroupFile02"
                                    aria-describedby="inputGroupFileAddon02"
                                  >
                                    {" "}
                                    Ex: upload .png, .jpg
                                  </label>
                                </div>
                              </div>
                            </div>

                            <div className="ProfileSubmit">
                              <button
                                type="submit"
                                className="btn btn-primary"
                                onClick={() =>
                                  this.setState({ isPageLoad: true }, () =>
                                    this.uploadImage()
                                  )
                                }
                              >
                                {this.state.i18n.Edit}
                              </button>
                            </div>
                          </div>

                          <div className="BoxData">
                            <h3>{this.state.i18n.ChangePassword}</h3>
                            <div className="RowFormInfo">
                              <div className="form-group ">
                                <label>{this.state.i18n.NewPassword} </label>
                                <input
                                  className="form-control"
                                  type="password"
                                  name="phone"
                                  placeholder={this.state.i18n.NewPassword}
                                  onChange={(e) => {
                                    this.setState({ Password: e.target.value });
                                  }}
                                />
                              </div>

                              <div className="form-group NewPass ">
                                <label>
                                  {" "}
                                  {this.state.i18n.RewritePassword}{" "}
                                </label>
                                <input
                                  className="form-control"
                                  type="password"
                                  name="phone"
                                  placeholder={this.state.i18n.RewritePassword}
                                  onChange={(e) => {
                                    this.setState({
                                      NewPassword: e.target.value,
                                    });
                                  }}
                                />
                                {this.state.Password != null ? (
                                  this.state.Password ==
                                  this.state.NewPassword ? (
                                    <h5>
                                      <i className="fa fa-check"></i>
                                    </h5>
                                  ) : (
                                    <h5>
                                      <i className="fa fa-times"></i>
                                    </h5>
                                  )
                                ) : null}
                              </div>
                            </div>

                            <div className="ProfileSubmit">
                              <button
                                type="submit"
                                className="btn btn-primary"
                                onClick={() =>
                                  this.setState({ isPageLoad: true }, () =>
                                    this.ChangePass()
                                  )
                                }
                              >
                                {this.state.i18n.Edit}
                              </button>
                            </div>
                          </div>

                          <div className="BoxData">
                            {" "}
                            {/** Address Box Start */}
                            <h3>
                              {this.state.i18n.MyADDRESSES}{" "}
                              <span
                                data-toggle="modal"
                                data-target={"#myLocationModal"}
                                onClick={() => {
                                  this.setState({
                                    selectedCity:null,
                                     selectedArea : null ,
                                     LocationName: '',
                                     building: '',
                                     Floor: '',
                                     Appartment : ''
                                    // latitude: this.state.position.lat,
                                    // longitude: this.state.position.lng,
                                  });
                                }}
                              >
                                {this.state.i18n.AddNewLoc}
                              </span>
                            </h3>
                            <div className="table-responsive">
                              <table className="table table-borderless">
                                {" "}
                                {/** Address Table Start */}
                                {this.state.area.length > 0 ? (
                                  <>
                                    <thead>
                                      <tr>
                                        <th scope="col">
                                          {this.state.i18n.Name}
                                        </th>
                                        <th scope="col">
                                          {this.state.i18n.Area}
                                        </th>
                                        <th scope="col">
                                          {this.state.i18n.HomeAddress}
                                        </th>
                                        <th scope="col"></th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {this.state.area.map((area) => {
                                        return (
                                          <tr key={area.id}>
                                            <th scope="row">{area.name}</th>
                                            <td>{area.areaname}</td>
                                            <td>{area.address} </td>
                                            <td>
                                              <div className="float-r">
                                                <a
                                                  data-toggle="modal"
                                                  data-target={"#myModal"}
                                                  className="MapA"
                                                  onClick={() =>
                                                    this.setState({
                                                      Latitude: area.latitude,
                                                      Long: area.longitude,
                                                      LocName: area.name,
                                                    })
                                                  }
                                                >
                                                  {" "}
                                                  {this.state.i18n.ShowOnGoogle}
                                                </a>
                                                <button
                                                  onClick={() =>
                                                    this.setState(
                                                      { isPageLoad: true },
                                                      this.Delete(area.id)
                                                    )
                                                  }
                                                  type="submit"
                                                  className="btn btn-primary"
                                                >
                                                  <img
                                                    alt="edit"
                                                    src="img/delete.png"
                                                    width="47px"
                                                  />
                                                </button>
                                              </div>
                                            </td>
                                          </tr>
                                        );
                                      })}
                                    </tbody>
                                  </>
                                ) : (
                                  <div className="col-md-12 col-sm-12 col-xs-12 no_pad EmptyCart ">
                                    <Img src="img/lost.png" />
                                  </div>
                                )}
                              </table>
                            </div>{" "}
                            {/** Address Table End */}
                          </div>
                          {/** Address Box End */}
                        </div>
                        {/**  Edit Location  Modal Start */}
                        <div
                          className="modal fade LocationModal "
                          id="myLocationModal"
                        >
                          <div className="modal-dialog">
                            <div className="modal-content">
                              <div className="modal-header">
                                <button
                                  type="button"
                                  className="close"
                                  data-dismiss="modal"
                                  aria-hidden="true"
                                >
                                  <span>

                                  ×
                                  </span>
                                </button>
                                <h5 className="modal-title">
                                  {this.state.i18n.AddNewLoc}
                                </h5>
                              </div>
                              <div className="container"></div>
                              <div className="modal-body modal-body-Verification">
                                <div className="row">
                                  <br />

                                  <div className="form-group col-md-6 col-sm-6 col-xs-12">
                                    <label>{this.state.i18n.City}</label> <br />
                                    <select
                                      className="form-control"
                                      name="City"
                                      onChange={(city) => {
                                        this.getCityAreas(city.target.value);
                                        this.setState({
                                          selectedCity: city.target.value,
                                          city_id_selected: city.target.value
                                        });
                                        this.getCityAreas(city.target.value);
                                        this.getAreaName(city.target.value);
                                      }}
                                    >
                                      <option value={0}>
                                        {this.state.i18n.Choose}
                                      </option>
                                      {this.state.Cities.map((city) => {
                                        return (
                                          <option
                                            key={city.id}
                                            value={city.id}
                                            selected={
                                              city.id == this.state.selectedCity
                                            }
                                          >
                                            {city.name}
                                          </option>
                                        );
                                      })}
                                    </select>
                                  </div>

                                  <div className="form-group col-md-6 col-sm-6 col-xs-12">
                                    <label>{this.state.i18n.Area}</label> <br />
                                    <select
                                      className="form-control"
                                      name="Area"
                                      onChange={(area) =>
                                       { 
                                        this.setState({
                                          AreaCity: area.target.value,
                                          selectedArea: area.target.value,
                                        })
                                        this.getAreaName2(area.target.value, this.state.city_id_selected);
                                       }
                                      }
                                    >
                                      <option value={0}>
                                        {this.state.i18n.SelectAre}
                                      </option>
                                      {this.state.CityAreas.map((area) => {
                                        return (
                                          <option key={area.id} value={area.id}  selected={
                                            area.id == this.state.selectedArea
                                          }>
                                            {area.name}
                                          </option>
                                        );
                                      })}
                                    </select>
                                  </div>

                                  <div className="form-group  col-md-6 col-sm-6 col-xs-12">
                                    <label>{this.state.i18n.LocName}</label>
                                    <input
                                      className="form-control"
                                      type="text"
                                      ref="name"
                                      name="name"
                                      onChange={(name) => {
                                        this.setState({
                                          LocationName: name.target.value,
                                        });
                                      }}
                                      value={this.state.LocationName}
                                    />
                                  </div>

                                  <div className="form-group  col-md-6 col-sm-6 col-xs-12">
                                    <label>{this.state.i18n.building}</label>
                                    <input
                                      className="form-control"
                                      type="text"
                                      ref="Building"
                                      name="Building"
                                      onChange={(building) => {
                                        this.setState({
                                          building: building.target.value,
                                        });
                                      }}
                                      value={this.state.building}
                                    />
                                  </div>

                                  <div className="form-group  col-md-6 col-sm-6 col-xs-12">
                                    <label>{this.state.i18n.Floor}</label>
                                    <input
                                      className="form-control"
                                      type="text"
                                      ref="Floor"
                                      name="Floor"
                                      onChange={(Floor) => {
                                        this.setState({
                                          Floor: Floor.target.value,
                                        });
                                      }}
                                      value={this.state.Floor}

                                    />
                                  </div>

                                  <div className="form-group  col-md-6 col-sm-6 col-xs-12">
                                    <label>{this.state.i18n.Appartment}</label>
                                    <input
                                      className="form-control"
                                      type="text"
                                      ref="Appartment"
                                      name="Appartment"
                                      onChange={(Appartment) => {
                                        this.setState({
                                          Appartment: Appartment.target.value,
                                        });
                                      }}
                                      value={this.state.Appartment}

                                    />
                                  </div>

                                  <div className="form-group  col-md-12 col-sm-12 col-xs-12">
                                    <LocationPicker
                                      containerElement={
                                        <div
                                          style={{
                                            height: "100%",
                                          }}
                                        />
                                      }
                                      mapElement={
                                        <div
                                          style={{
                                            height: "400px",
                                          }}
                                        />
                                      }
                                      zoom={15}
                                      defaultPosition={defaultPosition}
                                      onChange={this.handleLocationChange}
                                      circleOptions={{
                                        visible: false,
                                      }}
                                    />
                                  </div>

                                  <br />
                                  {this.state.Fill ? (
                                    <div className="form-group  col-md-12 col-sm-12 col-xs-12 Done Wrong ">
                                      <span>{this.state.i18n.Required}</span>
                                    </div>
                                  ) : null}
                                </div>
                              </div>

                              <div className="modal-footer">
                                <a
                                  className="btn btn-primary ResPas"
                                  onClick={() => this.AddArea()}
                                >
                                  {this.state.i18n.Add}
                                </a>

                                <a
                                  className="btn btn-primary ResPas"
                                  data-dismiss="modal"
                                >
                                  {this.state.i18n.Close}
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/** Edit Location  Modal End */}
                        {/** Map Modal Start */}
                        <div className="modal fade" id="myModal">
                          <div className="modal-dialog">
                            <div className="modal-content">
                              <div className="modal-header modal-map-header">
                                <button
                                  type="button"
                                  className="close"
                                  data-dismiss="modal"
                                  aria-hidden="true"
                                  style={{marginRight: "0 !important"}}
                                >
                                  ×
                                </button>
                                <h5 className="modal-title">
                                  {this.state.LocName}
                                </h5>
                              </div>
                              <div className="container"></div>
                              <div className="modal-body modal-body-Verification ">
                                <Map
                                  google={this.props.google}
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                  }}
                                  className={"map"}
                                  zoom={12}
                                  initialCenter={{
                                    lat: this.state.Latitude,
                                    lng: this.state.Long,
                                  }}
                                >
                                  <Marker
                                    key={1}
                                    title={this.state.LocName}
                                    name={<div>{this.state.LocName}</div>}
                                    position={{
                                      lat: this.state.Latitude,
                                      lng: this.state.Long,
                                    }}
                                    icon={"/img/maker2.png"}
                                    style={{
                                      width: 20,
                                      height: 20,
                                    }}
                                    onClick={this.onMarkerClick}
                                  />

                                  <InfoWindow
                                    marker={this.state.activeMarker}
                                    visible={this.state.showingInfoWindow}
                                  >
                                    {this.state.windowProps ? (
                                      <div>
                                        <a>{this.state.windowProps.name}</a>
                                      </div>
                                    ) : null}
                                  </InfoWindow>
                                </Map>
                              </div>

                              <div className="modal-footer">
                                <a
                                  className="btn btn-primary ResPas"
                                  data-dismiss="modal"
                                >
                                  {this.state.i18n.Close}
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/**Map  Modal End */}
                        <div className="col-md-3 col-sm-3 col-xs-12 Ads">
                          {/**  Ads Image End  */}
                          <a href="/Categories/__All">
                            <Img src="img/Group643.png" width="100%" />
                          </a>
                        </div>
                        {/** Ads Image End */}
                      </div>{" "}
                      {/** Informaion Tab End   */}
                    </div>
                  </div>{" "}
                  {/**  Tabs Contect End   */}
                </div>
              </div>
            </div>
          </div>
        </div>{" "}
        {/** Profile Section End */}
      </div> /** Wrapper Div  End */
    );
  }

  /************* Functions  ******************/

  uploadImage() {
    if (this.state.image != null) {
      let localUri = this.state.image;
      let filename = localUri.split("/").pop();

      // Infer the type of the image
      let match = /\.(\w+)AED/.exec(filename);
      let type = match ? `image/AED{match[1]}` : `image`;

      // Upload the image using the fetch and FormData APIs
      let formData = new FormData();
      formData.append("photo", this.state.file);

      fetch(Global.api_link + "uploadImage", {
        method: "POST",

        body: formData,
      })
        .then((response) => response.json())
        .then((result) => {
          this.setState(
            {
              image: result,
              //isPageLoad: true
            },
            () => {
              this.EditProfile();
            }
          );
        });
    } else {
      this.EditProfile();
    }
  }


  phoneNumberValidation (input) {
    const regx = /^\d{10}$/;

    return input.match(regx);
  } 

  
  /** Edit Profile */
  EditProfile() {

    if (this.state.name && this.state.email   !== null && this.phoneNumberValidation(this.state.phone)) {
      
      fetch(Global.api_link + "editUser", {
        method: "POST",
        headers: {
          Accept: "application/json",
        },
        body: JSON.stringify({
          token: Global.token,
          user_id: this.state.user_id,
          name: this.state.name,
          email: this.state.email,
          phone: this.state.phone,
          image: this.state.image ? this.state.image : this.state.imageOld,
        }),
      })
        .then((response) => response.json())
        .then((result) => {
          this.setState({
            Fill: false,
            isPageLoad: false,
          });
          swal({
            title: this.state.i18n.DoneSuccess,
            text: this.state.i18n.Done,
            icon: "success",
            button: this.state.i18n.OK,
          });
          //window.location.reload()
        });
    } else {
      this.setState({
        isPageLoad: false,
      });
      swal({
        title: this.state.i18n.Error,
        text: this.state.i18n.invalidEdit,
        icon: "warning",
        button: this.state.i18n.OK,
      });
    }
  }
  /** Edit Profile */

  /**ChangePass */
  ChangePass() {
    if (
      this.state.Password &&
      this.state.NewPassword != null &&
      this.state.Password == this.state.NewPassword
    ) {
      fetch(Global.api_link + "ChangePass", {
        method: "POST",
        headers: {
          Accept: "application/json",
        },
        body: JSON.stringify({
          token: Global.token,
          user_id: this.state.user_id,
          password: this.state.Password,
        }),
      })
        .then((response) => response.json())
        .then((result) => {
          swal({
            title: this.state.i18n.DoneSuccess,
            text: this.state.i18n.Done,
            icon: "success",
            button: this.state.i18n.OK,
          });

          this.setState({
            Password: null,
            NewPassword: null,
          });

          this.getUser();
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      this.setState({
        Done2: false,
      });

      swal({
        title: this.state.i18n.Error,
        text: this.state.i18n.WrongPass,
        icon: "warning",
        button: this.state.i18n.OK,
      });
    }
  }

  Options(PID) {
    fetch(Global.api_link + "OfferDetails", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: Global.token,
        lang: this.state.language,
        offer_id: PID,
        user_id: this.state.user_id,
      }),
    })
      .then((response) => response.json())
      .then((result) => {
        this.setState({
          Options: result.Options,
          isPageLoad: false,
          PID: PID,
        });
        //console.log(result);
      });
  }

  /** Option Price */
  OptionPrice(id) {
    this.state.Options.map((Opt) => {
      if (Opt.pid == id) {
        return this.setState({
          OfferPrice: Opt.price,
          OldPrice: Opt.old_price,
          percentage: Opt.percentage,
          OptionQty: Opt.qty,
          OptionCashBack: Opt.cashback,
          OptionDelivery: Opt.delivery,
          OptionOffer: id,
        });
      }
    });
  }

  /*** City Areas */
  getCityAreas(cid) {
    fetch(Global.api_link + "getCityAreas", {
      method: "POST",
      headers: {
        Accept: "application/json",
      },
      body: JSON.stringify({
        token: Global.token,
        lang: this.state.language,
        city_id: cid,
      }),
    })
      .then((response) => response.json())
      .then((result) => {
        this.setState({
          CityAreas: result.data,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  /** Add Area */
  AddArea() {

    if (this.state.LocationName != null && this.state.AreaCity != null && this.state.position.lat != 0) {
      fetch(Global.api_link + "AddArea", {
        method: "POST",
        headers: {
          Accept: "application/json",
        },
        body: JSON.stringify({
          token: Global.token,
          user_id: this.state.user_id,
          area_id: this.state.AreaCity,
          name: this.state.LocationName,
          address: this.state.building
            ? "Building : " + this.state.building
            : this.state.Floor
            ? "Floor : " + this.state.Floor
            : this.state.Appartment
            ? "Appartment : " + this.state.Appartment
            : "Amman",
          latitude: this.state.position.lat,
          longitude: this.state.position.lng,
        }),
      })
        .then((response) => response.json())
        .then((result) => {
          this.getUserArea();
          this.setState({
            AddLoc: false,
          });
          window.location.reload();
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      swal({
        title: this.state.i18n.Error,
        text: this.state.i18n.Required,
        icon: "warning",
        button: this.state.i18n.OK,
      });
      setTimeout(100);
    }
  }
  /** Add Area End */

  /*** Delete Area */
  Delete(id) {
    fetch(Global.api_link + "DeleteArea", {
      method: "POST",
      headers: {
        Accept: "application/json",
      },
      body: JSON.stringify({
        token: Global.token,
        user_id: this.state.user_id,
        area_id: id,
      }),
    })
      .then((response) => response.json())
      .then((result) => {
        this.setState({
          isPageLoad: false,
        });
        this.getUserArea();
      })
      .catch((error) => {
        console.error(error);
      });
  }

  /** Delete From Cart  */
  DeleteFromCart(oid) {
    fetch(Global.api_link + "DeleteFromCart", {
      method: "POST",
      headers: {
        Accept: "application/json",
      },
      body: JSON.stringify({
        token: Global.token,
        user_id: this.state.user_id,
        product_id: oid,
      }),
    })
      .then((response) => response.json())
      .then((result) => {
        this.setState({
          Loader: true,
        });
        this.Cart();
      })
      .catch((error) => {
        //console.error(error);
      });
  }
  /** Delete From Cart End */

 

  /***  Delivery Cost */
  DeliveryCost() {
    if (this.state.Area != null) {
      fetch(Global.mobile_epi_link + "getDeliveryCostNew", {
        method: "POST",
        headers: {
          Accept: "application/json",
        },
        body: JSON.stringify({
          token: Global.mobile_token,
          user_location: this.state.Area,
        }),
      })
        .then((response) => response.json())
        .then((result) => {
          this.setState({
            Cost: result.data,
            prevStoreB: result.prevStoreB,
          });
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      /*this.setState({
                Fill: true,
            });*/
      swal({
        title: this.state.i18n.Error,
        text: this.state.i18n.Required,
        icon: "warning",
        button: this.state.i18n.OK,
      });
    }
  }
  /***  Delivery Cost End */

  getUserArea() {
    fetch(Global.api_link + "getUserArea", {
      method: "POST",
      headers: {
        Accept: "application/json",
      },
      body: JSON.stringify({
        token: Global.token,
        user_id: this.state.user_id,
        lang: this.state.language,
      }),
    })
      .then((response) => response.json())
      .then((result) => {
        this.setState({
          area: result.data,
        });
      })
      .catch((error) => {
        //console.error(error);
      });
  }

  getAreaName(AreaCity) {
    fetch(Global.api_link + "getCities", {
      method: "POST",
      headers: {
        Accept: "application/json",
      },
      body: JSON.stringify({
        token: Global.token,
        lang: this.state.language,
      }),
    })
      .then((response) => response.json())
      .then((result) => {
        let areaname = "";
        result.data.map((area) => {
          if (area.id == AreaCity) {

            areaname = area.name;
            this.setState({
              AreaCityName: areaname,
              AreaCityName2:null
            }, () => {
              this.SearchOnMap()
            });
          }
        })


      })
      .catch((error) => {
        //console.error(error);
      });
  }
  getAreaName2(AreaCity,cid) {
    fetch(Global.api_link + "getCityAreas", {
      method: "POST",
      headers: {
        Accept: "application/json",
      },
      body: JSON.stringify({
        token: Global.token,
        lang: this.state.language,
        city_id: cid,
      }),
    })
      .then((response) => response.json())
      .then((result) => {
        let areaname = "";
        result.data.map((area) => {
          if (area.id == AreaCity) {

            areaname = area.name;
            this.setState({
              AreaCityName2: areaname,
            }, () => {
              this.SearchOnMap()
            });
          }
        })
 
      })
      .catch((error) => {
        //console.error(error);
      });
  }

  SearchOnMap() {
    let areaname = this.state.AreaCityName2 ? this.state.AreaCityName2 : ""
    let fullname = areaname ?  this.state.AreaCityName + ", "+areaname : this.state.AreaCityName;
    fetch("https://maps.googleapis.com/maps/api/geocode/json?address=Jordan, " + fullname + "&key=AIzaSyDwMMc_5DQ0ZZEs9Gqb1SKuVd3MOjrSsIE", {
      method: "POST",
      headers: {
        Accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((result) => {
        defaultPosition = {
          lat: result.results[0].geometry.location.lat,
          lng: result.results[0].geometry.location.lng,
        }
        // Set new location
        this.setState({ position: defaultPosition }); 
      })
      .catch((error) => {
        console.error(error);
      });
 
  }


}



export default GoogleApiWrapper({
  apiKey: "AIzaSyDwMMc_5DQ0ZZEs9Gqb1SKuVd3MOjrSsIE",
})(Profile);
