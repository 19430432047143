import React, { Component } from "react";
import Global from "../Global.json";
import swal from "sweetalert";
import { Form, FormControl, InputGroup } from "react-bootstrap";

export default class Contact extends Component {
    constructor(props) {
        super(props);
        // the initial application state
        this.state = {
            rating: 3,
            DataArray: [],
            UserPhone: "",
            Fill: false,
            user_id: localStorage.getItem("user_id")
                ? localStorage.getItem("user_id")
                : 0,
            language: localStorage.getItem("language")
                ? localStorage.getItem("language")
                : "english",

            isInvalidEmail: false,
            isInvalidPhoneNumber: false,
            zipCode:( window.localStorage.getItem("currentCountry")) ? JSON.parse(window.localStorage.getItem("currentCountry"))[
                "callingCodes"
            ][0]: '962' ,
        };
    }

    componentWillMount() {
        if (this.state.language != "english") {
            this.setState({
                i18n: Global.ar,
            });
        } else {
            this.setState({
                i18n: Global.en,
            });
        }

        this.getSettings();
    }

    Change() {
        localStorage.removeItem("language");
        localStorage.setItem("language", "العربية");
        window.location.reload();
    }

    ChangeEn() {
        localStorage.removeItem("language");
        localStorage.setItem("language", "English");
        window.location.reload();
    }

    /** FAQ Page  */
    getSettings() {
        fetch(Global.api_link + "getSettings", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                token: Global.token,
                lang: this.state.language,
            }),
        })
            .then((response) => response.json())
            .then((result) => {
                this.setState({
                    Phone: result.data.phone,
                    Email: result.data.contact_email,
                    Address: result.data.address,
                });
            });
    }
    /** FAQ Page End */
    render() {
        return (
            <div>
                <div className="LoginPage">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 col-sm-12 col-xs-12 no_pad">
                                <div className="col-md-8 col-sm-12 col-xs-12  LoginForm ContactPage ">
                                    <h1>{this.state.i18n.Contact} </h1>
                                    <p>{this.state.i18n.ContactText}</p>
                                    <div className="col-md-6 col-sm-12 col-xs-12 form-group">
                                        <label>
                                            {this.state.i18n.name}{" "}
                                            <span
                                                style={{
                                                    color: "#f52828",
                                                    fontSize: "11px",
                                                }}
                                            >
                                                <i
                                                    class="fa fa-certificate"
                                                    aria-hidden="true"
                                                ></i>
                                            </span>
                                        </label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            value={this.state.UserName}
                                            placeholder={this.state.i18n.name}
                                            name="name"
                                            onChange={(name) => {
                                                this.setState({
                                                    UserName: name.target.value,
                                                });
                                            }}
                                        />
                                    </div>

                                    <div className="col-md-6 col-sm-12 col-xs-12 form-group">
                                        <label>
                                            {this.state.i18n.Lastname}{" "}
                                            <span
                                                style={{
                                                    color: "#f52828",
                                                    fontSize: "11px",
                                                }}
                                            >
                                                <i
                                                    class="fa fa-certificate"
                                                    aria-hidden="true"
                                                ></i>
                                            </span>{" "}
                                        </label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            placeholder={
                                                this.state.i18n.Lastname
                                            }
                                            name="lname"
                                            value={this.state.UserLName}
                                            onChange={(lname) => {
                                                this.setState({
                                                    UserLName:
                                                        lname.target.value,
                                                });
                                            }}
                                        />
                                    </div>

                                    <div className="col-md-6 col-sm-12 col-xs-12 form-group">
                                        <label>
                                            {this.state.i18n.Email}
                                            <span
                                                style={{
                                                    color: "#f52828",
                                                    fontSize: "11px",
                                                }}
                                            >
                                                <i
                                                    class="fa fa-certificate"
                                                    aria-hidden="true"
                                                ></i>
                                            </span>
                                        </label>
                                        <input
                                            className="form-control"
                                            type="email"
                                            placeholder="name@example.com"
                                            name="email"
                                            value={this.state.UserEmail}
                                            onChange={(email) => {
                                                this.setState({
                                                    UserEmail:
                                                        email.target.value,
                                                    isInvalidEmail: false,
                                                });
                                            }}
                                        />
                                        {this.state.isInvalidEmail && (
                                            <span
                                                style={{
                                                    color: "red",
                                                    fontSize: "10px",
                                                }}
                                            >
                                                {this.state.i18n.InvalidEmail}
                                            </span>
                                        )}
                                    </div>

                                    <div className="col-md-6 col-sm-12 col-xs-12 form-group">
                                        <Form.Label
                                            htmlFor="inlineFormInputGroup"
                                            visuallyHidden
                                        >
                                            {this.state.i18n.Phone}
                                        </Form.Label>
                                        <InputGroup className="mb-2 phone-input-container">
                                            <InputGroup.Text className="zip-code-container">
                                                +{this.state.zipCode}
                                            </InputGroup.Text>
                                            <FormControl
                                                onInput={({ target }) =>
                                                    (target.value = Math.max(
                                                        0,
                                                        parseInt(target.value)
                                                    )
                                                        .toString()
                                                        .slice(0, 9))
                                                }
                                                className="form-control phone-input"
                                                type="text"
                                                placeholder={
                                                    this.state.i18n.Phone
                                                }
                                                name="phone"
                                                onChange={(phone) => {
                                                    this.setState({
                                                        UserPhone:
                                                            +phone.target.value,
                                                        isInvalidPhoneNumber: false,
                                                    });
                                                }}
                                                value={
                                                    isNaN(this.state.UserPhone)
                                                        ? () => {
                                                              this.setState({
                                                                  UserPhone: "",
                                                              });
                                                              return this.state
                                                                  .UserInfo;
                                                          }
                                                        : this.state.UserPhone
                                                }
                                            />
                                        </InputGroup>
                                        {this.state.isInvalidPhoneNumber && (
                                            <span
                                                style={{
                                                    color: "red",
                                                    fontSize: "10px",
                                                }}
                                            >
                                                {this.state.i18n.InvalidPhoneNumber}
                                            </span>
                                        )}
                                        {/* <label >{this.state.i18n.Phone} <span style={{color:"#f52828" , fontSize: "11px"}}><i class="fa fa-certificate" aria-hidden="true"></i></span></label>
                                    <input className="form-control" type="text" placeholder={this.state.i18n.Phone} name="phone" value={this.state.UserPhone} onChange={(phone) => { this.setState({ UserPhone: phone.target.value }); }}   /> */}
                                    </div>

                                    <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                        <label>
                                            {this.state.i18n.Myenquiry}{" "}
                                            <span
                                                style={{
                                                    color: "#f52828",
                                                    fontSize: "11px",
                                                }}
                                            >
                                                <i
                                                    class="fa fa-certificate"
                                                    aria-hidden="true"
                                                ></i>
                                            </span>
                                        </label>
                                        <textarea
                                            className="form-control TextArea"
                                            rows="10"
                                            placeholder={
                                                this.state.i18n.Myenquiry
                                            }
                                            value={this.state.message}
                                            name="message"
                                            onChange={(e) => {
                                                this.setState({
                                                    message: e.target.value,
                                                });
                                            }}
                                        />
                                    </div>

                                    {this.state.Fill ? (
                                        <div className="form-group  col-md-12 col-sm-12 col-xs-12 Done Wrong ">
                                            <span>
                                                {this.state.i18n.Required}
                                            </span>
                                        </div>
                                    ) : null}

                                    <button
                                        type="submit"
                                        className="btn btn-default ContactBtn"
                                        onClick={() => this.ContactMsg()}
                                    >
                                        {this.state.i18n.Send}
                                    </button>
                                </div>

                                <div className="col-md-4 col-sm-12 col-xs-12">
                                    <div className="LoginForm  ContactPhone">
                                        <h1>{this.state.i18n.GiveUsCall}</h1>
                                        <p>
                                            <a href={"tel:" + this.state.Phone}>
                                                {this.state.i18n.Mobile} :{" "}
                                                {this.state.Phone}
                                            </a>{" "}
                                            <br />
                                            <a
                                                href={
                                                    "mailto:" + this.state.Email
                                                }
                                            >
                                                {this.state.i18n.Email} :{" "}
                                                {this.state.Email}
                                            </a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    validEmailRegex(email) {
        const re =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!re.test(String(email).toLowerCase())) {
            this.setState({
                isInvalidEmail: true,
            });
        }
        return re.test(String(email).toLowerCase());
    }

    validPhoneNumberRegx(number) {
        const regx = /^[0-9]{9}$/;
        if (!regx.test(number)) {
            this.setState({
                isInvalidPhoneNumber: true,
            });
        }

        return regx.test(number);
    }
    /** Contact Message API*/
    ContactMsg() {
        if (
            this.state.UserName &&
            this.state.UserLName &&
            this.state.UserPhone &&
            this.state.message &&
            this.state.UserEmail != null &&
            this.validEmailRegex(this.state.UserEmail) &&
            this.validPhoneNumberRegx(this.state.UserPhone)
        ) {
            fetch(Global.api_link + "sendContactMessage", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    token: Global.token,
                    name: this.state.UserName + " " + this.state.UserLName,
                    phone: this.state.UserPhone,
                    message: this.state.message,
                    email: this.state.UserEmail,
                    user_id: this.state.user_id,
                }),
            })
                .then((response) => response.json())
                .then((result) => {
                    this.setState({
                        UserName: "",
                        UserLName: "",
                        UserPhone: "",
                        message: "",
                        UserEmail: "",
                    });

                    swal({
                        title: this.state.i18n.DoneSuccess,
                        text: this.state.i18n.ContactMsDone,
                        icon: "success",
                        button: this.state.i18n.OK,
                    });
                });
        } else {
            swal({
                title: this.state.i18n.Error,
                text: this.state.i18n.Required,
                icon: "warning",
                button: this.state.i18n.OK,
            });
        }
    }
    /** Contact Message API End*/
}
