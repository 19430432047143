import React, { Component } from "react";
import Img from "react-cool-img";
import Global from "../Global.json";
import swal from "sweetalert";
import { GoogleApiWrapper, Map, Marker, InfoWindow } from "google-maps-react";
import LocationPicker from "react-location-picker";
import Skeleton from "react-loading-skeleton";

/* Default position */
const defaultPosition = {
  lat: 31.963158,
  lng: 35.930359,
};

export class Orders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user_id: localStorage.getItem("user_id")
        ? localStorage.getItem("user_id")
        : 0,
      Done2: false,
      Fill2: false,
      CityAreas: [],
      Fill: false,
      address: "Jordan",
      area: [],
      Cities: [],
      position: {
        lat: 0,
        lng: 0,
      },
      AddLoc: false,
      area: [],
      Cities: [],
      IsCartDone: true,
      ShowAlert: false,
      Latitude: 31.963158,
      Long: 35.930359,
      AllCart: [],
      AllOrders: [],
      OrderDetails: [],
      TotalPrice: 0,
      QTYCart: 1,
      AllFav: [],
      Options: [],
      IFavDone: true,
      OrderIDDetails: 0,
      language: localStorage.getItem("language")
        ? localStorage.getItem("language")
        : "english",
    };

    // binding this to event-handler functions
    this.onMarkerClick = this.onMarkerClick.bind(this);
    this.onMapClick = this.onMapClick.bind(this);
    // Bind
    this.handleLocationChange = this.handleLocationChange.bind(this);
  }

  Change() {
    localStorage.removeItem("language");
    localStorage.setItem("language", "arabic");
    window.location.reload();
  }

  ChangeEn() {
    localStorage.removeItem("language");
    localStorage.setItem("language", "english");
    window.location.reload();
  }

  handleLocationChange({ position, address, places }) {
    // Set new location
    this.setState({ position, address });
    // console.log(this.state.position)
  }

  onMarkerClick = (props, marker, e) => {
    this.setState({
      selectedPlace: props,
      activeMarker: marker,
      showingInfoWindow: true,
      windowProps: props,
    });
  };
  onMapClick = (props) => {
    if (this.state.showingInfoWindow) {
      this.setState({
        showingInfoWindow: false,
        activeMarker: null,
      });
    }
  };

  componentWillMount() {
    if (
      this.state.user_id != 0 &&
      this.state.user_id &&
      this.state.user_id != null &&
      this.state.user_id != "null"
    ) {
      if (this.state.language != "english") {
        this.setState({
          i18n: Global.ar,
        });
      } else {
        this.setState({
          i18n: Global.en,
        });
      }
      this.getUser();
      this.Fav();
      this.Orders();
    } else {
      localStorage.removeItem("user_id");
      localStorage.setItem("user_id", 0);
      window.location.href = "/Login";
    }
  }

  getUser() {
    fetch(Global.api_link + "getUser", {
      method: "POST",
      headers: {
        Accept: "application/json",
      },
      body: JSON.stringify({
        token: Global.token,
        user_id: this.state.user_id,
      }),
    })
      .then((response) => response.json())
      .then((result) => {
        this.setState({
          name: result.data.name,
          email: result.data.email,
          image: result.data.image,
          phone: result.data.phone,
          Gender: result.data.gender,
          face_image: result.data.face_image,
          //DOB: result.data.dob,
          imageOld: result.data.image,
          isPageLoad: false,
        });

        this.getUserArea();
        this.getCities();
      })
      .catch((error) => {
        console.error(error);
      });
  }

  getUserArea() {
    fetch(Global.api_link + "getUserArea", {
      method: "POST",
      headers: {
        Accept: "application/json",
      },
      body: JSON.stringify({
        token: Global.token,
        user_id: this.state.user_id,
        lang: this.state.language,
      }),
    })
      .then((response) => response.json())
      .then((result) => {
        this.setState({
          area: result.data,
        });
      })
      .catch((error) => {
        //console.error(error);
      });
  }

  getCities() {
    fetch(Global.api_link + "getCities", {
      method: "POST",
      headers: {
        Accept: "application/json",
      },
      body: JSON.stringify({
        token: Global.token,
        lang: this.state.language,
      }),
    })
      .then((response) => response.json())
      .then((result) => {
        this.setState({
          Cities: result.data,
        });
      })
      .catch((error) => {
        //console.error(error);
      });
  }

  Cart() {
    fetch(Global.api_link + "getUserCartProducts", {
      method: "POST",
      headers: {
        Accept: "application/json",
      },
      body: JSON.stringify({
        token: Global.token,
        user_id: this.state.user_id,
        lang: this.state.language,
      }),
    })
      .then((response) => response.json())
      .then((result) => {
        this.setState({
          AllCart: result.data,
          TotalPrice: result.totalPrice,
          Balance: result.Balance,
          AmountCashBack: result.AmountCashBack,
          IsCartDone: false,
        });

        this.Prices();
      })
      .catch((error) => {
        console.error(error);
      });
  }

  Fav() {
    fetch(Global.api_link + "getUserFavProducts", {
      method: "POST",
      headers: {
        Accept: "application/json",
      },
      body: JSON.stringify({
        token: Global.token,
        user_id: this.state.user_id,
        lang: this.state.language,
      }),
    })
      .then((response) => response.json())
      .then((result) => {
        this.setState({
          AllFav: result.data,
          IFavDone: false,
        });

        this.Prices();
      })
      .catch((error) => {
        console.error(error);
      });
  }

  updateCartQTY(cart_id, qty) {
    fetch(Global.api_link + "updateCartQTY", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: Global.token,
        cart_id: cart_id,
        qty: qty,
      }),
    })
      .then((response) => response.json())
      .then((result) => {
        this.setState({
          IsCartDone: false,
        });
        this.Cart();
        this.Prices();
      });
  }

  /** Cart Prices */
  Prices() {
    fetch(Global.mobile_epi_link + "getUserCheckoutamounts", {
      method: "POST",
      headers: {
        Accept: "application/json",
      },
      body: JSON.stringify({
        token: Global.mobile_token,
        user_id: this.state.user_id,
        lang: this.state.language,
      }),
    })
      .then((response) => response.json())
      .then((result) => {
        var thisTime = new Date().getTime();
        var refTxn = this.state.user_id + "_" + thisTime;

        this.setState({
          Balance: result.balance,
          cashBack: result.cashBack,
          totalWithoutDiscount: result.totalWithoutDiscount,
          deliveryCost: result.deliveryCost,
          refTxn: refTxn,
          productsAmount: result.productsAmount,
          isPageLoad: false,
          cash_available: result.cash_available,
        });

        this.RandomOffer();
      })
      .catch((error) => {
        //console.error(error);
      });
  }

  /** Convert Content to HTML */
  MyComponent(desc) {
    return <div dangerouslySetInnerHTML={this.createMarkup(desc)} />;
  }

  createMarkup(desc) {
    return { __html: desc };
  }
  /** Convert Content to HTML End*/

  render() {
    return (
      <div>
        {" "}
        {/** Wrapper Div  Start */}
        <div>
          {" "}
          {/** Profile Section Start */}
          <div className="container">
            <div className="row">
              <div className="col-lg-12  col-md-12 col-sm-12 col-xs-12  Profile no_pad">
                <div className="ProfileBackImage">
                  <div>
                    <Img
                      src={
                        this.state.image !== null && this.state.image !== ""
                          ? Global.image_link + this.state.image
                          : this.state.face_image !== null &&
                            this.state.face_image !== ""
                          ? this.state.face_image
                          : "img/nopic.png"
                      }
                    />
                  </div>
                  <h2>{this.state.name}</h2>
                </div>

                <div className="col-lg-12  col-md-12 col-sm-12 col-xs-12  no_pad">
                  <div className="">
                    <div className="tab-content " id="tabs">
                      <div className="tab-pane active" id="ORDER">
                        {" "}
                        {/** MY Order Start  */}
                        {this.state.AllOrders.length > 0 ? (
                          <>
                            <div className="col-md-9 col-sm-9 col-xs-12 no_pad ">
                              {this.state.OrderIDDetails == 0 ? (
                                <div className="BoxData">
                                  <h3>{this.state.i18n.MyOrders}</h3>

                                  <div className="table-responsive">
                                    <table className="table table-borderless">
                                      <thead>
                                        <tr>
                                          <th scope="col">
                                            {this.state.i18n.Orders}
                                          </th>
                                          <th scope="col">
                                            {this.state.i18n.Method}
                                          </th>
                                          <th scope="col">
                                            {this.state.i18n.Price}
                                          </th>
                                          <th scope="col">
                                            {this.state.i18n.Date}
                                          </th>
                                          <th scope="col">
                                            {this.state.i18n.Status}{" "}
                                          </th>
                                          <th scope="col"> </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {this.state.AllOrders.map((order) => {
                                          return (
                                            <tr>
                                              <th scope="row">
                                                {this.state.i18n.Order} #{" "}
                                                {order.id} <br />
                                                {order.store_name}
                                              </th>
                                              <td>{order.method}</td>
                                              <td>
                                                {(order.amount*localStorage.getItem("CurrentCurrency")).toFixed(2)
}{" "}
                                                {/* {this.state.i18n.JOD} */}
                                                {localStorage.getItem("Currency")
}
                                              </td>
                                              <td>{order.date_time} </td>
                                              <td>
                                                {order.status != -2 ? (
                                                  order.is_delivered == 1 &&
                                                  order.is_finished == 1 ? (
                                                    <span className="Complet">
                                                      {this.state.i18n.Complete}
                                                    </span>
                                                  ) : order.is_delivered == 0 &&
                                                    order.is_finished == 1 ? (
                                                    <span className="Hold">
                                                      {this.state.i18n.onTheWay}
                                                    </span>
                                                  ) : (
                                                    <span className="Hold">
                                                      {this.state.i18n.Hold}
                                                    </span>
                                                  )
                                                ) : (
                                                  <span className="Hold">
                                                    {this.state.i18n.cancel}
                                                  </span>
                                                )}
                                              </td>
                                              <td>
                                                {" "}
                                                <a
                                                  onClick={() =>
                                                    this.setState(
                                                      {
                                                        OrderIDDetails:
                                                          order.id,
                                                      },
                                                      () =>
                                                        this.OrderDetails(
                                                          order.id
                                                        )
                                                    )
                                                  }
                                                >
                                                  {this.state.i18n.Details}
                                                </a>
                                              </td>
                                            </tr>
                                          );
                                        })}
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              ) : (
                                <div className="BoxData">
                                  <div className="table-responsive">
                                    <table className="table table-borderless">
                                      <thead>
                                        <tr>
                                          <th scope="col">
                                            <a
                                              className="BackToOrder"
                                              onClick={() =>
                                                this.setState({
                                                  OrderIDDetails: 0,
                                                })
                                              }
                                            >
                                              {this.state.i18n.BackToProduct}
                                            </a>
                                          </th>
                                          <th scope="col">
                                            {this.state.i18n.shipment}
                                          </th>
                                          <th scope="col">
                                            {this.state.i18n.Price}
                                          </th>
                                          <th scope="col">
                                            {this.state.i18n.Qty}
                                          </th>
                                          <th scope="col">
                                            {this.state.i18n.Date}
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {this.state.OrderDetails.map(
                                          (order) => {
                                            return (
                                              <tr>
                                                <th scope="row">
                                                  <Img
                                                    alt="Sooq"
                                                    placeholder={
                                                      "img/loader.gif"
                                                    }
                                                    error={"img/loader.gif"}
                                                    className="CartProdImg"
                                                    src={
                                                      Global.image_link +
                                                      order.option_image
                                                    }
                                                  />
                                                  {order.main_title}
                                                </th>
                                                <td>
                                                  {order.shipment_id == null
                                                    ? "-"
                                                    : order.shipment_id}
                                                </td>
                                                <td>
                                                  {(order.optionPrice*localStorage.getItem("CurrentCurrency")).toFixed(2)}
                                                {localStorage.getItem("Currency")}
                                                  {/* {this.state.i18n.JOD} */}

                                                </td>
                                                <td>{order.qty}</td>
                                                <td>{order.date_time} </td>
                                              </tr>
                                            );
                                          }
                                        )}
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              )}
                            </div>
                            <div className="col-md-3 col-sm-3 col-xs-12 Ads">
                              <a href="">
                                <Img src="img/Group643.png" width="100%" />
                              </a>
                            </div>
                          </>
                        ) : (
                          <div className="col-md-12 col-sm-12 col-xs-12 no_pad EmptyCart ">
                            <Img src="img/cartempt3y.png" />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>{" "}
                  {/**  Tabs Contect End   */}
                </div>
              </div>
            </div>
          </div>
        </div>{" "}
        {/** Profile Section End */}
      </div> /** Wrapper Div  End */
    );
  }

  /************* Functions  ******************/

  Options(PID) {
    fetch(Global.api_link + "OfferDetails", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: Global.token,
        lang: this.state.language,
        offer_id: PID,
        user_id: this.state.user_id,
      }),
    })
      .then((response) => response.json())
      .then((result) => {
        this.setState({
          Options: result.Options,
          isPageLoad: false,
          PID: PID,
        });
        //console.log(result);
      });
  }

  /** Option Price */
  OptionPrice(id) {
    this.state.Options.map((Opt) => {
      if (Opt.pid == id) {
        return this.setState({
          OfferPrice: Opt.price,
          OldPrice: Opt.old_price,
          percentage: Opt.percentage,
          OptionQty: Opt.qty,
          OptionCashBack: Opt.cashback,
          OptionDelivery: Opt.delivery,
          OptionOffer: id,
        });
      }
    });
  }

  Orders() {
    fetch(Global.api_link + "getUserOrders", {
      method: "POST",
      headers: {
        Accept: "application/json",
      },
      body: JSON.stringify({
        token: Global.token,
        user_id: this.state.user_id,
        lang: this.state.language,
      }),
    })
      .then((response) => response.json())
      .then((result) => {
        this.setState({
          AllOrders: result.data,
        });
      })
      .catch((error) => {
        this.Orders();
      });
  }

  OrderDetails(payment_id) {
    fetch(Global.api_link + "getUserOrdersDetails", {
      method: "POST",
      headers: {
        Accept: "application/json",
      },
      body: JSON.stringify({
        token: Global.token,
        user_id: this.state.user_id,
        lang: this.state.language,
        paymentID: payment_id,
      }),
    })
      .then((response) => response.json())
      .then((result) => {
        this.setState({
          OrderDetails: result.data,
        });
      })
      .catch((error) => {
        this.Orders();
      });
  }

  /***  Delivery Cost */
  DeliveryCost() {
    if (this.state.Area != null) {
      fetch(Global.mobile_epi_link + "getDeliveryCostNew", {
        method: "POST",
        headers: {
          Accept: "application/json",
        },
        body: JSON.stringify({
          token: Global.mobile_token,
          user_location: this.state.Area,
        }),
      })
        .then((response) => response.json())
        .then((result) => {
          this.setState({
            Cost: result.data,
            prevStoreB: result.prevStoreB,
          });
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      /*this.setState({
                Fill: true,
            });*/
      swal({
        title: this.state.i18n.Error,
        text: this.state.i18n.Required,
        icon: "warning",
        button: this.state.i18n.OK,
      });
    }
  }
  /***  Delivery Cost End */

  /*** Cash On Delivery ***/
  OrderCash() {
    fetch(Global.mobile_epi_link + "QpayPaymentComplete", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: Global.mobile_token,
        user_id: this.state.user_id,
        amount: this.state.totalWithoutDiscount,
        amountOrginal: this.state.productsAmount,
        txn_ref: this.state.refTxn,
        paymentMethod: 2,
      }),
    })
      .then((response) => response.json())
      .then((result) => {
        window.location.href = "/Success";
      })
      .catch((error) => {});
  }
}
