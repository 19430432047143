import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import Global from "../Global.json";

class ProductBlockNew extends Component {
  constructor(props) {
    super();
    this.state = {
      user_id: localStorage.getItem("user_id"),
      language: localStorage.getItem("language")
        ? localStorage.getItem("language")
        : "english",
    };
  }
  componentWillMount() {
    if (this.state.language !== "english") {
      this.setState({
        i18n: Global.ar,
      });
    } else {
      this.setState({
        i18n: Global.en,
      });
    }

  }


  render() {
    return (
      <Col md={8} lg={9} xl={9}>
        <div className="products">
        <p className="cat-name">{this.props.catName}</p>
          <Row>
            {/* product view */}
            {/* {this.props.productsLoader ? (
              <>
                <Col xs={12} sm={6} lg={4} xl={3}>
                  <Skeleton height={300} />
                </Col>
                <Col xs={12} sm={6} lg={4} xl={3}>
                  <Skeleton height={300} />
                </Col>
                <Col xs={12} sm={6} lg={4} xl={3}>
                  <Skeleton height={300} />
                </Col>
                <Col xs={12} sm={6} lg={4} xl={3}>
                  <Skeleton height={300} />
                </Col>
              </>
            ) : 
             */}
            {this.props.products.length < 1 && !this.props.scrollDataFlag ? (
              <div className="emptyProduct w-100 text-center" style={{width: "100%"}}>
                <img
                  src="/img/noProduct.png"
                  alt="l"
                  style={{ objectFit: "contain", margin: "auto", maxWidth: "250px" }}
                />

                <div className="noProductToShow" style={{ margin: "auto", padding: "0" }}>
                  {this.state.i18n.noItemToShow}
                </div>
              </div>
            ) : (
              this.props.products.map((item, index) => {
                return (
                  <Col xl={12} key={index}>
                    <div className="product otherShow">
                      <img
                        src={Global.image_link + item.image}
                        alt="l"
                        onClick={() => {
                          window.location.href = "/Details/" + item.id;
                        }}
                      />
                      {item.label ? <span className={"labelCard"}>{item.label} </span> : null}
                      <div className='prodictDetails-other'>
                        <div className="product-namePrice">
                          <h3>{item.title}</h3>
                          <div className="product-Price-rate">
                            <h4 className="priceProduct">
                            {item.old_price != 0 ? <span className="oldPricespan">
                                {(item.old_price*localStorage.getItem("CurrentCurrency")).toFixed(2)} {this.state.i18n[ localStorage.getItem("Currency")]}
                              </span> : <span className="oldPricespan-noValue"></span>}
                              <span>
                              {(item.price*localStorage.getItem("CurrentCurrency")).toFixed(2)} {this.state.i18n[ localStorage.getItem("Currency")]}
                              </span>
                            </h4>
                            <span className="pro-desc">
                              {this.MyComponent(item.desc)}

                            </span>
                            <ul>
                              <li>
                                <i
                                  className={
                                    item.rate > 0
                                      ? "fa fa-star"
                                      : "fa fa-star-o"
                                  }
                                ></i>
                              </li>
                              <li>
                                <i
                                  className={
                                    item.rate > 1
                                      ? "fa fa-star"
                                      : "fa fa-star-o"
                                  }
                                ></i>
                              </li>
                              <li>
                                <i
                                  className={
                                    item.rate > 2
                                      ? "fa fa-star"
                                      : "fa fa-star-o"
                                  }
                                ></i>
                              </li>
                              <li>
                                <i
                                  className={
                                    item.rate > 3
                                      ? "fa fa-star"
                                      : "fa fa-star-o"
                                  }
                                ></i>
                              </li>
                              <li>
                                <i
                                  className={
                                    item.rate > 4
                                      ? "fa fa-star"
                                      : "fa fa-star-o"
                                  }
                                ></i>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="addCartWish-other">
                          <button
                            onClick={() =>
                              this.state.user_id !== "0" &&
                                this.state.user_id !== 0 &&
                                this.state.user_id !== null
                                ? (window.location.href = "/Details/" + item.id)
                                : (window.location.href = "/Login")
                            }
                          >
                            {this.state.i18n.AddToCartOption}
                          </button>
                          <button
                            onClick={() =>
                              this.state.user_id !== "0" &&
                                this.state.user_id !== 0 &&
                                this.state.user_id !== null
                                ? this.props.addFav(item.id)
                                : (window.location.href = "/Login")
                            }
                          >
                            <i
                              className={
                                item.is_favor && this.state.user_id !== "0"
                                  ? "fa fa-heart red"
                                  : "fa fa-heart-o"
                              }
                            ></i>
                            {" " + this.state.i18n.AddToWishlist}
                          </button>
                        </div>
                      </div>
                    </div>
                  </Col>
                );
              })
            )}
            {/* product  view end */}
          </Row>
        </div>
      </Col>
    );
  }
  /****************** add to faviorite  ****************/

  AddToFavour(id) {
    fetch(Global.api_link + "AddRemoveFavorite", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: Global.token,
        pid: id,
        uid: this.state.user_id,
      }),
    })
      .then((response) => response.json())
      .then((result) => {
        // swal({
        //   title: this.state.i18n.DoneSuccess,
        //   text: this.state.i18n.AddedToFavourite,
        //   icon: "success",
        //   button: this.state.i18n.OK,
        // });
        // this.props.getProducts();
        // setTimeout(100);
      });
  }
  /****************** add to faviorite  end ****************/

  //   <ProductBlockNew products={this.state.products} scrollDataFlag={this.state.scrollDataFlag} productsLoader={this.state.productsLoader} getProducts={this.getProductsByCategory}/>

  /** Convert Content to HTML */
  MyComponent(desc) {
    return <div dangerouslySetInnerHTML={this.createMarkup(desc)} />;
  }
  createMarkup(desc) {
    return { __html: desc };
  }
  /** Convert Content to HTML End*/
}

export default ProductBlockNew;
