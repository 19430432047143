import React, { Component } from "react";
import Global from "../Global.json";
import * as firebase from "firebase";

export default class CloseTab extends Component {
  constructor(props) {
    super(props);
    // the initial application state
    this.state = {
      rating: 3,
      DataArray: [],
      user_id: localStorage.getItem("user_id"),
      HomeProduct: [],
      language: localStorage.getItem("language")
        ? localStorage.getItem("language")
        : "english"
    };
  }

  componentWillMount() {
    this.getCkeckoutAmounts2();
  }

  getCkeckoutAmounts2() {
    fetch(Global.mobile_epi_link + "getUserCheckoutamounts", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        token: Global.mobile_token,
        user_id: this.state.user_id
      })
    })
      .then(response => response.json())
      .then(async res => {
        this.setState(
          {
            products_ids: res.products_ids
          },
          () => {
            this.cancelPaymentProcess2();
          }
        );
      })
      .catch(error => {
        this.getCkeckoutAmounts2();
      });
  }

  cancelPaymentProcess2() {
    let ID_s = this.state.products_ids;

    if (ID_s && !this.state.showAlert) {
      if (ID_s.length > 0) {
        ID_s.map((item, index) => {
          let id = item.product_option_id;
          let all_qty = item.all_qty;
          let user_qty = item.user_qty;
          let cart_id = item.cart_id;
          firebase
            .database()
            .ref("products/" + id)
            .off("value");

          let thisTask2 = firebase.database().ref("products/" + id);

          thisTask2.once("value", snapshot2 => {
            setTimeout(async () => {
              if (snapshot2.exists()) {
                const valuesR = snapshot2.val();

                let waityArray = [];
                waityArray = valuesR.users_queue.split(",");
                if (parseInt(valuesR.users_queue.length) == 0) {
                  waityArray = [];
                }

                let inPurchaseArray = [];
                inPurchaseArray = valuesR.current_pay_user.split(",");
                if (parseInt(valuesR.current_pay_user.length) == 0) {
                  inPurchaseArray = [];
                }

                if (inPurchaseArray.indexOf(this.state.user_id) != -1) {
                  inPurchaseArray.splice(
                    inPurchaseArray.indexOf(this.state.user_id),
                    1
                  );
                  let inPurchaseStr = inPurchaseArray.join(",");

                  firebase
                    .database()
                    .ref("products/" + id)
                    .update({
                      current_pay_user: inPurchaseStr,
                      users_qty:
                        parseInt(valuesR.users_qty) - parseInt(user_qty)
                    });
                }

                if (waityArray.indexOf(this.state.user_id) != -1) {
                  waityArray.splice(waityArray.indexOf(this.state.user_id), 1);
                  let waityArrayStr = waityArray.join(",");

                  firebase
                    .database()
                    .ref("products/" + id)
                    .update({
                      users_queue: waityArrayStr
                    });
                }
              }
            }, 10);
          });

          if (index == ID_s.length - 1) {
            setTimeout(() => {
              window.close();
            }, 1000);
          }
        });
      }
    }
  }

  render() {
    return (
      <div>
        <div className="Loader">
          <img alt="JaDough" src="img/giphy.gif" />
        </div>
      </div>
    );
  }
}
