import React, { Component } from "react";
import { Route, BrowserRouter } from "react-router-dom";
import Home from "./Pages/Home";
import Header from "./Pages/Header";
import Footer from "./Pages/Footer";
import Login from "./Pages/Login";
import Signup from "./Pages/Signup";
// import Details from './Pages/DetailsOld';
import Contact from "./Pages/Contact";
import Search from "./Pages/Search";
import Profile from "./Pages/Profile";
import Payment from "./Pages/Payment";
import Cart from "./Pages/Cart";
import CloseTab from "./Pages/CloseTab";
import Success from "./Pages/Success";
import FAQ from "./Pages/Tearms";
import * as firebase from "firebase";
import "./App.css";
import "./App2.css";
// import CategoriesOld from "./Pages/Categories";
import SubCategory from "./Pages/Subcategory";
import Category from "./Pages/Categories";
import ProductDetails from "./Pages/productDetails";
import Collections from "./Pages/Collections";
import TodayDeals from "./Pages/TodayDeals";
import CategoriesOld from "./Pages/CategoriesOld";
import { Favourites } from "./Pages/Favourites";
import { Orders } from "./Pages/Orders";
import Tearms from "./Pages/Tearms";
import PaymentNew from "./Pages/PaymentNew";

// Your web app's Firebase configuration
var firebaseConfig = {
  apiKey: "AIzaSyBft6Zh--ksxiVWONbIkw6i4tpwjL26KO4",
  authDomain: "copon-216317.firebaseapp.com",
  databaseURL: "https://copon-216317.firebaseio.com",
  projectId: "copon-216317",
  storageBucket: "copon-216317.appspot.com",
  messagingSenderId: "585741925217",
  appId: "1:585741925217:web:f15f87ded4db3a59d8a320",
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export default class App extends Component {
  constructor(props) {
    super(props);
    // the initial application state
    this.state = {
      rating: 3,
      DataArray: [],
      user_id: localStorage.getItem("user_id"),
      HomeProduct: [],
      language: localStorage.getItem("language")
        ? localStorage.getItem("language")
        : "english",
    };
  }

  componentWillMount() {
    if (this.state.language == "arabic") {
      return import("./App_ar.css"), import("./arStyle.css");
    }
    if(!localStorage.getItem("Currency")){
      localStorage.setItem("Currency","JOD");
    }
    
  }

  render() {
    return (
      <BrowserRouter basename="/">
        <div>
          <div>
            <Header />
            <Route path="/" component={Home} exact />
            <Route path="/Login" component={Login} exact />
            <Route path="/Signup" component={Signup} exact />
            <Route path="/Profile" component={Profile} exact />
            <Route path="/Categories/:CatID" component={Category} exact />
            <Route path="/Collections/:CatID" component={Collections} exact />
            <Route path="/Favourites" component={Favourites} exact />
            <Route path="/Orders" component={Orders} exact />
            <Route path="/SubCategories/:CatID" component={SubCategory} exact />
            <Route path="/TodayDeals" component={TodayDeals} exact />
            <Route path="/Details/:PID" component={ProductDetails} exact />
            <Route path="/Search/:key/:CatID" component={Search} exact />
            <Route path="/Contact" component={Contact} exact />
            <Route path="/Success" component={Success} exact />
            <Route path="/Tearms/:name" component={Tearms} />
            <Route path="/Payment/:tex" component={PaymentNew} exact />
            <Route path="/Cart" component={Cart} exact />
            <Route path="/CloseTab" component={CloseTab} exact />
            <Footer />
          </div>
        </div>
      </BrowserRouter>
    );
  }
}
