import React, { Component } from "react";
import Global from "../Global.json";

class PaymentNew extends Component {
  constructor(props) {
    super(props);
  }

  componentWillMount() {}

  render() {
    return (
      <iframe
        src={
          "https://my.souqjo.net/Webapi/getPaymentLink?txn_ref=" +
          this.props.match.params.tex
        }
        style={{
          width: "100%",
          height: 800,
        }}
      />
    );
  }
}

export default PaymentNew;
