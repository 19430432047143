import React, { Component } from "react";
import Img from "react-cool-img";
import Global from "../Global.json";
import swal from "sweetalert";
import { GoogleApiWrapper, Map, Marker, InfoWindow } from "google-maps-react";
import LocationPicker from "react-location-picker";
import Skeleton from "react-loading-skeleton";

/* Default position */
const defaultPosition = {
  lat: 31.963158,
  lng: 35.930359,
};

export class Favourites extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user_id: localStorage.getItem("user_id")
        ? localStorage.getItem("user_id")
        : 0,
      Done2: false,
      Fill2: false,
      CityAreas: [],
      Fill: false,
      address: "Jordan",
      area: [],
      Cities: [],
      position: {
        lat: 0,
        lng: 0,
      },
      AddLoc: false,
      area: [],
      Cities: [],
      IsCartDone: true,
      ShowAlert: false,
      Latitude: 31.963158,
      Long: 35.930359,
      AllCart: [],
      AllOrders: [],
      OrderDetails: [],
      TotalPrice: 0,
      QTYCart: 1,
      AllFav: [],
      Options: [],
      IFavDone: true,
      OrderIDDetails: 0,
      language: localStorage.getItem("language")
        ? localStorage.getItem("language")
        : "english",
    };

    // binding this to event-handler functions
    this.onMarkerClick = this.onMarkerClick.bind(this);
    this.onMapClick = this.onMapClick.bind(this);
    // Bind
    this.handleLocationChange = this.handleLocationChange.bind(this);
  }

  Change() {
    localStorage.removeItem("language");
    localStorage.setItem("language", "arabic");
    window.location.reload();
  }

  ChangeEn() {
    localStorage.removeItem("language");
    localStorage.setItem("language", "english");
    window.location.reload();
  }

  handleLocationChange({ position, address, places }) {
    // Set new location
    this.setState({ position, address });
    // console.log(this.state.position)
  }

  onMarkerClick = (props, marker, e) => {
    this.setState({
      selectedPlace: props,
      activeMarker: marker,
      showingInfoWindow: true,
      windowProps: props,
    });
  };
  onMapClick = (props) => {
    if (this.state.showingInfoWindow) {
      this.setState({
        showingInfoWindow: false,
        activeMarker: null,
      });
    }
  };

  componentWillMount() {
    if (
      this.state.user_id != 0 &&
      this.state.user_id &&
      this.state.user_id != null &&
      this.state.user_id != "null"
    ) {
      if (this.state.language != "english") {
        this.setState({
          i18n: Global.ar,
        });
      } else {
        this.setState({
          i18n: Global.en,
        });
      }
      this.Fav();
      this.getUser();

    } else {
      localStorage.removeItem("user_id");
      localStorage.setItem("user_id", 0);
      window.location.href = "/Login";
    }
  }


 
  Fav() {
    fetch(Global.api_link + "getUserFavProducts", {
      method: "POST",
      headers: {
        Accept: "application/json",
      },
      body: JSON.stringify({
        token: Global.token,
        user_id: this.state.user_id,
        lang: this.state.language,
      }),
    })
      .then((response) => response.json())
      .then((result) => {
        this.setState({
          AllFav: result.data,
          IFavDone: false,
        });

        this.Prices();
      })
      .catch((error) => {
        console.error(error);
      });
  }

  getUser() {
    fetch(Global.api_link + "getUser", {
      method: "POST",
      headers: {
        Accept: "application/json",
      },
      body: JSON.stringify({
        token: Global.token,
        user_id: this.state.user_id,
      }),
    })
      .then((response) => response.json())
      .then((result) => {
        this.setState({
          name: result.data.name,
          email: result.data.email,
          image: result.data.image,
          phone: result.data.phone,
          Gender: result.data.gender,
          face_image: result.data.face_image,
          //DOB: result.data.dob,
          imageOld: result.data.image,
          isPageLoad: false,
        });

        this.getUserArea();
        this.getCities();
      })
      .catch((error) => {
        console.error(error);
      });
  }

  /** Convert Content to HTML */
  MyComponent(desc) {
    return <div dangerouslySetInnerHTML={this.createMarkup(desc)} />;
  }

  createMarkup(desc) {
    return { __html: desc };
  }
  /** Convert Content to HTML End*/

  render() {
    return (
      <div>
        {" "}
        {/** Wrapper Div  Start */}
        <div>
          {" "}
          {/** Profile Section Start */}
          <div className="container">
            <div className="row">
              <div className="col-lg-12  col-md-12 col-sm-12 col-xs-12  Profile  no_pad">
                <div className="ProfileBackImage">
                  <div>
                    <Img
                      src={
                        this.state.image !== null && this.state.image !== ""
                          ? Global.image_link + this.state.image
                          : this.state.face_image !== null &&
                            this.state.face_image !== ""
                          ? this.state.face_image
                          : "img/nopic.png"
                      }
                    />
                  </div>
                  <h2>{this.state.name}</h2>
                </div>

                <div className="col-lg-12  col-md-12 col-sm-12 col-xs-12  no_pad">
                  <div className="">
                    <div className="tab-content " id="tabs">
                      <div className="tab-pane active" id="messages">
                        {/** WishList Start */}
                        {this.state.AllFav.length > 0 ? (
                          <>
                            <div className="col-md-9 col-sm-9 col-xs-12 no_pad ">
                              <div className="BoxData">
                            <h3>{this.state.i18n.WISHLIST}</h3>
                                  
                                <div className="table-responsive">
                                  <table className="table table-borderless">
                                    <thead>
                                      {/* <tr>
                                        <th scope="col" className='favourites-tabal-title'>
                                          {this.state.i18n.WISHLIST}
                                        </th>
                                        <th scope="col"> </th>
                                      </tr> */}

                                    </thead>
                                    <tbody>
                                      {this.state.AllFav.map((fav) => {
                                        return (
                                          <tr>
                                            <th scope="row">
                                              <div className="FavTab">
                                                <Img
                                                  alt="Sooq"
                                                  src={
                                                    Global.image_link +
                                                    fav.image
                                                  }
                                                />
                                                <div className="FavProductDesc">
                                                  <h2>{fav.main_title}</h2>
                                                  {/** <p>{this.MyComponent(fav.desc)}</p> */}
                                                </div>
                                              </div>
                                            </th>
                                            <td>
                                              <div className="float-r">
                                                <a
                                                  // data-toggle="modal"
                                                  // data-target={"#myModalCart"}
                                                  onClick={() =>
                                                    // this.Options(fav.id)
                                                    {
                                                      window.location.href =
                                                        "/Details/" + fav.id;
                                                    }
                                                  }
                                                  // href={'/Details/' + fav.id}
                                                  className="AddToCartFav"
                                                >
                                                  {this.state.i18n.BuyNow}
                                                </a>
                                                <a
                                                  onClick={() =>
                                                    this.DeleteFromFavour(
                                                      fav.id
                                                    )
                                                  }
                                                >
                                                  <i
                                                    className="fa fa-times"
                                                    aria-hidden="true"
                                                  ></i>
                                                </a>
                                              </div>
                                            </td>
                                          </tr>
                                        );
                                      })}

                                      {/** Add To Cart Modal Start */}

                                      <div
                                        className="modal fade CartModal"
                                        id="myModalCart"
                                      >
                                        <div className="modal-dialog">
                                          <div className="modal-content">
                                            <div className="modal-header">
                                              <button
                                                type="button"
                                                className="close"
                                                data-dismiss="modal"
                                                aria-hidden="true"
                                              >
                                                ×
                                              </button>
                                              <h5 className="modal-title">
                                                {this.state.i18n.AddToCart}
                                              </h5>
                                            </div>
                                            <div className="container"></div>
                                            <div className="modal-body">
                                              <ul className="OptionCart">
                                                {this.state.Options.map(
                                                  (option) => {
                                                    return (
                                                      <div>
                                                        <li key={option.id}>
                                                          <h5>
                                                            {option.title}
                                                            <br /> <br />
                                                          </h5>
                                                          <a
                                                            className="PriceOption"
                                                            onClick={() =>
                                                              this.state
                                                                .user_id !== "0"
                                                                ? this.AddToCart(
                                                                    option.pid
                                                                  )
                                                                : (window.location.href =
                                                                    "/Login")
                                                            }
                                                          >
                                                            <div>
                                                              {option.price}
                                                              {
                                                                this.state.i18n
                                                                  .JOD
                                                              }
                                                            </div>
                                                          </a>
                                                        </li>
                                                      </div>
                                                    );
                                                  }
                                                )}
                                              </ul>
                                            </div>

                                            <div className="modal-footer FavFooter">
                                              <a
                                                className="btn btn-primary ResPas"
                                                data-dismiss="modal"
                                              >
                                                {
                                                  this.state.i18n
                                                    .ContinueShopping
                                                }
                                              </a>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      {/** ِAdd To Cart  Modal End */}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-3 col-sm-3 col-xs-12 Ads">
                              <a href="">
                                <Img src="img/Group643.png" width="100%" />
                              </a>
                            </div>
                          </>
                        )  : (
                          <div className="col-md-12 col-sm-12 col-xs-12 no_pad EmptyCart ">
                            <Img src="img/cartempt3y.png" />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>{" "}
                  {/**  Tabs Contect End   */}
                </div>
              </div>
            </div>
          </div>
        </div>{" "}
        {/** Profile Section End */}
      </div> /** Wrapper Div  End */
    );
  }

  /************* Functions  ******************/
  Options(PID) {
    fetch(Global.api_link + "OfferDetails", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: Global.token,
        lang: this.state.language,
        offer_id: PID,
        user_id: this.state.user_id,
      }),
    })
      .then((response) => response.json())
      .then((result) => {
        this.setState({
          Options: result.Options,
          isPageLoad: false,
          PID: PID,
        });
        //console.log(result);
      });
  }

  /** Option Price */
  OptionPrice(id) {
    this.state.Options.map((Opt) => {
      if (Opt.pid == id) {
        return this.setState({
          OfferPrice: Opt.price,
          OldPrice: Opt.old_price,
          percentage: Opt.percentage,
          OptionQty: Opt.qty,
          OptionCashBack: Opt.cashback,
          OptionDelivery: Opt.delivery,
          OptionOffer: id,
        });
      }
    });
  }



  /*** Delete Area */
  Delete(id) {
    fetch(Global.api_link + "DeleteArea", {
      method: "POST",
      headers: {
        Accept: "application/json",
      },
      body: JSON.stringify({
        token: Global.token,
        user_id: this.state.user_id,
        area_id: id,
      }),
    })
      .then((response) => response.json())
      .then((result) => {
        this.setState({
          isPageLoad: false,
        });
        this.getUserArea();
      })
      .catch((error) => {
        console.error(error);
      });
  }

  /** Delete From Cart  */
  DeleteFromCart(oid) {
    fetch(Global.api_link + "DeleteFromCart", {
      method: "POST",
      headers: {
        Accept: "application/json",
      },
      body: JSON.stringify({
        token: Global.token,
        user_id: this.state.user_id,
        product_id: oid,
      }),
    })
      .then((response) => response.json())
      .then((result) => {
        this.setState({
          Loader: true,
        });
        this.Cart();
      })
      .catch((error) => {
        //console.error(error);
      });
  }
  /** Delete From Cart End */

  DeleteFromFavour(id) {
    fetch(Global.api_link + "DeleteFromFav", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: Global.token,
        pid: id,
        uid: this.state.user_id,
      }),
    })
      .then((response) => response.json())
      .then((result) => {
        swal({
          title: this.state.i18n.DoneSuccess,
          text: this.state.i18n.DeletedToFavourite,
          icon: "success",
          button: this.state.i18n.OK,
        });
        setTimeout(100);

        this.Fav();
      });
  }



  


}

