import React, { Component } from "react";
import Img from "react-cool-img";
import Global from '../Global.json';
import swal from 'sweetalert';


export default class Home extends Component {
    constructor(props) {
        super(props)
        // the initial application state
        this.state = {
            user_id: localStorage.getItem('user_id'),
            language: localStorage.getItem('language') ? localStorage.getItem('language') : "english",
            title: props.title,
            price: props.price,
            image: props.image,
            id: props.id,
            Options: []
        }
    }
    componentWillMount() {
        if (this.state.language !== "english") {
            this.setState({
                i18n: Global.ar
            });
        } else {
            this.setState({
                i18n: Global.en
            });
        }
    }


    render() {

        return (
            <div>
                {/* style={{fontFamily: "RegularRoman"}} */}
                <div className="ProductsBox" >
                    <div className="thumbnail">
                        <a href={"/Details/" + this.state.id}>
                            <Img
                                src={Global.image_link + this.state.image}
                                placeholder={"/img/loader.gif"}
                                error={"/img/loader.gif"}
                            />
                        </a>
                        <div className="caption">
                            <h3>{this.state.title}</h3>
                            <span>
                                {(this.state.price*localStorage.getItem("CurrentCurrency")).toFixed(2)}
                                 <h6>
                                     {/* {this.state.i18n.JOD} */}
                                     {this.state.i18n[localStorage.getItem("Currency")]}
                                 </h6></span>
                            <ul className="ViewProduct">
                                <li>
                                    <a href={"/Details/" + this.state.id} style={{fontSize: "14px"}}> {this.state.i18n.BuyNow}</a>
                                </li>
                                <li>
                                    <a onClick={this.props.onClickFavor} className={this.props.favor ? "Favoured" : null}><i className="fa fa-heart-o" aria-hidden="true"></i></a>
                                </li>
                            </ul>
                        </div>

                    </div>
                </div>
            </div>
        );
    }

    /*** Functions ****/
 
}

