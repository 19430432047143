import React, { Component } from "react";
import Global from "../Global.json";

export default class Tearms extends Component {
  constructor(props) {
    super(props)
    // the initial application state
    this.state = {
      rating: 3,
      DataArray: [],
      user_id: localStorage.getItem('user_id'),
      HomeProduct: [],
      TearmsData: {},
      TearmsDataLader: true,
      language: localStorage.getItem('language') ? localStorage.getItem('language') : "english",
    }
  }


  componentWillMount() {
    if (this.state.language != "english") {
      this.setState({
        i18n: Global.ar
      });
    } else {
      this.setState({
        i18n: Global.en
      });
    }

    this.getAboutPageDetails()

  }

  Change() {
    localStorage.removeItem('language')
    localStorage.setItem('language', "العربية")
    window.location.reload()
  }

  ChangeEn() {
    localStorage.removeItem('language')
    localStorage.setItem('language', "English")
    window.location.reload()
  }


  /** Convert Content to HTML */
  MyComponent(desc) {
    return <div dangerouslySetInnerHTML={this.createMarkup(desc)} />;
  }

  createMarkup(desc) {
    return { __html: desc };
  }
  /** Convert Content to HTML End*/

  /** get About Page Details   */
  getAboutPageDetails() {
    fetch(Global.api_link + "getAboutPageDetails", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        token: Global.token,
        lang: this.state.language,
        machine_name : this.props.match.params.name

      })
    })
      .then(response => response.json())
      .then(result => { 
        this.setState(
          {
            TearmsData: result.data,
            TearmsDataLader:false
          }

        );
      });

  }
  /** get About Page Details End */

  render() {

    return (
      <div>
        <div className="LoginPage" style={{minHeight: "70vh"}}>
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-sm-12 col-xs-12 no_pad" >
                <div className="LoginForm About">
                  <div class="row">
                    <div class="columns large-12 medium-12 small-12">
                      <div class="content">
                        <h1 className='terms-title'>{this.state.TearmsData.title}</h1>
                        {
                          this.MyComponent(this.state.TearmsData.text)
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

