import React, { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";

import Global from "../Global.json";

class Footer extends Component {
  constructor() {
    super();
    this.state = {
      user_id: localStorage.getItem("user_id"),
      language: localStorage.getItem("language")
        ? localStorage.getItem("language")
        : "english",
      Categories: [],
      CategoriesLoader: true,
      tearms: [],
      tearmsLoader: true,
    };
  }

  componentWillMount() {
    if (this.state.language !== "english") {
      this.setState({
        i18n: Global.ar,
      });
    } else {
      this.setState({
        i18n: Global.en,
      });
    }
this.get_terms()
    this.getCategories();
    this.getSettings();
  }

  getCategories() {
    /** Home Page Categories */
    fetch(Global.api_link + "getFooterCategories", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: Global.token,
        lang: this.state.language,
      }),
    })
      .then((response) => response.json())
      .then((result) => {
        console.log(result)
        this.setState({
          Categories: result.data,
          CategoriesLoader: false,
        });
      });
    /** Home Page Categories End */
  }

  /** FAQ Page  */
  getSettings() {
    fetch(Global.api_link + "getSettings", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: Global.token,
        lang: this.state.language,
      }),
    })
      .then((response) => response.json())
      .then((result) => {
        this.setState({
          Phone: result.data.phone,
          Email: result.data.contact_email,
          Address: result.data.address,
        });
      });
  }
  /** FAQ Page End */


    /** Get  Terms Start  */

    get_terms() {
      fetch(Global.api_link + "getAboutPages", {
        method: "POST",
        headers: {
          Accept: "application/json",
        },
        body: JSON.stringify({
          token: Global.token,
          lang: this.state.language,
        }),
      })
        .then((response) => response.json())
        .then((result) => {
          this.setState({
            tearms: result.data,
            tearmsLoader: false,
          });
        })
        .catch((error) => {
          console.error(error);
        });
    }
  
    /** Get Terms End */

  render() {
    return (
      <div className="footer">
        <Container>
          <Row>
            <Col xs={12} md={4}>
              <div
                className="footer-logo" 
              >
                <img src="/img/Group191.png" alt="l"  onClick={() => {
                  window.location.href = "/";
                }} />
                <ul>
                  <li>
                    <img src="/img/delivery.png" alt="l" />
                    <p>{this.state.i18n.FASTDELIVARY}</p>
                  </li>
                  <li>
                    <img src="/img/Path642.png" alt="l" />
                    <p> {this.state.i18n.CREATIVESTRATEGY}</p>
                  </li>
                  <li>
                    <img src="/img/surface1.png" alt="l" />
                    <p> {this.state.i18n.HIGHSECURED}</p>
                  </li>
                  <li>
                    <img src="/img/avatar.png" alt="l" />
                    <p> {this.state.i18n.SUPPURT}</p>
                  </li>
                </ul>
              </div>
            </Col>
            <Col xs={12} md={3}>
              <div className="footer-contact">
                <p>{this.state.i18n.ContactUs}</p>
                <ul>
                  <li>
                    {" "}
                    <a >{this.state.i18n.SOUQJO}</a>
                  </li>
                  <li>
                    {" "}
                    <a >{this.state.Address}</a>
                  </li>
                  <li>
                    <a href={"tel:" + this.state.Phone}>
                      {this.state.i18n.Mobile} : {this.state.Phone}
                    </a>
                  </li>
                  <li>
                    <a href={"mailto:" + this.state.Email}>
                      {this.state.i18n.Email} : {this.state.Email}
                    </a>
                  </li>
                </ul>
              </div>
            </Col>
            <Col xs={12} md={3}>
              <div className="footer-category">
                <p>{this.state.i18n.Categories}</p>
                {this.state.CategoriesLoader ? (
                  <ul>
                    <li>
                      <Skeleton height={20} width={50} />
                    </li>
                    <li>
                      <Skeleton height={20} width={50} />
                    </li>
                    <li>
                      <Skeleton height={20} width={50} />
                    </li>
                    <li>
                      <Skeleton height={20} width={50} />
                    </li>
                    <li>
                      <Skeleton height={20} width={50} />
                    </li>
                    <li>
                      <Skeleton height={20} width={50} />
                    </li>
                  </ul>
                ) : (
                  <ul>
                    {console.log(this.state.Categories)}
                    {this.state.Categories.map((item, index) => {
                      return (
                        <li key={index}>
                          {" "}
                          <a href={item.childs ? "/SubCategories/" + item.id  : "/Categories/" + item.id  }>{item.name}</a>
                        </li>
                      );
                    })}
                  </ul>
                )}
              </div>
            </Col>
            <Col xs={12} md={2}>
              <div className="footer-account">
                <p>{this.state.i18n.Account}</p>
                <ul>
                  {this.state.user_id == 0 ? (
                    <li>
                      <a href="/Login">{this.state.i18n.Login}</a>
                    </li>
                  ) : null}
                  {/* {this.state.user_id == 0 ? ( */}
                     {this.state.tearmsLoader ? <Skeleton height={20} /> : (

                      this.state.tearms.map((item,index) => {
                        return (
                      <li key={index}>
                        <a href={"/Tearms/" + item.machine_name }> {item.title}</a>
                      </li>
                        )
                      })
                      )}
                    {/* // <li>
                    //   <a href={"/Terms/" + ''}>{this.state.i18n.Terms}</a>
                    // </li> */}
                  {/* ) : null} */}
                  {/* <li>
                    <a href="/Profile">{this.state.i18n.AccountSetting}</a>
                  </li> */}
                  <li>
                    <a href="/Contact">{this.state.i18n.ContactUs}</a>
                  </li>
                  {/* <li> <a href='/' >My Orders</a></li> */}
                </ul>
              </div>
            </Col>
            <Col xs={12}>
              <div className="footer-hr"></div>
            </Col>

            <Col xs={12}>
              <div className="copy-write">
                <div>
                  {/* Copyright © 2020 SOUQJO all rights reserved. */}
                  {this.state.i18n.Rights}
                </div>
             
                <ul>
                  <li>
                    {" "}
                   
                      <img src="/img/visa.png" alt="" />
                    
                  </li>
                  <li>
                    {" "}
                    
                      <img src="/img/mastercard.png" alt="" />
                   
                  </li>
                  {/**<li>
                    {" "}
                    <a href="/">
                      <img src="/img/paypal.png" alt="" />
                    </a>
                  </li>
                  <li>
                    {" "}
                    <a href="/">
                      <img src="/img/american-express.png" alt="" />
                    </a>
                  </li> **/}
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Footer;
