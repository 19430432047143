import React, { Component } from "react";
import FacebookLogin from "react-facebook-login";
import { GoogleLogin } from "react-google-login";
import Global from "../Global.json";
import CountryJSON from "../Country.json";
import Country_AR from "../Country_AR.json"
import { Form, FormControl, FormGroup, InputGroup } from "react-bootstrap";
export default class Signup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user_id: localStorage.getItem("user_id"),
            language: localStorage.getItem("language")
                ? localStorage.getItem("language")
                : "english",
            Fill: false,
            Found: false,
            EmailSyntax: false,
            isPageLoad: true,
            errors: [],
            PhoneSyntax: false,
            countryId: "",
            zipCode:( window.localStorage.getItem("currentCountry")) ? JSON.parse(window.localStorage.getItem("currentCountry"))[
                "callingCodes"
            ][0]: '962' ,
            isEmailValid: true
        };
        this.country = 962;
    }

    Change() {
        localStorage.removeItem("language");
        localStorage.setItem("language", "arabic");
        window.location.reload();
    }

    ChangeEn() {
        localStorage.removeItem("language");
        localStorage.setItem("language", "english");
        window.location.reload();
    }

    componentWillMount() {
        if (this.state.language !== "english") {
            this.setState({
                i18n: Global.ar,
            });
        } else {
            this.setState({
                i18n: Global.en,
            });
        }
    }

    responseFacebook = (response) => {
        if (response.id) {
            fetch(Global.api_link + "facebookLogin", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                },
                body: JSON.stringify({
                    token: Global.token,
                    email: response.email ? response.email : "",
                    name: response.name ? response.name : "",
                    image: response.image ? response.image : "",
                    facebook_id: response.id ? response.id : "",
                }),
            })
                .then((response) => response.json())
                .then(async (result) => {
                    await localStorage.setItem("user_id", result.data);
                    this.handleBack();
                    //console.log(response);
                })
                .catch((error) => {
                    //console.error(error);
                });
        }
    };

    responseGoogle = (response) => {
        if (response.googleId) {
            fetch(Global.api_link + "googleLogin", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                },
                body: JSON.stringify({
                    token: Global.token,
                    email: response.profileObj.email
                        ? response.profileObj.email
                        : "",
                    name: response.profileObj.givenName
                        ? response.profileObj.givenName +
                          " " +
                          response.profileObj.familyName
                        : "",
                    google_id: response.profileObj.googleId
                        ? response.profileObj.googleId
                        : "",
                }),
            })
                .then((response) => response.json())
                .then(async (result) => {
                    await localStorage.setItem("user_id", result.data);
                    this.handleBack();
                })
                .catch((error) => {
                    //console.error(error);
                });
        }
    };
    // console.log(CountryJSON.countries[0].name)

    onKeyPress(event) {
        return (
            (event.charCode >= 65 && event.charCode <= 90) ||
            (event.charCode >= 97 && event.charCode <= 122)
        );
    }

    validEmailRegex(email) {
        const re =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    handle = () => {
        localStorage.setItem("CountryInfo", "a");
    };

    
    render() {
        const handleKeyDown = (e) => {
            if (e.key === " ") {
                e.preventDefault();
            }
        };

        // const validEmailRegex = (e) => {
        //     RegExp(
        //         /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
        //     );
        //     const validateForm = (errors) => {
        //         let valid = true;
        //         Object.values(errors).forEach(
        //             (val) => val.length > 0 && (valid = false)
        //         );
        //         return valid;
        //     };
        // };

        return (
            <div>
                {/** Wrapper Div  Start */}
                <div>
                    {" "}
                    {/** Login  Start */}
                    <div className="LoginPage">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12  col-md-12 col-sm-12 col-xs-12 LoginForm ">
                                    <h1>{this.state.i18n.SooqAccountCreate}</h1>
                                    <span>
                                        {this.state.i18n.HaveAccount}{" "}
                                        <a href="/Login">
                                            {this.state.i18n.Login}
                                        </a>
                                    </span>

                                    <div className="Form">
                                        <div className="form-group">
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="exampleInputLastName"
                                                placeholder={
                                                    this.state.i18n.Name
                                                }
                                                onChange={(name) => {
                                                    this.setState({
                                                        UserName:
                                                            name.target.value,
                                                        Fill: false,
                                                    });
                                                }}
                                            />
                                        </div>

                                        <div className="form-group">
                                            <input
                                                //type="email"
                                                inputMode="email"
                                                //onKeyDown={handleKeyDown}
                                                //onKeyPress={validEmailRegex}
                                                className="form-control"
                                                id="exampleInputEmail1"
                                                aria-describedby="emailHelp"
                                                placeholder={
                                                    this.state.i18n.Email
                                                }
                                                onChange={(email) => {
                                                    var isArabic =
                                                        /[\u0600-\u06FF\u0750-\u077F]/;
                                                    if (
                                                        !isArabic.test(
                                                            email.target.value
                                                        )
                                                    ) {
                                                        this.setState({
                                                            UserEmail:
                                                                email.target
                                                                    .value,
                                                            Fill: false,
                                                        });
                                                    }
                                                }}
                                                value={this.state.UserEmail}
                                            />
                                        </div>

                                        <div className="form-group">
                                            <input
                                                type="password"
                                                className="form-control"
                                                id="exampleInputPassword1"
                                                placeholder={
                                                    this.state.i18n.Password
                                                }
                                                onChange={(password) => {
                                                    this.setState({
                                                        UserPassword:
                                                            password.target
                                                                .value,
                                                        Fill: false,
                                                    });
                                                }}
                                            />
                                        </div>

                                        {/* Country Start */}
                                        <div className="form-group form-group-country">
                                            <select
                                                onChange={({ target }) => {
                                                    localStorage.setItem(
                                                        "currentCountry",
                                                        target.value
                                                    );
                                                    this.setState({
                                                        zipCode: JSON.parse(
                                                            window.localStorage.getItem(
                                                                "currentCountry"
                                                            )
                                                        )["callingCodes"][0],
                                                        country :JSON.parse(
                                                            window.localStorage.getItem(
                                                                "currentCountry"
                                                            )
                                                        )["name"]
                                                    });
                                                }}
                                                className="custom-select custom-select-country"
                                            >
                                                <option selected>
                                                    {this.state.i18n.Country}
                                                </option>
                                                {CountryJSON.countries.map(
                                                    (item, index) => {
                                                        return (
                                                            localStorage.getItem("language") == "arabic" && Country_AR.filter(({code}) => code === item.alpha2Code)[0]?.name === undefined ? null :(
                                                            <option
                                                                value={JSON.stringify(
                                                                    item
                                                                )}
                                                            >
                                                                {/* {console.log( Country_AR.filter(({code}) => code === item.alpha2Code)[0]?.name === 'undefined' ? "" : Country_AR.filter(({code}) => code === item.alpha2Code)[0]?.name,index) */}
                                                                { localStorage.getItem("language") == "arabic" ? Country_AR.filter(({code}) => code === item.alpha2Code)[0]?.name : item.name
                                                                }
                                                            </option>)
                                                        );
                                                    }
                                                )}
                                                {/* onClick={()=> this.setState({countryId:item}) } */}
                                            </select>
                                        </div>
                                        {/* Country End */}
                                        {/* phone with countryCode start */}
                                        <div className="form-group form-group-phone-countryCode ">
                                         
                                                <span
                                                    className="input-group-text input-group-text-phone-countryCode "
                                              
                                                >
                                                    +{this.state.zipCode}
                                                </span>

                                                <input
                                                    onInput={({ target }) =>
                                                        (target.value =
                                                            Math.max(
                                                                0,
                                                                parseInt(
                                                                    target.value
                                                                )
                                                            )
                                                                .toString()
                                                                .slice(0, 9))
                                                    }
                                                    className="form-control form-control__phone-countryCode m-0 mr-0"
                                                    type="text"
                                                   
                                                    placeholder={
                                                        this.state.i18n.Phone
                                                    }
                                                    name="phone"
                                                    onChange={(phone) => {
                                                        this.setState({
                                                            UserPhone:
                                                                phone.target
                                                                    .value,
                                                        });
                                                    }}
                                                    value={
                                                        isNaN(
                                                            this.state.UserPhone
                                                        )
                                                            ? () => {
                                                                  this.setState(
                                                                      {
                                                                          UserPhone:
                                                                              "",
                                                                      }
                                                                  );
                                                                  return this
                                                                      .state
                                                                      .UserInfo;
                                                              }
                                                            : this.state
                                                                  .UserPhone
                                                    }
                                                />
                                           
                                        </div>
                                        {/* Phone with countryCode end */}
                                        <br />
                                        {this.state.Fill ? (
                                            <div className="form-group  col-md-12 col-sm-12 col-xs-12 Done Wrong">
                                                <span>
                                                    {this.state.i18n.Required}
                                                </span>
                                            </div>
                                        ) : null}

                                        {this.state.Found ? (
                                            <div className="form-group  col-md-12 col-sm-12 col-xs-12 Done Wrong">
                                                <span>
                                                    {
                                                        this.state.i18n
                                                            .EmailFoundBefore
                                                    }
                                                </span>
                                            </div>
                                        ) : null}

                                        {this.state.EmailSyntax ? (
                                            <div className="form-group  col-md-12 col-sm-12 col-xs-12 Done Wrong">
                                                <span>
                                                    {
                                                        this.state.i18n
                                                            .EmailSyntaxError
                                                    }
                                                </span>
                                            </div>
                                        ) : null}

                                        {this.state.PhoneSyntax ? (
                                            <div className="form-group  col-md-12 col-sm-12 col-xs-12 Done Wrong">
                                                <span>
                                                    {
                                                        this.state.i18n
                                                            .PhoneSyntax
                                                    }
                                                </span>
                                            </div>
                                        ) : null}

                                        <button
                                            type="submit"
                                            className="btn btn-primary reg-button"
                                            onClick={() =>
                                                this.setState(
                                                    { isPageLoad: true },
                                                    () => this.userSignup()
                                                )
                                            }
                                        >
                                            {this.state.i18n.CreateAccount}
                                        </button>
                                    </div>

                                    <div className="SocialLogin">
                                        <h2>{this.state.i18n.SocialLogin}</h2>
                                        <ul>
                                            <li>
                                                <FacebookLogin
                                                    appId="1159420434412580"
                                                    autoLoad={false}
                                                    fields="id,name,email,picture"
                                                    callback={
                                                        this.responseFacebook
                                                    }
                                                    onClick={() =>
                                                        this.setState({
                                                            isPageLoad: true,
                                                        })
                                                    }
                                                    cssclassName="facebookLgin"
                                                    isMobile={false}
                                                    icon="fa-facebook"
                                                    textButton={
                                                        this.state.i18n.Facebook
                                                    }
                                                />
                                            </li>
                                            <li>
                                                <GoogleLogin
                                                    clientId="1052289206560-qqv5jpurf5aso88ib4qimsd1n76in16u.apps.googleusercontent.com"
                                                    buttonText={
                                                        this.state.i18n.Google
                                                    }
                                                    onSuccess={
                                                        this.responseGoogle
                                                    }
                                                    onFailure={(error) => {}}
                                                    onClick={() =>
                                                        this.setState({
                                                            isPageLoad: true,
                                                        })
                                                    }
                                                    cookiePolicy={
                                                        "single_host_origin"
                                                    }
                                                    className="GoogleLoginIcon"
                                                />
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>{" "}
                {/** Login  End */}
            </div> /** Wrapper Div  End */
        );
    }

    /********** Functions ***********/
    responseFacebook = (response) => {
        if (response.id) {
            fetch(Global.api_link + "facebookLogin", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                },
                body: JSON.stringify({
                    token: Global.token,
                    email: response.email ? response.email : "",
                    name: response.name ? response.name : "",
                    image: response.image ? response.image : "",
                    facebook_id: response.id ? response.id : "",
                }),
            })
                .then((response) => response.json())
                .then(async (result) => {
                    await localStorage.setItem("user_id", result.data);
                    this.handleBack();
                    this.setState({
                        isPageLoad: false,
                    });
                    //console.log(response);
                })
                .catch((error) => {
                    //console.error(error);
                });
        }
    };

    responseGoogle = (response) => {
        //console.error(response);
        if (response.googleId) {
            fetch(Global.api_link + "googleLogin", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                },
                body: JSON.stringify({
                    token: Global.token,
                    email: response.profileObj.email
                        ? response.profileObj.email
                        : "",
                    image: response.profileObj.imageUrl
                        ? response.profileObj.imageUrl
                        : "",
                    name: response.profileObj.givenName
                        ? response.profileObj.givenName +
                          " " +
                          response.profileObj.familyName
                        : "",
                    google_id: response.profileObj.googleId
                        ? response.profileObj.googleId
                        : "",
                }),
            })
                .then((response) => response.json())
                .then(async (result) => {
                    await localStorage.setItem("user_id", result.data);
                    this.handleBack();
                    this.setState({
                        isPageLoad: false,
                    });
                })
                .catch((error) => {
                    //console.error(error);
                });
        }
    };

    handleBack = async () => {
        let redirectTo = await localStorage.getItem("redirectTo");
        if(redirectTo && redirectTo != ""){
            await localStorage.removeItem("redirectTo");
            window.location.href = redirectTo;
        }else{
            window.location.href = "/";
        }
        //this.props.history.goBack();
    };

    /** Sign UP */

    userSignup() {
      
        let re =
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (
            this.state.UserName &&
            this.state.UserPhone &&
            this.state.UserPassword &&
            this.state.country&&
            this.state.UserEmail != null
        ) {
            if (this.validEmailRegex(this.state.UserEmail)) {
                if (this.state.UserPhone.length >= 9) {
                    fetch(Global.api_link + "userSignup", {
                        method: "POST",
                        headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify({
                            token: Global.token,
                            name: this.state.UserName,
                            phone: this.state.UserPhone,
                            password: this.state.UserPassword,
                            DOB: null,
                            Gender: 0,
                            country: this.state.country,
                            email: this.state.UserEmail,
                        }),
                    })
                        .then((response) => response.json())
                        .then(async (result) => {
                            if (result.data !== null) {
                                await localStorage.setItem("user_id", result.data);
                                this.handleBack();
                                this.setState({
                                    isPageLoad: false,
                                });
                            } else {
                                this.setState({
                                    Found: true,
                                    isPageLoad: false,
                                });
                            }
                        });
                } else {
                    this.setState({
                        PhoneSyntax: true,
                        isPageLoad: false,
                    });
                }
            } else {
                this.setState({
                    EmailSyntax: true,
                    isPageLoad: false,
                });
            }
        } else {
            this.setState({
                Fill: true,
                isPageLoad: false,
            });
        }
    }
}
