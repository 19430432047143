import React, { Component } from "react";
import Global from "../Global.json";
import * as firebase from "firebase";

export default class Payment extends Component {
  constructor(props) {
    super(props);
    // the initial application state
    this.state = {
      showPayment: false,
      showWaitingPopup: false,
      itemsWaiting: [],
      hasSoldOutItem: false,
      itemsWithLessQTY: false,
      products_ids: [],
      rating: 3,
      Balance: 0,
      AmountCashBack: 0,
      TotalPrice: 0,
      isPageLoad: true,
      DataArray: [],
      user_id: localStorage.getItem("user_id")
        ? localStorage.getItem("user_id")
        : 0,
      uid: localStorage.getItem("user_id")
        ? localStorage.getItem("user_id")
        : 0,
      HomeProduct: [],
      language: localStorage.getItem("language")
        ? localStorage.getItem("language")
        : "english",
      isclosepage: false
    };
  }

  componentWillMount() {
    if (this.state.language != "english") {
      this.setState({
        i18n: Global.ar
      });
    } else {
      this.setState({
        i18n: Global.en
      });
    }

    this.getCkeckoutAmounts();

    window.addEventListener("beforeunload", event => {
      // Cancel the event as stated by the standard.
      window.open("/CloseTab", "_blank", "toolbar=yes,scrollbars=yes,resizable=yes,top=500,left=500,width=400,height=400");
    });
  }

  componentWillUnmount() {
    if (this.state.products_ids.length > 0) {
      //this.cancelPaymentProcess();
      let ID_s = this.state.products_ids;
      if (ID_s) {
        if (ID_s.length > 0) {
          ID_s.map((item, indexs) => {
            firebase
              .database()
              .ref("products/" + item.id)
              .off("value");

            firebase
              .database()
              .ref("payments/" + this.state.uid)
              .child(this.state.refTxn)
              .off("value");

            firebase
              .database()
              .ref("orders")
              .off("value");
          });
        }
      }
    }
    if (this.state.isclosepage) {
      this.cancelPaymentProcess();
    }
  }

  /**get paymentLink */

  getCkeckoutAmounts() {
    fetch(Global.mobile_epi_link + "getUserCheckoutamounts", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        token: Global.mobile_token,
        user_id: this.state.user_id
      })
    })
      .then(response => response.json())
      .then(async res => {
        var thisTime = new Date().getTime();
        this.setState({
          TotalPrice: res.totalWithoutDiscount,
          products_ids: res.products_ids,
          refTxn: this.state.user_id + "_" + thisTime,
          isPageLoad: false
        });

        firebase
          .database()
          .ref("turns/" + this.state.uid)
          .set({
            showPayment: false,
            showWaiting: false,
            showAlert: false,
            successProducts: 0
          });

        let ID_s = res.products_ids;
        if (ID_s) {
          if (ID_s.length > 0) {
            ID_s.map((item, indexs) => {
              let id = item.product_option_id;
              let all_qty = item.all_qty;
              let user_qty = item.user_qty;
              let thisTask = firebase.database().ref("products/" + id);
              thisTask.once("value", snapshot2 => {
                setTimeout(() => {
                  if (!snapshot2.exists()) {
                    firebase
                      .database()
                      .ref("products/" + id)
                      .set({
                        current_pay_user: "",
                        failed_users_queue: "",
                        is_completed: false,
                        product_option_id: id,
                        start_time: "",
                        users_queue: "",
                        is_sold_out: false,
                        all_qty: all_qty,
                        users_qty: 0
                      });
                  } else {
                    const valuesR = snapshot2.val();

                    let waityArray = [];
                    waityArray = valuesR.users_queue.split(",");
                    if (parseInt(valuesR.users_queue.length) == 0) {
                      waityArray = [];
                    }

                    let inPurchaseArray = [];
                    inPurchaseArray = valuesR.current_pay_user.split(",");
                    if (parseInt(valuesR.current_pay_user.length) == 0) {
                      inPurchaseArray = [];
                    }

                    if (inPurchaseArray.indexOf(this.state.uid) != -1) {
                      inPurchaseArray.splice(
                        inPurchaseArray.indexOf(this.state.uid),
                        1
                      );
                      let inPurchaseStr = inPurchaseArray.join(",");

                      firebase
                        .database()
                        .ref("products/" + id)
                        .update({
                          current_pay_user: inPurchaseStr,
                          users_qty:
                            parseInt(valuesR.users_qty) - parseInt(user_qty)
                        });
                    }

                    if (waityArray.indexOf(this.state.uid) != -1) {
                      waityArray.splice(waityArray.indexOf(this.state.uid), 1);
                      let waityArrayStr = waityArray.join(",");

                      firebase
                        .database()
                        .ref("products/" + id)
                        .update({
                          users_queue: waityArrayStr
                        });
                    }
                  }
                });
              });
            });
          }else{
            window.location.href = "/Cart";
          }
        }else{
          window.location.href = "/Cart";
        }

        this.setState(
          {
            isLoading: false
          },
          () => {
            this.checkFirebaseProducts();
          }
        );
      })
      .catch(error => {
        this.getCkeckoutAmounts();
      });
  }

  Change() {
    localStorage.removeItem("language");
    localStorage.setItem("language", "العربية");
    window.location.reload();
  }

  ChangeEn() {
    localStorage.removeItem("language");
    localStorage.setItem("language", "english");
    window.location.reload();
  }

  render() {
    if (this.state.isPageLoad) {
      return (
        <div>
          <div className="Loader">
            <img alt="Copon" src="img/giphy.gif" />
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <div className="LoginPage">
            <div className="container no_pad_hor_xs">
              <div className="">
                <div className=" ">
                  <div className="LoginForm About PaymentForm">
                    <h1>{this.state.i18n.Checkout}</h1>
                    {this.state.isclosepage ? (
                      <h1>Close Page</h1>
                    ) : (
                      <div className="no_pad">
                        <div className=" AccordionCart no_pad">
                          <div class="panel-group" id="accordion">
                            <div class="panel panel-default">
                               
                              <div
                                id="collapse1"
                                class="panel-collapse collapse in"
                              >
                                <div class="panel-body">
                                  {this.state.showPayment ? (
                                    <iframe
                                      src={
                                        Global.api_link +
                                        "qpayLink?amount=" +
                                        parseFloat(this.state.TotalPrice) +
                                        "&refTxn=" +
                                        this.state.refTxn
                                      }
                                      style={{
                                        width: "100%",
                                        height: 800
                                      }}
                                    />
                                  ) : this.state.showAlert ? (
                                    <div className="askForWaitingDiv">
                                      <h1>{this.state.alertData.title}</h1>
                                      <h4>{this.state.alertData.message}</h4>
                                      <div className="CompleteOrder">
                                        {this.state.alertData.cancelText ? (
                                          <a
                                            type="submit"
                                            onClick={() => {
                                              this.setState({
                                                showWaitingPopup: true,
                                                showAlert: false
                                              });
                                            }}
                                            className="btn btn-default Register"
                                          >
                                            {this.state.alertData.cancelText}
                                          </a>
                                        ) : null}

                                        {this.state.alertData.cancelText ? (
                                          <strong>{this.state.i18n.Or}</strong>
                                        ) : null}

                                        <button
                                          type="submit"
                                          onClick={() => {
                                            this.removeItemsAndBackToCart();
                                          }}
                                          className="btn btn-default Register ContinueShopping"
                                        >
                                          {this.state.alertData.confirmText}
                                        </button>
                                      </div>
                                    </div>
                                  ) : this.state.showWaitingPopup ? (
                                    <div className="askForWaitingDiv">
                                      <h1>Waiting for my turn</h1>
                                      <img src="/img/loaderPayment.gif" />
                                      <div
                                        className="CompleteOrder"
                                        style={{ marginTop: 0 }}
                                      >
                                        <button
                                          type="submit"
                                          onClick={() => {
                                            this.removeItemsAndBackToCart();
                                          }}
                                          className="btn btn-default Register ContinueShopping"
                                        >
                                          Ignore these products
                                        </button>
                                      </div>
                                    </div>
                                  ) : (
                                    <div className="askForWaitingDiv">
                                      <h1>Please wait...</h1>
                                      <img src="/img/loaderPayment.gif" />
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }

  checkFirebaseProducts() {
    firebase
      .database()
      .ref("payments/" + this.state.uid + "/" + this.state.refTxn)
      .set({
        user: this.state.uid,
        refTxn: this.state.refTxn,
        is_paid: false,
        is_finish: false
      });

    let ID_s = this.state.products_ids;
    let isSuccessItems = 0;
    let itemsNotForWait = [];
    let itemsForWait = [];
    let failedArray = [];
    if (ID_s) {
      if (ID_s.length > 0) {
        let timeStamp = new Date().getTime();
        ID_s.map((item, indexs) => {
          let id = item.product_option_id;
          let all_qty = item.all_qty;
          let user_qty = item.user_qty;
          let thisTask = firebase.database().ref("products/" + id);

          thisTask.on("value", snapshot2 => {
            setTimeout(() => {
              if (snapshot2.exists()) {
                const valuesR = snapshot2.val();

                let inPurchaseArray = [];
                inPurchaseArray = valuesR.current_pay_user.split(",");
                if (parseInt(valuesR.current_pay_user.length) == 0) {
                  inPurchaseArray = [];
                }

                let inWaitingArray = [];
                inWaitingArray = valuesR.users_queue.split(",");
                if (parseInt(valuesR.users_queue.length) == 0) {
                  inWaitingArray = [];
                }

                //Check if ALL QTY less than added QTY
                if (valuesR.all_qty < user_qty && valuesR.all_qty > 0) {
                  this.setState({
                    itemsWithLessQTY: true
                  });
                }

                if (valuesR.all_qty == 0) {
                  //Check if ALL QTY sold out
                  this.setState({
                    hasSoldOutItem: true
                  });
                  firebase
                    .database()
                    .ref("products/" + id)
                    .update({
                      all_qty: all_qty,
                      is_sold_out: true
                    });

                  firebase
                    .database()
                    .ref("products/" + id)
                    .off("value");

                  this.setState({
                    showAlert: true,
                    alertData: {
                      title: this.state.i18n.Alert,
                      message: this.state.i18n.QTYBuyed,
                      showConfirmButton: true,
                      showCancelButton: false,
                      cancelText: null,
                      confirmText: this.state.i18n.OK,
                      onCancelPressed: null,
                      onConfirmPressed: () => {
                        this.setState(
                          {
                            showAlert: false
                          },
                          () => {
                            setTimeout(() => {
                              this.setState(
                                {
                                  isLoading: true
                                },
                                () => {
                                  this.removeItemsAndBackToCart();
                                }
                              );
                            }, 1000);
                          }
                        );
                      }
                    }
                  });
                } else {
                  //Check if firebase QTY less than server QTY
                  if (valuesR.all_qty >= all_qty) {
                    let isSD = true;
                    if (all_qty > 0) {
                      isSD = false;
                    }
                    firebase
                      .database()
                      .ref("products/" + id)
                      .update({
                        all_qty: all_qty,
                        is_sold_out: isSD
                      });
                  }

                  if (inWaitingArray.indexOf(this.state.uid) != -1) {
                    let itemsWaitings = this.state.itemsWaiting;
                    if (itemsWaitings.indexOf(id) == -1) {
                      itemsWaitings.push(id);
                    }
                  }

                  //if firebase product with other person add to waiting
                  if (
                    !valuesR.is_sold_out &&
                    valuesR.all_qty != 0 &&
                    valuesR.all_qty >= user_qty &&
                    inPurchaseArray.indexOf(this.state.uid) == -1 &&
                    inWaitingArray.indexOf(this.state.uid) == -1 &&
                    (parseInt(valuesR.users_qty) >= parseInt(valuesR.all_qty) ||
                      parseInt(
                        parseInt(valuesR.users_qty) + parseInt(user_qty)
                      ) > parseInt(all_qty))
                  ) {
                    let itemsWaiting = this.state.itemsWaiting;
                    if (itemsWaiting.indexOf(id) == -1) {
                      itemsWaiting.push(id);
                    }

                    if (itemsForWait.indexOf(id) == -1) {
                      itemsForWait.push(id);
                      itemsNotForWait.splice(itemsNotForWait.indexOf(id), 1);
                    }

                    isSuccessItems--;

                    this.setState({
                      showAlert: true,
                      alertData: {
                        title: this.state.i18n.Alert,
                        message: this.state.i18n.QTYWAiting,
                        showConfirmButton: true,
                        showCancelButton: true,
                        cancelText: this.state.i18n.Wait,
                        confirmText: this.state.i18n.Removeit,
                        onCancelPressed: () => {
                          this.setState(
                            {
                              showAlert: false
                            },
                            () => {
                              setTimeout(() => {
                                this.setState(
                                  {
                                    showWaitingPopup: true
                                  },
                                  () => {
                                    this.getCkeckoutAmounts();
                                  }
                                );
                              }, 100);
                            }
                          );
                        },
                        onConfirmPressed: () => {
                          this.setState(
                            {
                              showAlert: false
                            },
                            () => {
                              setTimeout(() => {
                                this.setState(
                                  {
                                    isLoading: true
                                  },
                                  () => {
                                    this.removeItemsAndBackToCart();
                                  }
                                );
                              }, 1000);
                            }
                          );
                        }
                      }
                    });

                    this.setState(
                      {
                        allowToPay: false,
                        itemsWaiting: itemsWaiting
                      },
                      () => {
                        let users_q = "";
                        let user_q_array = inWaitingArray;

                        if (user_q_array.indexOf(this.state.uid) == -1) {
                          user_q_array[user_q_array.length] = this.state.uid;
                          users_q = user_q_array.join(",");

                          firebase
                            .database()
                            .ref("products/" + id)
                            .update({
                              users_queue: users_q
                            });
                        }
                      }
                    );
                  }

                  if (
                    parseInt(
                      parseInt(valuesR.users_qty) + parseInt(user_qty)
                    ) <= parseInt(valuesR.all_qty)
                  ) {
                    let userTurnID = 0;
                    if (
                      (parseInt(inWaitingArray.length) > 0 &&
                        parseInt(inWaitingArray[0]) ==
                          parseInt(this.state.uid)) ||
                      (parseInt(inWaitingArray.length) == 0 &&
                        parseInt(inWaitingArray[0]) != parseInt(this.state.uid))
                    ) {
                      //my user turn
                      userTurnID = this.state.uid;

                      if (itemsNotForWait.indexOf(id) == -1) {
                        itemsNotForWait.push(id);
                        itemsForWait.splice(itemsForWait.indexOf(id), 1);
                      }

                      isSuccessItems++;

                      let itemsWaiting = this.state.itemsWaiting;
                      if (itemsWaiting.indexOf(id) != -1) {
                        itemsWaiting.splice(itemsWaiting.indexOf(id), 1);
                      }

                      this.setState({
                        itemsWaiting: itemsWaiting
                      });
                    } else {
                      //other user turn
                      if (inWaitingArray[0].length > 0) {
                        userTurnID = inWaitingArray[0];
                      } else {
                        userTurnID = 0;
                      }
                    }

                    if (userTurnID != 0) {
                      let users_q = "";
                      let user_q_array = inWaitingArray;
                      user_q_array.splice(user_q_array.indexOf(userTurnID), 1);
                      users_q = user_q_array.join(",");

                      let inPAr = inPurchaseArray;
                      if (inPAr.indexOf(userTurnID) == -1) {
                        inPAr.push(userTurnID);
                        let inPurchaseStr = inPAr.join(",");

                        firebase
                          .database()
                          .ref("products/" + id)
                          .update({
                            current_pay_user: inPurchaseStr,
                            users_qty: parseInt(valuesR.users_qty) + user_qty,
                            users_queue: users_q
                          });
                      }

                      if (valuesR.all_qty > all_qty) {
                        firebase
                          .database()
                          .ref("products/" + id)
                          .update({
                            all_qty: all_qty,
                            is_sold_out: false
                          });
                      }
                    }
                  }
                }
              } else {
                isSuccessItems++;
                let inPurchaseArray = "";
                let itemsWaiting = this.state.itemsWaiting;
                if (itemsWaiting.indexOf(id) != -1) {
                  itemsWaiting.splice(itemsWaiting.indexOf(id), 1);
                }
                inPurchaseArray = this.state.uid;
                this.setState({
                  itemsWaiting: itemsWaiting
                });
                firebase
                  .database()
                  .ref("products/" + id)
                  .set({
                    current_pay_user: inPurchaseArray,
                    failed_users_queue: "",
                    is_completed: false,
                    product_option_id: id,
                    start_time: timeStamp,
                    users_queue: "",
                    is_sold_out: false,
                    all_qty: all_qty,
                    users_qty: user_qty
                  });
              }

              if (itemsNotForWait.length == ID_s.length) {
                this.setState(
                  {
                    isLoading: false,
                    showAlert: false
                  },
                  () => {
                    this.componentWillUnmount();

                    setTimeout(() => {
                      this.setState({
                        showPayment: true,
                        showWaitingPopup: false
                      });
                    }, 100);
                  }
                );
              } else {
                this.setState({
                  isLoading: false
                });
              }

              //Get Data From SQL Again
              //this.getCkeckoutAmounts();
            }, 10);
          });
        });
      }
    }
  }

  cancelPaymentProcess() {
    let ID_s = this.state.products_ids;

    if (ID_s && !this.state.showAlert) {
      if (ID_s.length > 0) {
        ID_s.map((item, index) => {
          let id = item.product_option_id;
          let all_qty = item.all_qty;
          let user_qty = item.user_qty;
          let cart_id = item.cart_id;
          firebase
            .database()
            .ref("products/" + id)
            .off("value");

          let thisTask2 = firebase.database().ref("products/" + id);

          thisTask2.once("value", snapshot2 => {
            setTimeout(async () => {
              if (snapshot2.exists()) {
                const valuesR = snapshot2.val();

                let waityArray = [];
                waityArray = valuesR.users_queue.split(",");
                if (parseInt(valuesR.users_queue.length) == 0) {
                  waityArray = [];
                }

                let inPurchaseArray = [];
                inPurchaseArray = valuesR.current_pay_user.split(",");
                if (parseInt(valuesR.current_pay_user.length) == 0) {
                  inPurchaseArray = [];
                }

                if (inPurchaseArray.indexOf(this.state.uid) != -1) {
                  inPurchaseArray.splice(
                    inPurchaseArray.indexOf(this.state.uid),
                    1
                  );
                  let inPurchaseStr = inPurchaseArray.join(",");

                  firebase
                    .database()
                    .ref("products/" + id)
                    .update({
                      current_pay_user: inPurchaseStr,
                      users_qty:
                        parseInt(valuesR.users_qty) - parseInt(user_qty)
                    });
                }

                if (waityArray.indexOf(this.state.uid) != -1) {
                  waityArray.splice(waityArray.indexOf(this.state.uid), 1);
                  let waityArrayStr = waityArray.join(",");

                  firebase
                    .database()
                    .ref("products/" + id)
                    .update({
                      users_queue: waityArrayStr
                    });
                }
              }
            }, 10);
          });

          /*
          if (index == ID_s.length - 1) {
          }*/
        });
      }
    }
  }

  removeItemsAndBackToCart() {
    let ID_s = this.state.products_ids;

    if (ID_s) {
      if (ID_s.length > 0) {
        ID_s.map((item, index) => {
          let id = item.product_option_id;
          let all_qty = item.all_qty;
          let user_qty = item.user_qty;
          let cart_id = item.cart_id;
          firebase
            .database()
            .ref("products/" + id)
            .off("value");

          let thisTask2 = firebase.database().ref("products/" + id);

          thisTask2.once("value", snapshot2 => {
            setTimeout(async () => {
              if (snapshot2.exists()) {
                const valuesR = snapshot2.val();

                let inPurchaseArray = [];
                inPurchaseArray = valuesR.current_pay_user.split(",");
                if (parseInt(valuesR.current_pay_user.length) == 0) {
                  inPurchaseArray = [];
                }

                let inWaitingArray = [];
                inWaitingArray = valuesR.users_queue.split(",");
                if (parseInt(valuesR.users_queue.length) == 0) {
                  inWaitingArray = [];
                }

                if (inWaitingArray.indexOf(this.state.uid) != -1) {
                  inWaitingArray.splice(
                    inWaitingArray.indexOf(this.state.uid),
                    1
                  );
                  let inWaitingArrayStr = inWaitingArray.join(",");

                  firebase
                    .database()
                    .ref("products/" + id)
                    .update({
                      users_queue: inWaitingArrayStr
                    });

                  //Delete From Cart
                  await this.updateCartQTY(cart_id, 0);
                }

                if (inPurchaseArray.indexOf(this.state.uid) != -1) {
                  inPurchaseArray.splice(
                    inPurchaseArray.indexOf(this.state.uid),
                    1
                  );
                  let inPurchaseStr = inPurchaseArray.join(",");

                  firebase
                    .database()
                    .ref("products/" + id)
                    .update({
                      current_pay_user: inPurchaseStr,
                      users_qty:
                        parseInt(valuesR.users_qty) - parseInt(user_qty)
                    });
                }
              }
            }, 1000);
          });

          if (index == ID_s.length - 1) {
            //Back Cart
            window.location.href = "/Cart";
          }
        });
      }
    }
  }

  updateCartQTY(cart_option_id, new_qty) {
    fetch(Global.mobile_epi_link + "updateUserCartProductQTY", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        token: Global.mobile_token,
        cart_option_id: cart_option_id,
        new_qty: new_qty
      })
    })
      .then(response => response.json())
      .then(async res => {})
      .catch(error => {
        //this.updateCartQTY(cart_option_id, new_qty);
      });
  }
}
