import React, { Component } from "react";
import { Button, Modal } from "react-bootstrap";
import Global from "../Global.json";

class SizeGiudesModal extends Component {
    constructor(props){
        super(props);
    };

    
    render() {
        const {closeModal, modalImage} = this.props;
        return (
            <Modal
            show={true}
            className="sizes-modal"
            size="lg"
        >
            <Modal.Header closeButton onClick={closeModal} className="modal-header">
            </Modal.Header>
            <Modal.Body className="productDetails-ModalBody">
                {
                    console.log(this.props.modalImage)
                }
                <img src={`${Global.image_link}${modalImage}`} style={{maxWidth: "100%"}} />
            </Modal.Body>
        </Modal>
        );
    }
}

export default SizeGiudesModal;
