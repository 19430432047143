import React, { Component } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import InputRange from "react-input-range";
import "react-input-range/lib/css/index.css";
import Global from "../Global.json";
import Skeleton from "react-loading-skeleton";
import swal from "sweetalert";
import Img from "react-cool-img";

class TodayDeals extends Component {
    constructor(props) {
        super();
        this.state = {
            user_id: localStorage.getItem("user_id"),
            language: localStorage.getItem("language")
                ? localStorage.getItem("language")
                : "english",
            CatID: props.match.params.CatID,
            CategoryDone: true,
            Categories: [],
            details: false,
            value: { min: 0, max: 0 },
            colorSelected: 1,
            Adsimages: {},
            AdsimagesLoader: true,
            products: [],
            productsLoader: true,
            min: 0,
            max: 0,
            minMaxPriceLoader: true,
            activePage: 1,
            rateSelected: -1,
            no_more_data: false,
            scrollDataFlag: false,
        };
        this.handleScroll = this.handleScroll.bind(this);
    }
    componentWillMount() {
        if (this.state.language !== "english") {
            this.setState({
                i18n: Global.ar,
            });
        } else {
            this.setState({
                i18n: Global.en,
            });
        }
        this.getAdsImages();
        // this.getMaxMinPrice();
        this.getProductsByCategory();
    }

    //   componentDidMount() {
    //     window.addEventListener("scroll", this.handleScroll);
    //   }

    /** get Ads Images Start */
    getAdsImages = () => {
        fetch(Global.api_link + "getAdsImages", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                token: Global.token,
            }),
        })
            .then((response) => response.json())
            .then((result) => {
                this.setState({
                    Adsimages: result.data,
                    AdsimagesLoader: false,
                });
            });
    };
    /** get Ads Images  end */

    /** get products by category Start */
    getProductsByCategory() {
        fetch(Global.api_link + "getTodayDeals", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                token: Global.token,
                lang: this.state.language,
                // category_id: this.props.match.params.CatID,
                // Min:
                //   parseInt(this.state.value.min) != 0
                //     ? parseInt(this.state.value.min)
                //     : -1,
                // Max:
                //   parseInt(this.state.value.max) != 0
                //     ? parseInt(this.state.value.max)
                //     : -1,
                // activePage: this.state.activePage,
                // Sort: 1,
                user_id: this.state.user_id,
                // rate: this.state.rateSelected,
            }),
        })
            .then((response) => response.json())
            .then((result) => {
                // if (this.state.activePage == 1) {
                this.setState({
                    products: result.randomOffer,
                    productsLoader: false,
                    // scrollDataFlag: false,
                });
                // } else {
                //   if (result.data.length > 0) {
                //     let old_data = this.state.products;
                //     let new_data = old_data.concat(result.data);
                //     this.setState({
                //       products: new_data,
                //       productsLoader: false,
                //       scrollDataFlag: false,
                //     });
                //   } else {
                //     this.setState({
                //       // products: [],
                //       activePage:parseInt(this.state.activePage ) - 1,
                //       no_more_data: true,
                //       scrollDataFlag: false,
                //       minMaxPriceLoader: false,
                //     } , () => console.log(this.state.activePage , 'active'));
                //   }
                // }
            });
    }
    /** get products by category end */

    /** get max and min price Start */
    getMaxMinPrice = () => {
        fetch(Global.api_link + "getSingleCategoryPricesFilter", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                token: Global.token,
                category_id: this.props.match.params.CatID,
            }),
        })
            .then((response) => response.json())
            .then((result) => {
                this.setState(
                    {
                        value: {
                            min:
                                result.MinValue.price &&
                                result.MinValue.price != result.MaxValue.price
                                    ? parseInt(result.MinValue.price)
                                    : 0,
                            max: result.MaxValue.price
                                ? parseInt(result.MaxValue.price)
                                : 0,
                        },
                        min:
                            result.MinValue.price &&
                            result.MinValue.price != result.MaxValue.price
                                ? parseInt(result.MinValue.price)
                                : 0,
                        max: result.MaxValue.price
                            ? parseInt(result.MaxValue.price)
                            : 1000,
                        minMaxPriceLoader: false,
                    },
                    () => {
                        this.getProductsByCategory();
                    }
                );
            });
    };
    /** get max and min price Start */
    render() {
        return (
            <div className="search-page">
                {/* best gift section  */}
                {this.state.AdsimagesLoader ? (
                    <Skeleton height={300} />
                ) : (
                    <div className="landing-page">
                        {console.log(
                            Global.image_link + this.state.Adsimages.image,
                            "now"
                        )}
                        <img
                            src={Global.image_link + this.state.Adsimages.image}
                            alt="l"
                            onClick={() => {
                                window.location.href =
                                    this.state.Adsimages.link;
                            }}
                        />
                    </div>
                )}
                {/* best gift section end */}

                <Container>
                    <Row style={{ justifyContent: "center" }}>
                        {/* filter section  */}
                        {/* <Col md={4} lg={3} xl={2} className="no-padd">
              <div className="filter">
                <div className="filterTitle">
                  <h4> {this.state.i18n.Filters}</h4>
                  <div className="hr"></div>
                </div> */}
                        {/* price input */}
                        {/* <div className="price">
                  <p>{this.state.i18n.Price}</p>
                  {this.state.minMaxPriceLoader ? (
                    <Skeleton height={50} />
                  ) : (
                    (console.log(this.state.max, this.state.min, "max , min"),
                    (
                      <InputRange
                        maxValue={this.state.max}
                        minValue={this.state.min}
                        value={this.state.value}
                        formatLabel={(value) => `${value} JOD`}
                        // onChange={(value) => this.setState({ value })}
                        onChange={(value) => {
                          this.setState({
                            value: value,
                            scrollDataFlag: true,
                            activePage: 1,
                            products: [],
                          });
                        }}
                        onChangeComplete={() => this.getProductsByCategory()}
                      />
                    ))
                  )}
                </div> */}
                        {/* price input */}

                        {/* rate section   */}

                        {/* <div className="rate">
                  <p> {this.state.i18n.Rating}</p>
                  <div>
                    <p
                      onClick={() => {
                        this.setState({
                          rateSelected: 5,
                        }, ()=>this.getProductsByCategory());
                      }}
                      className={
                        this.state.rateSelected === 5 ? "rateBorder" : ""
                      }
                    >
                      {" "}
                      {this.state.rateSelected === 5 ? (
                        <i className="fa fa-check"></i>
                      ) : null}
                    </p>
                    <ul>
                      <li>
                        <i class="fa fa-star" style={{ color: "#1A9C8C" }}></i>
                      </li>
                      <li>
                        <i class="fa fa-star" style={{ color: "#1A9C8C" }}></i>
                      </li>
                      <li>
                        <i class="fa fa-star" style={{ color: "#1A9C8C" }}></i>
                      </li>
                      <li>
                        <i class="fa fa-star" style={{ color: "#1A9C8C" }}></i>
                      </li>
                      <li>
                        <i class="fa fa-star" style={{ color: "#1A9C8C" }}></i>
                      </li>
                    </ul>
                  </div>
                  <div>
                    <p
                      onClick={() => {
                        this.setState({
                          rateSelected: 4,
                        }, ()=>this.getProductsByCategory());
                      }}
                      className={
                        this.state.rateSelected === 4 ? "rateBorder" : ""
                      }
                    >
                      {" "}
                      {this.state.rateSelected === 4 ? (
                        <i className="fa fa-check"></i>
                      ) : null}
                    </p>
                    <ul>
                      <li>
                        <i class="fa fa-star" style={{ color: "#1A9C8C" }}></i>
                      </li>
                      <li>
                        <i class="fa fa-star" style={{ color: "#1A9C8C" }}></i>
                      </li>
                      <li>
                        <i class="fa fa-star" style={{ color: "#1A9C8C" }}></i>
                      </li>
                      <li>
                        <i class="fa fa-star" style={{ color: "#1A9C8C" }}></i>
                      </li>
                      <li>
                        <i class="fa fa-star"></i>
                      </li>
                    </ul>
                  </div>
                  <div>
                    <p
                      onClick={() => {
                        this.setState({
                          rateSelected: 3,
                        }, ()=>this.getProductsByCategory());
                      }}
                      className={
                        this.state.rateSelected === 3 ? "rateBorder" : ""
                      }
                    >
                      {" "}
                      {this.state.rateSelected === 3 ? (
                        <i className="fa fa-check"></i>
                      ) : null}
                    </p>
                    <ul>
                      <li>
                        <i class="fa fa-star" style={{ color: "#1A9C8C" }}></i>
                      </li>
                      <li>
                        <i class="fa fa-star" style={{ color: "#1A9C8C" }}></i>
                      </li>
                      <li>
                        <i class="fa fa-star" style={{ color: "#1A9C8C" }}></i>
                      </li>
                      <li>
                        <i class="fa fa-star"></i>
                      </li>
                      <li>
                        <i class="fa fa-star"></i>
                      </li>
                    </ul>
                  </div>
                  <div>
                    <p
                      onClick={() => {
                        this.setState({
                          rateSelected: 2,
                        }, ()=>this.getProductsByCategory());
                      }}
                      className={
                        this.state.rateSelected === 2 ? "rateBorder" : ""
                      }
                    >
                      {" "}
                      {this.state.rateSelected === 2 ? (
                        <i className="fa fa-check"></i>
                      ) : null}
                    </p>
                    <ul>
                      <li>
                        <i class="fa fa-star" style={{ color: "#1A9C8C" }}></i>
                      </li>
                      <li>
                        <i class="fa fa-star" style={{ color: "#1A9C8C" }}></i>
                      </li>
                      <li>
                        <i class="fa fa-star"></i>
                      </li>
                      <li>
                        <i class="fa fa-star"></i>
                      </li>
                      <li>
                        <i class="fa fa-star"></i>
                      </li>
                    </ul>
                  </div>
                  <div>
                    <p
                      onClick={() => {
                        this.setState({
                          rateSelected: 1,
                          products : []
                        }, ()=>this.getProductsByCategory());
                      }}
                      className={
                        this.state.rateSelected === 1 ? "rateBorder" : ""
                      }
                    >
                      {" "}
                      {this.state.rateSelected === 1 ? (
                        <i className="fa fa-check"></i>
                      ) : null}
                    </p>
                    <ul>
                      <li>
                        <i class="fa fa-star" style={{ color: "#1A9C8C" }}></i>
                      </li>
                      <li>
                        <i class="fa fa-star"></i>
                      </li>
                      <li>
                        <i class="fa fa-star"></i>
                      </li>
                      <li>
                        <i class="fa fa-star"></i>
                      </li>
                      <li>
                        <i class="fa fa-star"></i>
                      </li>
                    </ul>
                  </div>
                  <div>
                    <p
                      onClick={() => {
                        this.setState({
                          rateSelected: 0,
                          products : []
                        }, ()=>this.getProductsByCategory());
                      }}
                      className={
                        this.state.rateSelected === 0 ? "rateBorder" : ""
                      }
                    >
                      {" "}
                      {this.state.rateSelected === 0 ? (
                        <i className="fa fa-check"></i>
                      ) : null}
                    </p>
                    <ul>
                      <li>
                        <i class="fa fa-star" ></i>
                      </li>
                      <li>
                        <i class="fa fa-star"></i>
                      </li>
                      <li>
                        <i class="fa fa-star"></i>
                      </li>
                      <li>
                        <i class="fa fa-star"></i>
                      </li>
                      <li>
                        <i class="fa fa-star"></i>
                      </li>
                    </ul>
                  </div>
                </div> */}
                        {/* rate section   */}
                        {/* apply button   */}

                        {/* <div className="applyButton">
                  <a
                    onClick={() => {
                      this.getProductsByCategory();
                    }}
                  >
                    APPLAY
                  </a>
                </div> */}
                        {/* apply button end  */}
                        {/* </div>
            </Col> */}
                        {/* filter section end */}

                        {/* products show section  */}

                        <Col md={8} lg={9} xl={10}>
                            <div className="products">
                                <Row>
                                    {/* product view */}
                                    {this.state.productsLoader ? (
                                        <>
                                            <Col xs={12} sm={6} lg={4} xl={3}>
                                                <Skeleton height={300} />
                                            </Col>
                                            <Col xs={12} sm={6} lg={4} xl={3}>
                                                <Skeleton height={300} />
                                            </Col>
                                            <Col xs={12} sm={6} lg={4} xl={3}>
                                                <Skeleton height={300} />
                                            </Col>
                                            <Col xs={12} sm={6} lg={4} xl={3}>
                                                <Skeleton height={300} />
                                            </Col>
                                        </>
                                    ) : this.state.products.length < 1 &&
                                      !this.state.scrollDataFlag ? (
                                        <div className="emptyProduct text-center">
                                            <img
                                                src="/img/noProduct.png"
                                                alt="l"
                                                style={{
                                                    objectFit: "contain",
                                                    margin: "auto",
                                                    maxWidth: "250px",
                                                }}
                                            />

                                            <div
                                                className="noProductToShow"
                                                style={{
                                                    margin: "auto",
                                                    padding: "0",
                                                }}
                                            >
                                                {this.state.i18n.noItemToShow}
                                            </div>
                                        </div>
                                    ) : (
                                        this.state.products.map(
                                            (item, index) => {
                                                return (
                                                    <Col
                                                        xs={12}
                                                        sm={6}
                                                        lg={4}
                                                        xl={3}
                                                        key={index}
                                                    >
                                                        <div className="product">
                                                            <img
                                                                src={
                                                                    Global.image_link +
                                                                    item.image
                                                                }
                                                                alt="l"
                                                                onClick={() => {
                                                                    window.location.href =
                                                                        "/Details/" +
                                                                        item.id;
                                                                }}
                                                            />
                                                            <div className="product-namePrice">
                                                                <p>
                                                                    {item.title}
                                                                </p>
                                                                <div className="product-Price-rate">
                                                                    <span>
                                                                        {
                                                                            item.price
                                                                        }{" "}
                                                                        JOD
                                                                    </span>
                                                                    <ul>
                                                                        <li>
                                                                            <i
                                                                                className={
                                                                                    item.rate >
                                                                                    0
                                                                                        ? "fa fa-star"
                                                                                        : "fa fa-star-o"
                                                                                }
                                                                            ></i>
                                                                        </li>
                                                                        <li>
                                                                            <i
                                                                                className={
                                                                                    item.rate >
                                                                                    1
                                                                                        ? "fa fa-star"
                                                                                        : "fa fa-star-o"
                                                                                }
                                                                            ></i>
                                                                        </li>
                                                                        <li>
                                                                            <i
                                                                                className={
                                                                                    item.rate >
                                                                                    2
                                                                                        ? "fa fa-star"
                                                                                        : "fa fa-star-o"
                                                                                }
                                                                            ></i>
                                                                        </li>
                                                                        <li>
                                                                            <i
                                                                                className={
                                                                                    item.rate >
                                                                                    3
                                                                                        ? "fa fa-star"
                                                                                        : "fa fa-star-o"
                                                                                }
                                                                            ></i>
                                                                        </li>
                                                                        <li>
                                                                            <i
                                                                                className={
                                                                                    item.rate >
                                                                                    4
                                                                                        ? "fa fa-star"
                                                                                        : "fa fa-star-o"
                                                                                }
                                                                            ></i>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                            <div className="addCartWish">
                                                                <button
                                                                    onClick={() =>
                                                                        this
                                                                            .state
                                                                            .user_id !==
                                                                        "0"
                                                                            ? (window.location.href =
                                                                                  "/Details/" +
                                                                                  item.id)
                                                                            : (window.location.href =
                                                                                  "/Login")
                                                                    }
                                                                >
                                                                    {
                                                                        this
                                                                            .state
                                                                            .i18n
                                                                            .AddToCartOption
                                                                    }
                                                                </button>
                                                                <button
                                                                    onClick={() =>
                                                                        this
                                                                            .state
                                                                            .user_id !==
                                                                        "0"
                                                                            ? this.AddToFavour(
                                                                                  item.id
                                                                              )
                                                                            : (window.location.href =
                                                                                  "/Login")
                                                                    }
                                                                >
                                                                    <i
                                                                        className={
                                                                            item.is_favor
                                                                                ? "fa fa-heart red"
                                                                                : "fa fa-heart-o"
                                                                        }
                                                                    ></i>
                                                                    {/* {item.is_favor
                                  ? this.state.i18n.favorited
                                  : this.state.i18n.favorite} */}
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                );
                                            }
                                        )
                                    )}
                                    {/* product  view end */}
                                </Row>
                            </div>
                        </Col>
                        {/* products show section end   */}
                        {this.state.scrollDataFlag ? (
                            <div
                                className="col-lg-12 col-md-12 col-sm-12 col-xs-12 no_pad"
                                style={{ textAlign: "center" }}
                            >
                                <Img
                                    style={{ objectFit: "scale-down" }}
                                    src={"/img/loads2.gif"}
                                    placeholder={"/img/loads2.gif"}
                                    error={"/img/loads2.gif"}
                                    className="card-img-top image"
                                />
                            </div>
                        ) : null}
                    </Row>
                </Container>
            </div>
        );
    }

    /****************** add to faviorite  ****************/

    AddToFavour(id) {
        fetch(Global.api_link + "AddRemoveFavorite", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                token: Global.token,
                pid: id,
                uid: this.state.user_id,
            }),
        })
            .then((response) => response.json())
            .then((result) => {
                // swal({
                //   title: this.state.i18n.DoneSuccess,
                //   text: this.state.i18n.AddedToFavourite,
                //   icon: "success",
                //   button: this.state.i18n.OK,
                // });
                this.getProductsByCategory();
                setTimeout(100);
            });
    }
    /****************** add to faviorite  end ****************/

    /****************** add to Cart ****************/

    // AddToCart(id) {
    //   fetch(Global.api_link + "addToCart", {
    //     method: "POST",
    //     headers: {
    //       Accept: "application/json",
    //       "Content-Type": "application/json",
    //     },
    //     body: JSON.stringify({
    //       token: Global.token,
    //       product_id: id,
    //       user_id: this.state.user_id,
    //       qty: 1,
    //     }),
    //   })
    //     .then((response) => response.json())
    //     .then((result) => {
    //       swal({
    //         title: this.state.i18n.DoneSuccess,
    //         text: this.state.i18n.ProductCart,
    //         icon: "success",
    //         button: this.state.i18n.OK,
    //       });

    //       this.CartCount();
    //     });
    // }
    /****************** add to Cart end ****************/

    /****************** cart count  ****************/

    CartCount() {
        fetch(Global.api_link + "getCartCount", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                token: Global.token,
                user_id: this.state.user_id,
            }),
        })
            .then((response) => response.json())
            .then((result) => {
                this.setState(
                    {
                        cartNum: result.data,
                    },
                    () => {}
                );
            });
    }
    /****************** cart count end ****************/

    /****************** show products by active page  ****************/

    handleScroll() {
        const windowHeight =
            "innerHeight" in window
                ? window.innerHeight
                : document.documentElement.offsetHeight;
        const body = document.body;
        const html = document.documentElement;
        const docHeight = Math.max(
            body.scrollHeight,
            body.offsetHeight,
            html.clientHeight,
            html.scrollHeight,
            html.offsetHeight
        );
        const windowBottom = windowHeight + window.pageYOffset;
        if (
            windowBottom >= parseInt(docHeight) - 400 &&
            !this.state.no_more_data &&
            !this.state.scrollDataFlag &&
            !this.state.productsLoader
        ) {
            this.setState(
                {
                    scrollDataFlag: true,
                    activePage: parseInt(parseInt(this.state.activePage) + 1),
                },
                () => {
                    this.getProductsByCategory();
                }
            );
        } else {
        }
    }
    /****************** show products by active page end ****************/
}

export default TodayDeals;
