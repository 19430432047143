import React, { Component } from "react";
import { Col, Container, Form, Row, Tab, Tabs } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import Global from "../Global.json";
//import Moment from 'react-moment';
import moment from "moment";

import "react-inner-image-zoom/lib/InnerImageZoom/styles.css";
import InnerImageZoom from "react-inner-image-zoom";
import swal from "sweetalert";
import Skeleton from "react-loading-skeleton";
import {
    FacebookShareButton,
    WhatsappIcon,
    WhatsappShareButton,
    FacebookIcon,
    EmailShareButton,
    EmailIcon,
} from "react-share";
import SizeGiudesModal from "./SizeGiudesModal";

// var moment = require('moment');
class ProductDetails extends Component {
    constructor() {
        super();
        this.state = {
            user_id: localStorage.getItem("user_id"),
            language: localStorage.getItem("language")
                ? localStorage.getItem("language")
                : "english",
            details: false,
            activeKey: "home",
            value: { min: 2, max: 10 },
            colorSelected: 1,
            numOfProd: 1,
            activeRate: 0,
            ProductDetails: {},
            RelatedOffers: [],
            productOptions: [],
            optionQty: 0,
            ProductDetailsLoader: true,
            images: [],
            optionimagesLoader: true,
            OptionsImages: [],
            optionIndex: 0,
            IsOptionSelected: false,
            addToWish: false,
            ratesComm: [],
            commentLoader: true,
            copyLink: false,
            qty: 1,
            closeModal: true,
            CatSizeGuide: null
        };
    }

    // render main img gallery carousel
    _renderCustomControls(item) {
        return (
            <InnerImageZoom
                zoomScale={1}
                // src={Global.image_link + item.original}
                src={
                    this.state.IsOptionSelected &&
                        this.state.OptionsImages.length < 1
                        ? Global.image_link +
                        this.state.productOptions[this.state.optionIndex]
                            .image
                        : Global.image_link + item.original
                }
                zoomSrc={
                    this.state.IsOptionSelected &&
                        this.state.OptionsImages.length < 1
                        ? Global.image_link +
                        this.state.productOptions[this.state.optionIndex]
                            .image
                        : Global.image_link + item.original
                }
            />
        );
    }
    // end render main img gallery carousel

    // render thubs img gallery carousel

    _renderCustomThumbs(item) {
        // if (this.state.IsOptionSelected && this.state.OptionsImages.length < 1) {
        // } else {
        return (
            <img
                src={
                    this.state.IsOptionSelected &&
                        this.state.OptionsImages.length < 1
                        ? Global.image_link +
                        this.state.productOptions[this.state.optionIndex]
                            .image
                        : Global.image_link + item.thumbnail
                }
                alt="l"
                style={{ width: "100%" }}
            />
        );
        // }
    }
    // end render thubs img gallery carousel

    componentWillMount() {
        if (this.state.language !== "english") {
            this.setState({
                i18n: Global.ar,
            });
        } else {
            this.setState({
                i18n: Global.en,
            });
        }
        this.ProductDetails();
        this.getratesComm();
    }

    /** get Product Details Start */
    ProductDetails = () => {
        fetch(Global.api_link + "OfferDetails", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                token: Global.token,
                lang: this.state.language,
                offer_id: this.props.match.params.PID,
                user_id: this.state.user_id,
            }),
        })
            .then((response) => response.json())
            .then((result) => {
                let array = [];
                if (result.Images.length > 0) {
                    result.Images.map((item) => {
                        array.push({
                            original: item.path,
                            thumbnail: item.path_thumb,
                        });
                    });

                    this.setState({
                        ProductDetails: result.data,
                        images: array,
                        RelatedOffers: result.RelatedOffers,
                        productOptions: result.Options,
                        optionQty:
                            result.Options.length == 1
                                ? result.Options[0].qty
                                : 0,

                        addToWish: result.favor,
                        ProductDetailsLoader: false,
                        CatSizeGuide: result.getCatSizeGuide
                    });
                } else {
                    this.setState({
                        ProductDetails: result.data,
                        images: [
                            {
                                original: result.data.image,
                                thumbnail: result.data.image_thumb,
                            },
                        ],
                        RelatedOffers: result.RelatedOffers,
                        productOptions: result.Options,
                        optionQty:
                            result.Options.length == 1
                                ? result.Options[0].qty
                                : 0,

                        addToWish: result.favor,
                        ProductDetailsLoader: false,
                    });
                }
            });
    };
    /** get Product Details  end */

    quantity_increase = () => {
        // const {qty} = this.state;
        this.setState({
            qty: ++this.state.qty,
        });
    };

    quantity_decrease = () => {
        // const {qty} = this.state;
        if (this.state.qty === 1) {
            return;
        } else {
            this.setState({
                qty: --this.state.qty,
            });
        }
    };

    /** get Comments */
    getratesComm() {
        fetch(Global.api_link + "getAllProductRateComments", {
            method: "POST",
            headers: {
                Accept: "application/json",
            },
            body: JSON.stringify({
                token: Global.token,
                offer_id: this.props.match.params.PID,
                // offer_id: 200,
            }),
        })
            .then((response) => response.json())
            .then((result) => {
                this.setState({
                    ratesComm: result.data,
                    commentLoader: false,
                });
            })
            .catch((error) => {
                console.error(error);
            });
    }

    closeModal = () => {
        this.setState({
            closeModal: true
        })
    }

    render() {
        //let   date = moment().format("YYYY-MM-DD");
        let isBefore = moment().isBefore(
            this.state.ProductDetails.label_valid_date
        );
        //alert(isBefore);

        return (
            <div className="product-details">
                <Container>
                    {!this.state.closeModal && <SizeGiudesModal closeModal={this.closeModal} modalImage={this.state.CatSizeGuide} />}

                    <div className="productDetails-top">
                        {this.state.ProductDetailsLoader ? (
                            <Skeleton height={500} />
                        ) : (
                            <Row>
                                {/* product details section  */}

                                <Col md={6}>
                                    {/* product image zoom  */}
                                    {this.state.OptionsImages.length > 0 ? (
                                        <ImageGallery
                                            items={this.state.OptionsImages}
                                            thumbnailPosition="left"
                                            showNav={false}
                                            showFullscreenButton={false}
                                            showPlayButton={false}
                                            renderItem={(images) =>
                                                this._renderCustomControls(
                                                    images
                                                )
                                            }
                                            showThumbnails={true}
                                            renderThumbInner={(images) =>
                                                this._renderCustomThumbs(images)
                                            }
                                        />
                                    ) : this.state.IsOptionSelected &&
                                        this.state.OptionsImages.length == 0 ? (
                                        <ImageGallery
                                            items={[
                                                {
                                                    original:
                                                        Global.image_link +
                                                        this.state
                                                            .productOptions[
                                                            this.state
                                                                .optionIndex
                                                        ].image,
                                                    thumbnail:
                                                        Global.image_link +
                                                        this.state
                                                            .productOptions[
                                                            this.state
                                                                .optionIndex
                                                        ].image_thumb,
                                                },
                                            ]}
                                            thumbnailPosition="left"
                                            showNav={false}
                                            showFullscreenButton={false}
                                            showPlayButton={false}
                                            showThumbnails={true}
                                            renderItem={(images) =>
                                                this._renderCustomControls(
                                                    images
                                                )
                                            }
                                            renderThumbInner={(images) =>
                                                this._renderCustomThumbs(images)
                                            }
                                        />
                                    ) : (
                                        <ImageGallery
                                            items={this.state.images}
                                            thumbnailPosition="left"
                                            showNav={false}
                                            showFullscreenButton={false}
                                            showPlayButton={false}
                                            showThumbnails={true}
                                            renderItem={(images) =>
                                                this._renderCustomControls(
                                                    images
                                                )
                                            }
                                            renderThumbInner={(images) =>
                                                this._renderCustomThumbs(images)
                                            }
                                        />
                                    )}

                                    {/* product image zoom  end */}
                                </Col>

                                <Col md={6}>
                                    {/* product info  */}
                                    <div className="product-details-rigth">
                                        <h4>
                                            {" "}
                                            {this.state.ProductDetails.title}
                                        </h4>
                                        {this.state.ProductDetails.label &&
                                            isBefore ? (
                                            <span
                                                className={"labelCardDetails"}
                                            >
                                                {
                                                    this.state.ProductDetails
                                                        .label
                                                }{" "}
                                            </span>
                                        ) : null}
                                        <h5 className="product_id">
                                            {" "}
                                            {this.state.i18n.ID} :{" "}
                                            {this.state.ProductDetails.id}{" "}
                                        </h5>
                                        <p>
                                            {this.MyComponent(
                                                this.state.ProductDetails
                                                    .short_desc
                                            )}
                                        </p>
                                        {/* product rate  */}
                                        <div className="form-group">
                                            <div
                                                className="rate quantity buttons_added mt-5 border-none align-item-center"
                                                style={{
                                                    fontSize: "17px",
                                                    fontFamily:
                                                        "RegularBodyRoman",
                                                }}
                                            >
                                                <div>
                                                    <p className="d-flex justify-content-start">
                                                        {
                                                            this.state.i18n._Quantity
                                                        }
                                                    </p>
                                                    <input
                                                        type="button"
                                                        value="-"
                                                        className="minus"
                                                        onClick={
                                                            this.quantity_decrease
                                                        }
                                                    />
                                                    <input
                                                        type="number"
                                                        step="1"
                                                        min="1"
                                                        value={this.state.qty}
                                                        name="qty"
                                                        className="input-text qty text"
                                                        size="4"
                                                        pattern=""
                                                        inputmode=""
                                                    />
                                                    <input
                                                        type="button"
                                                        value="+"
                                                        className="plus"
                                                        onClick={
                                                            this
                                                                .quantity_increase
                                                        }
                                                    />
                                                </div>
                                                {
                                                    this.state.CatSizeGuide === null ? "" : <div>
                                                        <span className="aizesGuidesBtn" onClick={() => {
                                                            this.setState({
                                                                closeModal: false
                                                            })
                                                        }} >
                                                            {
                                                                this.state.i18n.sizesGuide
                                                            }
                                                        </span>
                                                    </div>
                                                }

                                            </div>
                                        </div>
                                        <div className="rate productDetailsRate">
                                            <div>
                                                <span>
                                                    {this.state.i18n.rate}
                                                </span>
                                                <ul>
                                                    <li>
                                                        <i
                                                            className={
                                                                this.state
                                                                    .ProductDetails
                                                                    .rate > 0
                                                                    ? "fa fa-star"
                                                                    : "fa fa-star-o"
                                                            }
                                                        ></i>
                                                    </li>
                                                    <li>
                                                        <i
                                                            className={
                                                                this.state
                                                                    .ProductDetails
                                                                    .rate > 1
                                                                    ? "fa fa-star"
                                                                    : "fa fa-star-o"
                                                            }
                                                        ></i>
                                                    </li>
                                                    <li>
                                                        <i
                                                            className={
                                                                this.state
                                                                    .ProductDetails
                                                                    .rate > 2
                                                                    ? "fa fa-star"
                                                                    : "fa fa-star-o"
                                                            }
                                                        ></i>
                                                    </li>
                                                    <li>
                                                        <i
                                                            className={
                                                                this.state
                                                                    .ProductDetails
                                                                    .rate > 3
                                                                    ? "fa fa-star"
                                                                    : "fa fa-star-o"
                                                            }
                                                        ></i>
                                                    </li>
                                                    <li>
                                                        <i
                                                            className={
                                                                this.state
                                                                    .ProductDetails
                                                                    .rate > 4
                                                                    ? "fa fa-star"
                                                                    : "fa fa-star-o"
                                                            }
                                                        ></i>
                                                    </li>
                                                </ul>
                                            </div>

                                            <div className="ShareRow">
                                                <h5>
                                                    {this.state.i18n.ShareText}
                                                </h5>
                                                <ul>
                                                    <a>
                                                        <li>
                                                            <FacebookShareButton
                                                                url={
                                                                    Global.DetailsShareLink +
                                                                    this.props
                                                                        .match
                                                                        .params
                                                                        .PID
                                                                }
                                                                separator=":: "
                                                                // title={this.state.Details.title}
                                                                quote={
                                                                    "Look at this amazing offer from Sooq"
                                                                }
                                                            >
                                                                <FacebookIcon
                                                                    size={32}
                                                                    round
                                                                />
                                                            </FacebookShareButton>
                                                        </li>
                                                    </a>
                                                    <a>
                                                        <li>
                                                            <WhatsappShareButton
                                                                url={
                                                                    Global.DetailsShareLink +
                                                                    this.props
                                                                        .match
                                                                        .params
                                                                        .PID
                                                                }
                                                                separator=":: "
                                                            // title={this.state.Details.title}
                                                            >
                                                                <WhatsappIcon
                                                                    size={32}
                                                                    round
                                                                />
                                                            </WhatsappShareButton>
                                                        </li>
                                                    </a>
                                                    <a>
                                                        <li>
                                                            <EmailShareButton
                                                                url={
                                                                    Global.DetailsShareLink +
                                                                    this.props
                                                                        .match
                                                                        .params
                                                                        .PID
                                                                }
                                                                // subject={this.state.Details.title}
                                                                body="I want you to see this offer from Sooq "
                                                                separator=":: "
                                                            // title={this.state.Details.title}
                                                            >
                                                                <EmailIcon
                                                                    size={32}
                                                                    round
                                                                />
                                                            </EmailShareButton>
                                                        </li>
                                                    </a>
                                                    <a title="Copy">
                                                        <li
                                                            className="copyLinkLi"
                                                            onClick={() => {
                                                                if (
                                                                    !this.state
                                                                        .copyLink
                                                                ) {
                                                                    this.copyPageUrl(
                                                                        Global.DetailsShareLink +
                                                                        this
                                                                            .props
                                                                            .match
                                                                            .params
                                                                            .PID
                                                                    );
                                                                }
                                                            }}
                                                        >
                                                            <img src="/img/copy2.svg"></img>
                                                        </li>
                                                    </a>
                                                </ul>
                                            </div>
                                        </div>
                                        {/* product rate  end */}

                                        {/* size and availabe qty  */}
                                        {/* {this.state
                                            .ProductDetailsLoader ? null : (
                                            <ul className="moreProdDetails">
                                                {!this.state.IsOptionSelected ||
                                                    this.state.productOptions[
                                                        this.state.optionIndex
                                                    ].size < 1 ? null : (
                                                    <li>
                                                        <span>
                                                            {
                                                                this.state.i18n
                                                                    .Size
                                                            }
                                                        </span>
                                                        <span>
                                                            {
                                                                this.state
                                                                    .productOptions[
                                                                    this.state
                                                                        .optionIndex
                                                                ].size
                                                            }
                                                        </span>
                                                    </li>
                                                )}
                                                {!this.state.IsOptionSelected ||
                                                    this.state.productOptions[
                                                        this.state.optionIndex
                                                    ].weight < 1 ? null : (
                                                    <li>
                                                        <span>
                                                            {
                                                                this.state.i18n
                                                                    .weight
                                                            }
                                                        </span>{" "}
                                                        <span>
                                                            {" "}
                                                            {
                                                                this.state
                                                                    .productOptions[
                                                                    this.state
                                                                        .optionIndex
                                                                ].weight
                                                            }
                                                        </span>
                                                    </li>
                                                )}
                                                {this.state.IsOptionSelected &&
                                                    this.state.productOptions[
                                                        this.state.optionIndex
                                                    ].qty > 0 ? (
                                                    <li>
                                                        <span>
                                                            {
                                                                this.state.i18n
                                                                    .Quantity
                                                            }
                                                        </span>{" "}
                                                        <span>
                                                            {" "}
                                                            {
                                                                this.state
                                                                    .productOptions[
                                                                    this.state
                                                                        .optionIndex
                                                                ].qty
                                                            }
                                                        </span>
                                                    </li>
                                                ) : null}
                                            </ul>
                                        )} */}
                                        {/* size and availabe qty  end */}

                                        {/* product options */}
                                        <div className="product-options">
                                        {this.state.IsOptionSelected ||
                                                this.state.productOptions.length ==
                                                1 ? (
                                                <>
                                                    {/* product price  */}
                                                    <p className="priceProduct">
                                                        {parseFloat(
                                                            this.state
                                                                .productOptions[
                                                                this.state
                                                                    .optionIndex
                                                            ].price*localStorage.getItem("CurrentCurrency")
                                                        ).toFixed(2) +
                                                            " " +
                                                            this.state.i18n[localStorage.getItem("Currency")]}
                                                    </p>
                                                    {/* product price  end */}
                                                </>
                                            ) : null}
                                        </div>
                                        <div className="product-options">
                                            {this.state.productOptions.length >
                                                1 ? (
                                                <ul>
                                                    <li>
                                                        <p>
                                                            {
                                                                this.state.i18n
                                                                    .Options
                                                            }
                                                        </p>
                                                        <select
                                                            onChange={(e) => {
                                                                this.setState(
                                                                    {
                                                                        // numOfProd: 1,
                                                                        // optionQty: this.state.productOptions[
                                                                        //   e.target.value
                                                                        // ].qty,
                                                                        optionIndex:
                                                                            e
                                                                                .target
                                                                                .value,
                                                                    },
                                                                    () => {
                                                                        this.OptionImages(
                                                                            this
                                                                                .state
                                                                                .optionIndex
                                                                        );
                                                                    }
                                                                );
                                                            }}
                                                        >
                                                            {this.state
                                                                .productOptions
                                                                .length > 1 ? (
                                                                <>
                                                                    <option
                                                                        selected
                                                                        disabled
                                                                    >
                                                                        {
                                                                            this
                                                                                .state
                                                                                .i18n
                                                                                .ChooseOption
                                                                        }
                                                                    </option>
                                                                    {this.state.productOptions.map(
                                                                        (
                                                                            item,
                                                                            index
                                                                        ) => {
                                                                            return (
                                                                                <option
                                                                                    value={
                                                                                        index
                                                                                    }
                                                                                >
                                                                                    {
                                                                                        item.title
                                                                                    }
                                                                                </option>
                                                                            );
                                                                        }
                                                                    )}
                                                                </>
                                                            ) : (
                                                                this.state.productOptions.map(
                                                                    (
                                                                        item,
                                                                        index
                                                                    ) => {
                                                                        return (
                                                                            <option
                                                                                selected={
                                                                                    index ==
                                                                                        0
                                                                                        ? true
                                                                                        : false
                                                                                }
                                                                                value={
                                                                                    index
                                                                                }
                                                                            >
                                                                                {
                                                                                    item.title
                                                                                }
                                                                            </option>
                                                                        );
                                                                    }
                                                                )
                                                            )}
                                                        </select>
                                                    </li>
                                                    <li></li>
                                                </ul>
                                            ) : null}
                                            
                                        </div>
                                        {/* product options end */}

                                        <div className="footer-prodDetails">
                                            <Row>
                                                {this.state.IsOptionSelected ||
                                                    this.state.productOptions
                                                        .length == 1 ? (
                                                    <>
                                                        {
                                                            // this.state.IsOptionSelected &&
                                                            this.state
                                                                .productOptions[
                                                                this.state
                                                                    .optionIndex
                                                            ].qty < 1 ? (
                                                                <div className="soldOut addToCartDetalis">
                                                                    {
                                                                        this
                                                                            .state
                                                                            .i18n
                                                                            .SoldOut
                                                                    }
                                                                </div>
                                                            ) : (
                                                                <>
                                                                    <Col
                                                                        md={12}
                                                                        className="no-padd"
                                                                    >
                                                                        {/* add to cart butoon  */}
                                                                        {/* {this.state.optionQty < 1 &&
                              this.state.optionIndex == 0 ? ( */}
                                                                        <div className="addToCartButtons">
                                                                            <div
                                                                                className="addToCartDetalis"
                                                                                onClick={() => {
                                                                                    if (this
                                                                                        .state
                                                                                        .user_id !==
                                                                                        "0") {
                                                                                        this.AddToCart(
                                                                                            this
                                                                                                .state
                                                                                                .productOptions[
                                                                                                this
                                                                                                    .state
                                                                                                    .optionIndex
                                                                                            ]
                                                                                                .pid
                                                                                        );
                                                                                    } else {
                                                                                        localStorage.setItem("redirectTo", "/Details/" + this.props.match.params.PID);
                                                                                        window.location.href =
                                                                                            "/Login";
                                                                                    }

                                                                                }

                                                                                }
                                                                            >
                                                                                {" "}
                                                                                {
                                                                                    this
                                                                                        .state
                                                                                        .i18n
                                                                                        .AddToCartOption
                                                                                }
                                                                            </div>
                                                                            {/* <p className="Or">{this.state.i18n.Or}</p> */}
                                                                            {/* special oreder start  */}
                                                                            <div className="specialOrderButton">
                                                                                <a
                                                                                    href={
                                                                                        "https://api.whatsapp.com/send?phone=962789787575&text=" +
                                                                                        this
                                                                                            .state
                                                                                            .i18n
                                                                                            .HiSouqJO +
                                                                                        this
                                                                                            .state
                                                                                            .i18n
                                                                                            .IWantSpecialOrder +
                                                                                        ": " +
                                                                                        this
                                                                                            .props
                                                                                            .match
                                                                                            .params
                                                                                            .PID +
                                                                                        this
                                                                                            .state
                                                                                            .i18n
                                                                                            .withOptionID +
                                                                                        ": " +
                                                                                        this
                                                                                            .state
                                                                                            .productOptions[
                                                                                            this
                                                                                                .state
                                                                                                .optionIndex
                                                                                        ]
                                                                                            .pid +
                                                                                        " " +
                                                                                        this
                                                                                            .state
                                                                                            .i18n
                                                                                            .ProductName +
                                                                                        ": " +
                                                                                        this
                                                                                            .state
                                                                                            .ProductDetails
                                                                                            .title +
                                                                                        this
                                                                                            .state
                                                                                            .i18n
                                                                                            .OptionName +
                                                                                        ": " +
                                                                                        this
                                                                                            .state
                                                                                            .productOptions[
                                                                                            this
                                                                                                .state
                                                                                                .optionIndex
                                                                                        ]
                                                                                            .title
                                                                                    }
                                                                                >
                                                                                    {
                                                                                        this
                                                                                            .state
                                                                                            .i18n
                                                                                            .specialOrder
                                                                                    }
                                                                                </a>
                                                                            </div>

                                                                            {/* special oreder end  */}
                                                                        </div>

                                                                        {/* add to cart butoon end  */}
                                                                    </Col>
                                                                    <Col
                                                                        xs={12}
                                                                        className="no-padd"
                                                                    >
                                                                        {/* order by whatsapp button */}
                                                                        {/* {this.state.optionQty < 1 &&
                                  this.state.optionIndex == 0 ? null : ( */}
                                                                        <a
                                                                            href={
                                                                                "https://api.whatsapp.com/send?phone=962789787575&text=" +
                                                                                this
                                                                                    .state
                                                                                    .i18n
                                                                                    .HiSouqJO +
                                                                                this
                                                                                    .state
                                                                                    .i18n
                                                                                    .IWantToOrder +
                                                                                ": " +
                                                                                this
                                                                                    .props
                                                                                    .match
                                                                                    .params
                                                                                    .PID +
                                                                                this
                                                                                    .state
                                                                                    .i18n
                                                                                    .withOptionID +
                                                                                ": " +
                                                                                this
                                                                                    .state
                                                                                    .productOptions[
                                                                                    this
                                                                                        .state
                                                                                        .optionIndex
                                                                                ]
                                                                                    .pid +
                                                                                " " +
                                                                                this
                                                                                    .state
                                                                                    .i18n
                                                                                    .ProductName +
                                                                                ": " +
                                                                                this
                                                                                    .state
                                                                                    .ProductDetails
                                                                                    .title +
                                                                                this
                                                                                    .state
                                                                                    .i18n
                                                                                    .OptionName +
                                                                                ": " +
                                                                                this
                                                                                    .state
                                                                                    .productOptions[
                                                                                    this
                                                                                        .state
                                                                                        .optionIndex
                                                                                ]
                                                                                    .title
                                                                            }
                                                                            className="whatsAppButton"
                                                                            style={{
                                                                                color: "black",
                                                                            }}
                                                                        >
                                                                            {
                                                                                this
                                                                                    .state
                                                                                    .i18n
                                                                                    .OrderByWhatsApp
                                                                            }
                                                                        </a>
                                                                        {/* )} */}

                                                                        {/* order by whatsapp button end */}
                                                                    </Col>
                                                                </>
                                                            )
                                                        }
                                                    </>
                                                ) : null}
                                                <Col xs={4} className="no-padd">
                                                    {/* add to wish butoon  */}

                                                    <div>
                                                        <i
                                                            onClick={() => {
                                                                if (
                                                                    this.state
                                                                        .user_id !==
                                                                    "0" &&
                                                                    this.state
                                                                        .user_id !==
                                                                    0 &&
                                                                    this.state
                                                                        .user_id !==
                                                                    null
                                                                ) {
                                                                    this.setState(
                                                                        {
                                                                            addToWish:
                                                                                !this
                                                                                    .state
                                                                                    .addToWish,
                                                                        },
                                                                        () => {
                                                                            this.AddToFavour(
                                                                                this
                                                                                    .props
                                                                                    .match
                                                                                    .params
                                                                                    .PID
                                                                            );
                                                                        }
                                                                    )
                                                                } else {
                                                                    localStorage.setItem("redirectTo", "/Details/" + this.props.match.params.PID);
                                                                    window.location.href =
                                                                        "/Login"
                                                                }
                                                            }
                                                            }
                                                            className={
                                                                this.state
                                                                    .addToWish &&
                                                                    this.state
                                                                        .user_id !==
                                                                    "0"
                                                                    ? "fa fa-heart"
                                                                    : "fa fa-heart-o"
                                                            }
                                                        ></i>

                                                        {/* <span>
                          {this.state.addToWish
                            ? this.state.i18n.favorited

                            : 
                             this.state.i18n.favorite}
                        </span> */}
                                                    </div>
                                                    {/* add to wist butoon end  */}
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                    {/* product info  */}
                                </Col>
                                {/* product details section end */}
                            </Row>
                        )}
                    </div>

                    {/* related products section  */}
                    <div className="related-product">
                        {/* related products title  */}
                        {this.state.RelatedOffers.length < 1 ? null : (
                            <h2>{this.state.i18n.RelatedProduct}</h2>
                        )}
                        {/* related products title end */}

                        <Row>
                            {/* product view */}

                            {this.state.ProductDetailsLoader ? (
                                <>
                                    <Col xs={12} sm={6} lg={4} xl={4}>
                                        <Skeleton height={300} />
                                    </Col>
                                    <Col xs={12} sm={6} lg={4} xl={3}>
                                        <Skeleton height={300} />
                                    </Col>
                                    <Col xs={12} sm={6} lg={4} xl={3}>
                                        <Skeleton height={300} />
                                    </Col>
                                    <Col xs={12} sm={6} lg={4} xl={3}>
                                        <Skeleton height={300} />
                                    </Col>
                                </>
                            ) : (
                                this.state.RelatedOffers.map((item, index) => {
                                    return (
                                        <Col xs={12} sm={6} md={4} lg={4} >
                                            {/* <div className="product">
                        <img src="/img/wRHZ0kT8uL.png" alt="l" />
                        <div className="product-namePrice">
                          <p>{item.title}</p>
                          <span>{item.price} JOD</span>
                        </div>
                        <button>Add to Cart</button>
                      </div> */}
                                            <div className="product">
                                                <img
                                                    src={
                                                        Global.image_link +
                                                        item.image
                                                    }
                                                    alt="l"
                                                    onClick={() => {
                                                        window.location.href =
                                                            "/Details/" +
                                                            item.id;
                                                    }}
                                                />
                                                <div className="product-namePrice">
                                                    <h3>{item.title}</h3>
                                                    <div className="product-Price-rate">
                                                        <span className="text-center">
                                                        {(item.price*localStorage.getItem("CurrentCurrency")).toFixed(2)} 
                                                            {"  "} {this.state.i18n[localStorage.getItem("Currency")]}
                                                            {/* {
                                                                this.state.i18n.JOD
                                                                    
                                                            } */}
                                                        </span>
                                                        <ul>
                                                            <li>
                                                                <i
                                                                    className={
                                                                        item.rate >
                                                                            0
                                                                            ? "fa fa-star"
                                                                            : "fa fa-star-o"
                                                                    }
                                                                ></i>
                                                            </li>
                                                            <li>
                                                                <i
                                                                    className={
                                                                        item.rate >
                                                                            1
                                                                            ? "fa fa-star"
                                                                            : "fa fa-star-o"
                                                                    }
                                                                ></i>
                                                            </li>
                                                            <li>
                                                                <i
                                                                    className={
                                                                        item.rate >
                                                                            2
                                                                            ? "fa fa-star"
                                                                            : "fa fa-star-o"
                                                                    }
                                                                ></i>
                                                            </li>
                                                            <li>
                                                                <i
                                                                    className={
                                                                        item.rate >
                                                                            3
                                                                            ? "fa fa-star"
                                                                            : "fa fa-star-o"
                                                                    }
                                                                ></i>
                                                            </li>
                                                            <li>
                                                                <i
                                                                    className={
                                                                        item.rate >
                                                                            4
                                                                            ? "fa fa-star"
                                                                            : "fa fa-star-o"
                                                                    }
                                                                ></i>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="addCartWish">
                                                    <button
                                                        onClick={() =>
                                                            this.state
                                                                .user_id !==
                                                                "0" &&
                                                                this.state
                                                                    .user_id !==
                                                                0 &&
                                                                this.state
                                                                    .user_id !==
                                                                null
                                                                ? (window.location.href =
                                                                    "/Details/" +
                                                                    item.id)
                                                                : (window.location.href =
                                                                    "/Login")
                                                        }
                                                    >
                                                        {
                                                            this.state.i18n
                                                                .AddToCartOption
                                                        }
                                                    </button>
                                                    <button
                                                        onClick={() =>
                                                            this.state
                                                                .user_id !==
                                                                "0" &&
                                                                this.state
                                                                    .user_id !==
                                                                0 &&
                                                                this.state
                                                                    .user_id !==
                                                                null
                                                                ? this.AddToFavour(
                                                                    item.id
                                                                )
                                                                : (window.location.href =
                                                                    "/Login")
                                                        }
                                                    >
                                                        <i
                                                            className={
                                                                item.favor &&
                                                                    this.state
                                                                        .user_id !==
                                                                    "0"
                                                                    ? "fa fa-heart red"
                                                                    : "fa fa-heart-o"
                                                            }
                                                        ></i>
                                                        {" " +
                                                            this.state.i18n
                                                                .AddToWishlist}
                                                        {/* {item.favor
                                  ? this.state.i18n.favorited
                                  : this.state.i18n.favorite} */}
                                                    </button>
                                                </div>
                                            </div>
                                        </Col>
                                    );
                                })
                            )}

                            {/* product  view end */}
                        </Row>
                    </div>
                    {/* related products section end */}

                    {/* review and discription section  */}
                    <div className="descr-review-product">
                        <Tabs
                            defaultActiveKey="review"
                            id="uncontrolled-tab-example"
                            activeKey={this.state.activeKey}
                            onSelect={(k) =>
                                this.setState({
                                    activeKey: k,
                                })
                            }
                        >
                            {/* description tab section  */}
                            <Tab
                                eventKey="home"
                                title={this.state.i18n.DESCRIPTION}
                            >
                                <div className="desc_review">
                                    {this.MyComponent(
                                        this.state.ProductDetails.desc
                                    )}
                                </div>
                            </Tab>
                            {/* description tab section end  */}

                            {/* review tab section  */}

                            <Tab
                                eventKey="review"
                                title={this.state.i18n.review}
                            >
                                {this.state.ratesComm.length < 1 ? (
                                    <div className="desc_review">
                                        {this.state.i18n.NoCommentToShow}
                                    </div>
                                ) : (
                                    this.state.ratesComm.map((item, index) => {
                                        return (
                                            <div key={index} className="review">
                                                <img
                                                    src={
                                                        item.image
                                                            ? Global.image_link +
                                                            item.image
                                                            : item.face_image
                                                    }
                                                    alt="l"
                                                />
                                                <div className="review-detalis">
                                                    <div></div>
                                                    <div className="top-review-details">
                                                        <p>{item.name}</p>
                                                        <span>{item.date}</span>
                                                        <div className="rate-review">
                                                            <span>
                                                                {
                                                                    this.state
                                                                        .i18n
                                                                        .rate
                                                                }
                                                            </span>
                                                            <ul>
                                                                <li>
                                                                    <i
                                                                        className={
                                                                            item.rate >
                                                                                0
                                                                                ? "fa fa-star"
                                                                                : "fa fa-star-o"
                                                                        }
                                                                    ></i>
                                                                </li>
                                                                <li>
                                                                    <i
                                                                        className={
                                                                            item.rate >
                                                                                1
                                                                                ? "fa fa-star"
                                                                                : "fa fa-star-o"
                                                                        }
                                                                    ></i>
                                                                </li>
                                                                <li>
                                                                    <i
                                                                        className={
                                                                            item.rate >
                                                                                2
                                                                                ? "fa fa-star"
                                                                                : "fa fa-star-o"
                                                                        }
                                                                    ></i>
                                                                </li>
                                                                <li>
                                                                    <i
                                                                        className={
                                                                            item.rate >
                                                                                3
                                                                                ? "fa fa-star"
                                                                                : "fa fa-star-o"
                                                                        }
                                                                    ></i>
                                                                </li>
                                                                <li>
                                                                    <i
                                                                        className={
                                                                            item.rate >
                                                                                4
                                                                                ? "fa fa-star"
                                                                                : "fa fa-star-o"
                                                                        }
                                                                    ></i>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <p className="review-detalis-p">
                                                        {item.comment}
                                                    </p>
                                                    {/* <h6>Replay</h6> */}
                                                </div>
                                            </div>
                                        );
                                    })
                                )}
                                {this.state.user_id != 0 ? (
                                    <>
                                        <div className="review-form">
                                            <Row>
                                                {/* review and rate section  */}
                                                <Col
                                                    xs={{ span: 10, offset: 1 }}
                                                    md={10}
                                                    lg={8}
                                                    xl={6}
                                                >
                                                    <div className="choose-review-rate">
                                                        <label>
                                                            {
                                                                this.state.i18n
                                                                    .YourReview
                                                            }
                                                        </label>

                                                        {/* review rate section  */}
                                                        <div className="rate-review">
                                                            <label>
                                                                {
                                                                    this.state
                                                                        .i18n
                                                                        .rate
                                                                }
                                                            </label>
                                                            <ul>
                                                                <li>
                                                                    <i
                                                                        className={
                                                                            this
                                                                                .state
                                                                                .activeRate >=
                                                                                1
                                                                                ? "fa fa-star"
                                                                                : "fa fa-star-o"
                                                                        }
                                                                        onClick={() => {
                                                                            this.setState(
                                                                                {
                                                                                    activeRate: 1,
                                                                                }
                                                                            );
                                                                        }}
                                                                    ></i>
                                                                </li>
                                                                <li>
                                                                    <i
                                                                        onClick={() => {
                                                                            this.setState(
                                                                                {
                                                                                    activeRate: 2,
                                                                                }
                                                                            );
                                                                        }}
                                                                        className={
                                                                            this
                                                                                .state
                                                                                .activeRate >=
                                                                                2
                                                                                ? "fa fa-star"
                                                                                : "fa fa-star-o"
                                                                        }
                                                                    ></i>
                                                                </li>
                                                                <li>
                                                                    <i
                                                                        onClick={() => {
                                                                            this.setState(
                                                                                {
                                                                                    activeRate: 3,
                                                                                }
                                                                            );
                                                                        }}
                                                                        className={
                                                                            this
                                                                                .state
                                                                                .activeRate >=
                                                                                3
                                                                                ? "fa fa-star"
                                                                                : "fa fa-star-o"
                                                                        }
                                                                    ></i>
                                                                </li>
                                                                <li>
                                                                    <i
                                                                        onClick={() => {
                                                                            this.setState(
                                                                                {
                                                                                    activeRate: 4,
                                                                                }
                                                                            );
                                                                        }}
                                                                        className={
                                                                            this
                                                                                .state
                                                                                .activeRate >=
                                                                                4
                                                                                ? "fa fa-star"
                                                                                : "fa fa-star-o"
                                                                        }
                                                                    ></i>
                                                                </li>
                                                                <li>
                                                                    <i
                                                                        onClick={() => {
                                                                            this.setState(
                                                                                {
                                                                                    activeRate: 5,
                                                                                }
                                                                            );
                                                                        }}
                                                                        className={
                                                                            this
                                                                                .state
                                                                                .activeRate >=
                                                                                5
                                                                                ? "fa fa-star"
                                                                                : "fa fa-star-o"
                                                                        }
                                                                    ></i>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        {/* review rate section  */}
                                                    </div>
                                                    {/* review text area  */}
                                                    <textarea
                                                        type="text"
                                                        placeholder={
                                                            this.state.i18n
                                                                .CommentText
                                                        }
                                                        value={
                                                            this.state
                                                                .commentRate
                                                        }
                                                        onChange={(e) => {
                                                            this.setState({
                                                                commentRate:
                                                                    e.target
                                                                        .value,
                                                            });
                                                        }}
                                                    />
                                                    {/* review text area  end */}
                                                </Col>

                                                {/* review and rate section  */}

                                                {/* send button section  */}
                                                <Col
                                                    xs={{ span: 10, offset: 1 }}
                                                >
                                                    <a
                                                        onClick={() =>
                                                            this.AddRate()
                                                        }
                                                    >
                                                        {this.state.i18n.Send}
                                                    </a>
                                                </Col>
                                                {/* send button section  */}
                                            </Row>
                                            {/* // ):null} */}
                                        </div>
                                    </>
                                ) : (
                                    // ):                  <div className="desc_review">{this.state.i18n.NoCommentToShow}</div>
                                    <div className="desc_review">
                                        {" "}
                                        {this.state.i18n.pls}
                                        <a href="/login">
                                            {this.state.i18n.signin}
                                        </a>
                                        {this.state.i18n.ToSeeComments}{" "}
                                    </div>
                                )}
                            </Tab>
                            {/* review tab section  end */}
                        </Tabs>
                    </div>
                    {/* review and discription section  end */}
                </Container>
            </div>
        );
    }
    /** Convert Content to HTML */
    MyComponent(desc) {
        return <div dangerouslySetInnerHTML={this.createMarkup(desc)} />;
    }
    createMarkup(desc) {
        return { __html: desc };
    }
    /** Convert Content to HTML End*/

    // ########################### option images ###########################
    OptionImages(indexOfOption) {
        fetch(Global.api_link + "getOptionsImages", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                token: Global.token,
                option_id: this.state.productOptions[indexOfOption].pid,
            }),
        })
            .then((response) => response.json())
            .then((result) => {
                let arr = [];
                if (result.data) {
                    result.data.map((img) => {
                        arr.push({
                            thumbnail: img.path_thumb,
                            original: img.image,
                        });
                    });
                }
                this.setState(
                    {
                        OptionsImages: arr,
                        optionimagesLoader: false,
                        IsOptionSelected: true,
                        numOfProd: 1,
                        optionQty: this.state.productOptions[indexOfOption].qty,
                        optionIndex: indexOfOption,
                    },
                    () => {
                        console.log(this.state.OptionsImages);
                    }
                );
            });
    }
    // ########################### option images ###########################

    /****************** add to Cart ****************/

    AddToCart(id) {
        fetch(Global.api_link + "addToCart", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                token: Global.token,
                product_id: id,
                user_id: this.state.user_id,
                qty: this.state.qty,
            }),
        })
            .then((response) => response.json())
            .then((result) => {
                swal({
                    title: this.state.i18n.ProductCart,
                    icon: "success",
                    buttons: {
                        cancel: this.state.i18n.continueShopping,

                        catch: {
                            text: this.state.i18n.goToCart,
                            value: "catch",
                        },
                    },
                }).then((go) => {
                    if (go) {
                        window.location.href = "/cart";
                    } else {
                        window.location.reload();
                    }
                });
            })
            .catch((error) => {
                console.log(error);
            });
    }
    /****************** add to Cart end ****************/

    /****************** add to faviorite  ****************/

    AddToFavour(id) {
        fetch(Global.api_link + "AddRemoveFavorite", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                token: Global.token,
                pid: id,
                uid: this.state.user_id,
            }),
        })
            .then((response) => response.json())
            .then((result) => {
                this.ProductDetails();
                // this.setState({
                //   addToWish: !this.state.addToWish,
                // });
            });
    }
    /****************** add to faviorite  end ****************/
    /****************** add comment  ****************/

    AddRate() {
        if (
            this.state.commentRate != null &&
            this.state.commentRate != "" &&
            this.state.activeRate != 0
        ) {
            fetch(Global.api_link + "addRate", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                },
                body: JSON.stringify({
                    token: Global.token,
                    product_id: this.props.match.params.PID,
                    user_id: this.state.user_id,
                    rates: this.state.activeRate,
                    comment: this.state.commentRate,
                }),
            })
                .then((response) => response.json())
                .then((result) => {
                    swal({
                        title: this.state.i18n.ReviewAdded,
                        // text: this.state.i18n.ProductCart,
                        icon: "success",
                        button: this.state.i18n.OK,
                    }).then(() => {
                        this.setState({
                            activeRate: 0,
                            commentRate: " ",
                        });
                    });

                    this.getratesComm();
                })
                .catch((error) => {
                    console.error(error);
                });
        } else {
            swal({
                title: this.state.i18n.Required,
                icon: "warning",
                button: this.state.i18n.OK,
            });
        }
    }
    /****************** add comment end ****************/

    // copy link url
    async copyPageUrl(url) {
        try {
            await navigator.clipboard.writeText(url);
            console.log("Page URL copied to clipboard");
            // alert('lll')
        } catch (err) {
            console.error("Failed to copy: ", err);
            // alert(err)
        }
    }
    // copy link url
}
export default ProductDetails;
