import React, { Component } from "react";
import { Col, Container, Row, Carousel } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import Global from "../Global.json";
import Skeleton from "react-loading-skeleton";
import LinesEllipsis from "react-lines-ellipsis";
class Home extends Component {
  constructor() {
    super();
    this.state = {
      language: localStorage.getItem("language")
        ? localStorage.getItem("language")
        : "english",
      details: false,
      mainSliderData: [],
      mainSliderDataLoader: true,
      Adsimages: {},
      AdsimagesLoader: true,
      catWithDesc: [],
      catWithDescLoader: true,
      collectionData: [],
      collectionDataLoader: true,
    };
  }

  componentWillMount() {
    if (this.state.language !== "english") {
      this.setState({
        i18n: Global.ar,
      });
    } else {
      this.setState({
        i18n: Global.en,
      });
    }
    this.getNewSliderOffer();
    this.getAdsImages();
    this.getCategoriesWithProductDesc();
    this.getCollections();
  }

  /** get New Slider Offer Start */
  getNewSliderOffer = () => {
    fetch(Global.api_link + "getNewSliderOffer", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: Global.token,
        lang: this.state.language,
      }),
    })
      .then((response) => response.json())
      .then((result) => {
        this.setState({
          mainSliderData: result.randomOffer,
          mainSliderDataLoader: false,
        });
      });
  };
  /** get New Slider Offer  end */

  /** get Collections Start */
  getCollections = () => {
    fetch(Global.api_link + "getCollections", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: Global.token,
        lang: this.state.language,
      }),
    })
      .then((response) => response.json())
      .then((result) => {
        this.setState({
          collectionData: result.data,
          collectionDataLoader: false,
        });
      });
  };
  /** get Collections  end */

  /** get Ads Images Start */
  getAdsImages = () => {
    fetch(Global.api_link + "getAdsImages", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: Global.token,
      }),
    })
      .then((response) => response.json())
      .then((result) => {
        this.setState({
          Adsimages: result.data,
          AdsimagesLoader: false,
        });
      });
  };
  /** get Ads Images  end */

  /** get Categories With Product Desc Start */
  getCategoriesWithProductDesc = () => {
    fetch(Global.api_link + "getCategoriesWithProductDesc", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: Global.token,
        lang: this.state.language,
      }),
    })
      .then((response) => response.json())
      .then((result) => {
        this.setState({
          catWithDesc: result.data,
          catWithDescLoader: false,
        });
      });
  };
  /** get Categories With Product Desc end */

  render() {
    return (
      <div className="home">
        {/* landing image  */}
        {this.state.mainSliderDataLoader ? (
          <Skeleton duration={2} height={400} />
        ) : this.state.mainSliderData.length < 1 ? null : (
          (
            <Carousel
              indicators={false}
              controls={this.state.mainSliderData.length > 1}
            >
              {this.state.mainSliderData.map((item, index) => {
                return (
                  <Carousel.Item key={index}>
                    <img
                      src={Global.image_link + item.image}
                      alt="l"
                      onClick={() => {
                        window.location.href =
                          item.parent == 0
                            ? "/SubCategories/" + item.id
                            : "/Categories/" + item.id;
                      }}
                      className="landing-page-img"
                    />
                  </Carousel.Item>
                );
              })}
            </Carousel>
          )
        )}
        {/* landing image end */}

        {/* title section  */}
        {this.state.collectionData.length < 1 &&
          this.state.Adsimages.image == null ? null : (
          <div className="title-section">
            <Container >
              <Row style={{ justifyContent: "center" }}>
                <Col xs={3} sm={4} md={4} className="no-padd">
                  <div className="hr-section">
                    <div></div>
                  </div>
                </Col>
                <Col xs={5} sm={4} md={4} className="no-padd">
                  <h4>{this.state.i18n.CuratedCollections}</h4>
                </Col>
                <Col xs={3} sm={4} md={4} className="no-padd">
                  <div className="hr-section">
                    <div></div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        )}
        {/* title section end */}



        {/* Collections section */}
        {this.state.collectionData.length < 1 ? null : (
          <div className="collections">
            <Container>
              <Row>
                {this.state.collectionDataLoader ? (
                  <>
                    <Col xs={4} className="padd-5-mobile">
                      <Skeleton height={300} />
                    </Col>
                    <Col xs={4} className="padd-5-mobile">
                      <Skeleton height={300} />
                    </Col>
                    <Col xs={4} className="padd-5-mobile">
                      <Skeleton height={300} />
                    </Col>
                  </>
                ) : (
                  this.state.collectionData.map((item, index) => {
                    return (
                      <Col xs={4} className="padd-5-mobile">
                        <a
                          href={"/Collections/" + item.id}
                          className="collection-item"
                        >
                          <img src={Global.image_link + item.image} alt="l" />

                          <p>{item.name}</p>
                        </a>
                      </Col>
                    );
                  })
                )}
                {/* <Col xs={4} className="padd-5-mobile">
                <a href="#" className="collection-item">
                  <img src="/img/TDWy4cMxuc.png" alt="l" />

                  <p>Soap Collections</p>
                </a>
              </Col>
              <Col xs={4} className="padd-5-mobile">
                <a href="#" className="collection-item">
                  <img src="/img/LmBxOpmcrr.png" alt="l" />

                  <p>Straw Collections</p>
                </a>
              </Col>
              <Col xs={4} className="padd-5-mobile">
                <a href="#" className="collection-item">
                  <img
                    src="/img/HM_HP_CatTile_HomeDecor_Desktop._CB1198675309_ copy.png"
                    alt="l"
                  />

                  <p>HOME DECOR</p>
                </a>
              </Col> */}
              </Row>
            </Container>
          </div>
        )}

        {/* best gift section  */}
        {this.state.AdsimagesLoader ? (
          <Skeleton height={300} />
        ) : (
          <div className="landing-page"> 
              <img
                src={Global.image_link + this.state.Adsimages.image}
                alt="l"
              onClick={() => {
                window.location.href = this.state.Adsimages.link;
              }} 
              />
           
          </div>
        )}
        {/* best gift section end */}

        {/* Collections section end  */}

        {/* title section  */}
        <div className="title-section">
          <Container>
            <Row style={{ justifyContent: "center" }}>
              <Col xs={3} sm={4} md={4} className="no-padd">
                <div className="hr-section">
                  <div></div>
                </div>
              </Col>
              <Col xs={5} sm={4} md={4} className="no-padd">
                <h4>{this.state.i18n.ShopByCategory}</h4>
              </Col>
              <Col xs={3} sm={4} md={4} className="no-padd">
                <div className="hr-section">
                  <div></div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        {/* title section end */}

        {/* shop by category section   */}

        <div className="ShopBYCategory">
          <Container>
            <Row>
              {this.state.catWithDescLoader ? (
                <>
                  <Col xs={6} sm={6} md={4}>
                    <Skeleton height={250} />
                  </Col>
                  <Col xs={6} sm={6} md={4}>
                    <Skeleton height={250} />
                  </Col>
                  <Col xs={6} sm={6} md={4}>
                    <Skeleton height={250} />
                  </Col>
                  <Col xs={6} sm={6} md={4}>
                    <Skeleton height={250} />
                  </Col>
                </>
              ) : (
                this.state.catWithDesc.map((item, index) => {
                  return (
                    <Col xs={6} sm={6} md={4} key={index}>
                      <a
                        href={
                          item.childs > 0
                            ? "/SubCategories/" + item.id
                            : "/Categories/" + item.id
                        }
                        className="shop-category"
                        style={{
                          background: `url(${item.icon != null
                            ? Global.image_link + item.icon
                            : "/img/New2.png"
                            })`,
                        }}
                      >
                        {/* <img src={item.icon != null ? Global.image_link + item.icon : "/img/BUWDQEwJGI.png"} alt="l" /> */}
                        <div className="shopCategotyTitle">
                          <p>{item.name}</p>
                        </div>
                        <div className="shop-category-details showInhover">
                          <h5>{item.name}</h5>
                          <span>{item.desc}</span>
                          <p>{item.count + " " + this.state.i18n.Iteams}</p>
                        </div>
                      </a>
                    </Col>
                  );
                })
              )}
            </Row>
          </Container>
        </div>
        {/* shop by category section end  */}

        {/* join us  */}
        <div className="joinUs">
          <div className="joinUs-bg-shadow">
            <h4>{this.state.i18n.AreYouMakers}</h4>
            <p>{this.state.i18n.joinUsInSooqJo}</p>
            <a href="https://join.souqjo.net/Home/Join">{this.state.i18n.JoinNow}</a>
          </div>
        </div>

        {/* join us  */}

        {/* social media  follow section  */}
        <div className="socialmedia-follow">
          <Container>
            <Row>
              <Col xs={4} className="no-padd-under767">
                <div className="follow_item">
                  <i
                    className="fa fa-facebook-f"
                    onClick={() => {
                      window.location.href =
                        "https://www.facebook.com/souqjo.net/?__tn__=%2Cd%2CP-R&eid=ARD6h8fG48LgPpq-Mq9SamvnN5pfCTvPfo4WjmqyLljIY8ZIEgVXvvrZtyBb5GwZJ3Hmp3ysIzC0IRLa";
                    }}
                  ></i>
                  <div>
                    <span>
                      {" "}
                      {this.state.i18n.followUs} {this.state.i18n.facebook}{" "}
                    </span>
                    <span>@Souqjo</span>
                  </div>
                </div>
              </Col>
              <Col xs={4} className="no-padd-under767">
                <div className="follow_item">
                  <i
                    className="fa fa-twitter"
                    onClick={() => {
                      window.location.href = "https://twitter.com/Souqjo_net";
                    }}
                  ></i>
                  <div>
                    <span>
                      {this.state.i18n.followUs} {this.state.i18n.twitter}{" "}
                    </span>
                    <span>@Souqjo</span>
                  </div>
                </div>
              </Col>
              <Col xs={4} className="no-padd-under767">
                <div className="follow_item">
                  <i
                    className="fa fa-instagram"
                    onClick={() => {
                      window.location.href =
                        "https://www.instagram.com/souqjonet/";
                    }}
                  ></i>
                  <div>
                    <span>
                      {this.state.i18n.followUs} {this.state.i18n.instagram}
                    </span>
                    <span>@Souqjo</span>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        {/* social media  follow section end */}
      </div>
    );
  }
}

export default Home;
