import React, { Component } from "react";
import {
    Button,
    Col,
    Container,
    Dropdown,
    DropdownButton,
    Form,
    FormControl,
    InputGroup,
    Row,
    ThemeProvider,
} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import MultilevelSidebar from "react-multilevel-sidebar";
import "react-multilevel-sidebar/src/Sidebar.css";
import Img from "react-cool-img";
import Global from "../Global.json";

import * as firebase from "firebase";
import Skeleton from "react-loading-skeleton";

const   Currencies1= {
    EUR: 1.25326,
    USD: 1.41638,
    KWD: 0.4274,
    JOD: 1,
    CHF: 1.30554,
    SAR: 5.30525,
    AED: 5.20574,
}
class Header extends Component {
    constructor() {
        super();
        this.state = {
            user_id: localStorage.getItem("user_id") ? localStorage.getItem("user_id") : 0,
            language: localStorage.getItem("language")
                ? localStorage.getItem("language")
                : "english",
            isOpen: false,
            userInfo: [],
            cartNum: 0,
            keyVal: "",
            Categories: [],
            CategoriesLoder: false,
            options: [],
            SelectedCatID: 0,
            mainCategoryData: [],
            mainCategoryDataLoader: true,
            key: "",
            Currency:localStorage.getItem("Currency") ?localStorage.getItem("Currency") : "JOD" ,
            
            Currencies: {
                  EUR: 1.25326,
                  USD: 1.41638,
                  KWD: 0.4274,
                  JOD: 1,
                  CHF: 1.30554,
                  SAR: 5.30525
              },
              CurrentCurrency:Currencies1[localStorage.getItem("Currency")] ? Currencies1[localStorage.getItem("Currency")] : "JOD"
        };
    }
    // Currency function
    
  async  compute(){

   const options = {method: 'GET', headers: {Accept: 'application/json'}};

//    await fetch('https://api.fastforex.io/fetch-multi?from=jod&to=eur%2Cusd%2Csar%2Caed%2Ckwd%2Ceur%2Cjod&api_key=69d5b8a000-b79682f9b4-r2wwj7', options)
//      .then(response => response.json())
//      .then(response =>  {this.setState({
//          Currencies:response.results,
//         CurrentCurrency:response.results[`${localStorage.getItem('Currency')}`] })}       
//      )
//      .catch(err => console.error(err));


     localStorage.setItem("Currencies", JSON.stringify(this.state.Currencies));
     localStorage.setItem("CurrentCurrency",this.state.CurrentCurrency);

    }
 async   changeCurrency(){

    this.setState({
        CurrentCurrency: this.state.Currencies[localStorage.getItem("Currency")]
    })

     await   localStorage.setItem("Currency",this.state.Currency);
    //  await localStorage.setItem("CurrentCurrency",this.state.CurrentCurrency);




        window.location.reload();
    }

    /////// from old Header //////
    Change() {
        localStorage.removeItem("language");
        localStorage.setItem("language", "arabic");
        window.location.reload();
    }

    ChangeEn() {
        localStorage.removeItem("language");
        localStorage.setItem("language", "english");
        window.location.reload();
    }

    componentWillMount() {
       
        // setst{
        //     CurrentCurrency:khkh[`${localStorage.getItem('Currency')}`] })}       
        
        
        // }

        this.setState({
            // CurrentCurrency: this.state.Currencies["JOD"]
        })
        localStorage.setItem("CurrentCurrency",this.state.Currencies[localStorage.getItem("Currency")]);

        if (this.state.language !== "english") {
            this.setState({
                i18n: Global.ar,
            });
        } else {
            this.setState({
                i18n: Global.en,
            });
        }

        this.getCategories();
        this.compute()
        this.getUserInfo();
        this.geUserCartCount();
        this.getMainCategory();
        this.getCheckuserBlocked();
        if (window.location.pathname != "/Payment") {
            this.getCkeckoutAmounts2();
        }
    }

    /** Get User Info Start */
    getUserInfo = () => {
        fetch(Global.api_link + "getUser", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                token: Global.token,
                user_id: this.state.user_id,
            }),
        })
            .then((response) => response.json())
            .then((result) => {
                this.setState({
                    userInfo: result.data,
                });
            });
    };
    /** Get User Info  end */

    /** Check user is blocked Start */
    getCheckuserBlocked = () => {
        fetch(Global.api_link + "CheckuserBlocked", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                token: Global.token,
                user_id: this.state.user_id,
            }),
        })
            .then((response) => response.json())
            .then((result) => {
                if (result.data != 1) {
                    localStorage.removeItem("user_id");
                    localStorage.setItem("user_id", 0);
                    this.setState({
                        cartNum: 0,
                    });
                }
            });
    };
    /** Check user is blocked  end */

    /** get Main Category Start */
    getMainCategory = () => {
        fetch(Global.api_link + "getMainCategories", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                token: Global.token,
                lang: this.state.language,
            }),
        })
            .then((response) => response.json())
            .then((result) => { 
                this.setState({
                    mainCategoryData: result.data,
                    mainCategoryDataLoader: false,
                });
            });
    };
    /** get Main Category  end */

    /** get Cart Count */
    geUserCartCount = () => {
        fetch(Global.api_link + "getUserCartProducts", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                token: Global.token,
                lang: this.state.language,
                user_id: this.state.user_id,
            }),
        })
            .then((response) => response.json())
            .then((result) => {
                this.setState({
                    cartNum: result.data.length,
                    isPageLoad: false,
                });
            });
    };
    /** get Cart Count */
    getCkeckoutAmounts2() {
        fetch(Global.mobile_epi_link + "getUserCheckoutamounts", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                token: Global.mobile_token,
                user_id: this.state.user_id,
            }),
        })
            .then((response) => response.json())
            .then(async (res) => {
                this.setState(
                    {
                        products_ids: res.products_ids,
                    },
                    () => {
                        this.cancelPaymentProcess2();
                    }
                );
            })
            .catch((error) => {
                this.getCkeckoutAmounts2();
            });
    }

    cancelPaymentProcess2() {
        let ID_s = this.state.products_ids;

        if (ID_s && !this.state.showAlert) {
            if (ID_s.length > 0) {
                ID_s.map((item, index) => {
                    let id = item.product_option_id;
                    let all_qty = item.all_qty;
                    let user_qty = item.user_qty;
                    let cart_id = item.cart_id;
                    firebase
                        .database()
                        .ref("products/" + id)
                        .off("value");

                    let thisTask2 = firebase.database().ref("products/" + id);

                    thisTask2.once("value", (snapshot2) => {
                        setTimeout(async () => {
                            if (snapshot2.exists()) {
                                const valuesR = snapshot2.val();

                                let waityArray = [];
                                waityArray = valuesR.users_queue.split(",");
                                if (parseInt(valuesR.users_queue.length) == 0) {
                                    waityArray = [];
                                }

                                let inPurchaseArray = [];
                                inPurchaseArray =
                                    valuesR.current_pay_user.split(",");
                                if (
                                    parseInt(valuesR.current_pay_user.length) ==
                                    0
                                ) {
                                    inPurchaseArray = [];
                                }

                                if (
                                    inPurchaseArray.indexOf(
                                        this.state.user_id
                                    ) != -1
                                ) {
                                    inPurchaseArray.splice(
                                        inPurchaseArray.indexOf(
                                            this.state.user_id
                                        ),
                                        1
                                    );
                                    let inPurchaseStr =
                                        inPurchaseArray.join(",");

                                    firebase
                                        .database()
                                        .ref("products/" + id)
                                        .update({
                                            current_pay_user: inPurchaseStr,
                                            users_qty:
                                                parseInt(valuesR.users_qty) -
                                                parseInt(user_qty),
                                        });
                                }

                                if (
                                    waityArray.indexOf(this.state.user_id) != -1
                                ) {
                                    waityArray.splice(
                                        waityArray.indexOf(this.state.user_id),
                                        1
                                    );
                                    let waityArrayStr = waityArray.join(",");

                                    firebase
                                        .database()
                                        .ref("products/" + id)
                                        .update({
                                            users_queue: waityArrayStr,
                                        });
                                }
                            }
                        }, 10);
                    });

                    /*
            if (index == ID_s.length - 1) {
            }*/
                });
            }
        }
    }

    /////// from old Header end //////

    /// handle toggle
    handleSidebarToggle = (isOpen) => {
        this.setState({ isOpen });
    };

    all = {
        content: [
            {
                id: 0,
                name: window.localStorage.language === "english" ? "All" : "الكل",
                parent: 0,
                to: "/Categories/__All",
            },
        ],
    };
    render() {
        return (
            <div className="Header">
                <Container fluid>
                    <Row className="padd-bot">
                        {/* logo section  */}
                        <Col xs={2} sm={2} md={2} className="logo-col">
                            <div className="logo">
                                <img
                                    src="/img/Group 191.png"
                                    alt="l"
                                    onClick={() => {
                                        window.location.href = "/";
                                    }}
                                />
                            </div>
                        </Col>
                        {/* logo section end */}

                        {/* search section  */}
                        <Col
                            xs={8}
                            sm={12}
                            md={4}
                            lg={6}
                            className="search-web d-flex justify-content-start align-items-center"
                        >
                            <div className="search-div">
                                <InputGroup className="mb-3">
                                    <DropdownButton
                                        as={InputGroup.Prepend}
                                        variant="outline-secondary"
                                        title={
                                            this.state.SelectedCat
                                                ? this.state.SelectedCat
                                                : this.state.i18n.AllCategories
                                        }
                                        id="input-group-dropdown-1"
                                    >
                                        {this.state.Categories.map(
                                            (item, index) => {
                                                return (
                                                    <Dropdown.Item
                                                        onClick={() => {
                                                            this.setState({
                                                                SelectedCat:
                                                                    item
                                                                        .content[0]
                                                                        .name,
                                                                SelectedCatID:
                                                                    item
                                                                        .content[0]
                                                                        .id,
                                                            });
                                                        }}
                                                        key={index}
                                                    >
                                                        {item.content[0].name}
                                                    </Dropdown.Item>
                                                );
                                            }
                                        )}
                                    </DropdownButton>

                                    <FormControl
                                        aria-describedby="basic-addon1"
                                        placeholder={this.state.i18n.SearchText}
                                        onChange={(key) => {
                                            this.setState({
                                                keyVal: key.target.value,
                                            });
                                        }}
                                        onKeyPress={(e) => {
                                            if (e.key === "Enter") {
                                                if (this.state.keyVal === "") {
                                                } else {
                                                    this.Search(
                                                        this.state.keyVal
                                                    );
                                                }
                                            }
                                        }}
                                    />
                                    <InputGroup.Append>
                                        <Button
                                            variant="outline-secondary"
                                            onClick={() => {
                                                if (this.state.keyVal == "") {
                                                } else {
                                                    this.Search(
                                                        this.state.keyVal
                                                    );
                                                }
                                            }}
                                        >
                                            <img
                                                src="/img/search.png"
                                                alt="1"
                                            />
                                        </Button>
                                    </InputGroup.Append>
                                </InputGroup>
                            </div>
                        </Col>

                        {/* search section end  */}

                        {/* language section  */}
                        <Col
                            xs={9}
                            sm={10}
                            md={6}
                            lg={4}
                            className=" cartLangAcc no-padd-under567"
                        >
                            {/* Currency Section Start */}
                            {/* <div className="language-dropdown">
                             <p className="m-0 currency">
                                {this.state.i18n.JOD}
                             </p>
                            </div> */}
                            <div className="language-dropdown">
                <Dropdown >
                  <Dropdown.Toggle id="dropdown-basic">
                  {this.state.i18n[this.state.Currency]}
                  </Dropdown.Toggle>
                  <Dropdown.Menu  className="Dropdown-Menu-Currency">
                    <Dropdown.Item onClick={()=> this.setState({Currency:"JOD"},()=>this.changeCurrency())} >
                    {this.state.i18n.JOD}                    </Dropdown.Item>
                    <Dropdown.Item onClick={()=> this.setState({Currency:"USD"},()=>this.changeCurrency())} >
                    {this.state.i18n.USD}
                    </Dropdown.Item>
                    <Dropdown.Item onClick={()=> this.setState({Currency:"SAR"},()=>this.changeCurrency())} >
                    {this.state.i18n.SAR}
                    </Dropdown.Item>
                    <Dropdown.Item onClick={()=> this.setState({Currency:"KWD"},()=>this.changeCurrency())} >
                    {this.state.i18n.KWD}
                    </Dropdown.Item>
                    <Dropdown.Item onClick={()=> this.setState({Currency:"AED"},()=>this.changeCurrency())} >
                    {this.state.i18n.AED}
                    </Dropdown.Item>
                    <Dropdown.Item onClick={()=> this.setState({Currency:"EUR"},()=>this.changeCurrency())} >
                    {this.state.i18n.EUR}
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>

                            {/* Currency Section End */}
                            <div className="language-dropdown">
                                <Dropdown>
                                    <Dropdown.Toggle id="dropdown-basic">
                                        {/* <div className='languages'> */}
                                        <img
                                            src="/img/translator.svg"
                                            alt="l"
                                        />
                                        {this.state.language == "english" ? (
                                            <div className="language-name">
                                                <span>English</span>
                                                <span>الانجليزية</span>
                                            </div>
                                        ) : (
                                            <div className="language-name">
                                                <span>Arabic</span>
                                                <span>العربيــة</span>
                                            </div>
                                        )}
                                        {/* </div> */}
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <Dropdown.Item
                                            onClick={() => this.Change()}
                                        >
                                            العربيــة
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                            onClick={() => this.ChangeEn()}
                                        >
                                            English
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                            {/* language section end */}
                            {/* user section  */}

                            <div className="user-header">
                                <img src="/img/user.svg" alt="a" />
                                <div className="user-signin">
                                    {this.state.user_id == 0 ? (
                                        <p>
                                            <a href="/Login">
                                                {this.state.i18n.Login}
                                            </a>{" "}
                                            /{" "}
                                            <a href="/Signup">
                                                {this.state.i18n.Join}
                                            </a>{" "}
                                        </p>
                                    ) : (
                                        <p className="nameUser">
                                            {this.state.userInfo.name}
                                        </p>
                                    )}
                                    {/* <h6>Account & Lists</h6> */}
                                    {this.state.user_id == 0 ? null : (
                                        <Dropdown>
                                            <Dropdown.Toggle id="dropdown-basic">
                                                <h6>
                                                    {this.state.i18n.MYACCOUNT}
                                                </h6>
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                <Dropdown.Item href="/Profile#info">
                                                    {this.state.i18n.Profile}
                                                </Dropdown.Item>
                                                <Dropdown.Item href="/Orders">
                                                    {this.state.i18n.MyOrders}
                                                </Dropdown.Item>
                                                <Dropdown.Item href="/Favourites">
                                                    {this.state.i18n.WISHLIST}
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                    onClick={() =>
                                                        this.Logout()
                                                    }
                                                >
                                                    {this.state.i18n.Logout}
                                                </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    )}
                                </div>
                            </div>
                            {/* user section end */}
                            {/* cart section  */}

                            <div
                                className="header-cart"
                                onClick={() => (window.location.href = "/cart")}
                            >
                                <div className="cartIcon">
                                    <img
                                        src="/img/shopping-cart.svg"
                                        alt="l"
                                        className="cartIcon-img"
                                    />
                                    {this.state.cartNum ? (
                                        <span className="Counter">
                                            {" "}
                                            {parseInt(this.state.cartNum)}
                                        </span>
                                    ) : null}
                                </div>

                                {/* <p>{this.state.i18n.cart}</p> */}
                            </div>
                        </Col>
                        {/* cart section end */}
                    </Row>
                    {/* MOBILE SEARCH */}
                    {/* <Row className="Row-search-mobile">
            <Col xs={12} className="search-mobile">
              <div className="search-div">
                <InputGroup className="mb-3">
                  <DropdownButton
                    as={InputGroup.Prepend}
                    variant="outline-secondary"
                    title={
                      this.state.SelectedCat
                        ? this.state.SelectedCat
                        : this.state.i18n.AllCategories
                    }
                    id="input-group-dropdown-1"
                  >
                    {this.state.Categories.map((item, index) => {
                      return (
                        <Dropdown.Item
                          onClick={() => {
                            this.setState({
                              // CategorieSelected : item.content[0].name,
                              SelectedCat: item.content[0].name,
                              SelectedCatID: item.content[0].id,
                            });
                          }}
                          key={index}
                        >
                          {item.content[0].name}
                        </Dropdown.Item>
                      );
                    })}
                  </DropdownButton>
                  <FormControl
                    aria-describedby="basic-addon1"
                    placeholder={this.state.i18n.SearchText}
                    onChange={(key) => {
                      this.setState({ keyVal: key.target.value });
                    }}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                          if (this.state.keyVal === "") {
                          } else {
                              this.Search(this.state.keyVal);
                          }
                      }
                  }}
                  />{" "}
                  <InputGroup.Append>
                    <Button
                      variant="outline-secondary"
                      onClick={() => {
                        if (this.state.keyVal == "") {
                        } else {
                          this.Search(this.state.keyVal);
                        }
                      }}
                    >
                      <img src="/img/search.png" alt="1" />
                    </Button>
                  </InputGroup.Append>
                </InputGroup>
              </div>
            </Col>

          </Row> */}
                </Container>

                {/* bottom header  */}
                <div className="bottom-header">
                    <Container fluid>
                        <Row>
                            <Col md={12} >
                                <div className="bottom-header-content">
                                    {/* toggle */}
                                    <div className="toggle-header">
                                        <MultilevelSidebar
                                            open={this.state.isOpen}
                                            onToggle={this.handleSidebarToggle}
                                            options={this.state.options}
                                            // onItemClick={()=>window.location.href='/'}

                                            header={
                                                <div className="logo">
                                                    <img
                                                        src="/img/Group1.png"
                                                        alt="l"
                                                        onClick={() => {
                                                            window.location.href =
                                                                "/";
                                                        }}
                                                    />
                                                </div>
                                            }
                                            // onItemClick={this.handleClick}
                                        />

                                        {/* button toggle end */}
                                        <button
                                            onClick={() =>
                                                this.handleSidebarToggle(true)
                                            }
                                        >
                                            <img src="/img/menu.png" alt="l" />{" "}
                                        </button>
                                        <span
                                            onClick={() =>
                                                this.handleSidebarToggle(true)
                                            }
                                            className="toggle-span"
                                        >
                                            {/* All */}
                                            {this.state.i18n.All}
                                        </span>
                                        {/* button toggle end*/}
                                    </div>
                                    {/* toggle */}

                                    {/* list  */}
                                    <ul>
                                        {/* <li>
                      {" "}
                      <a href="/TodayDeals">{this.state.i18n.todayDeals}</a>
                    </li> */}
                                        {/* <li>
                      {" "}
                      <a href="#">Customer Service</a>
                    </li>
                    <li>
                      {" "}
                      <a href="#">Gift Cards</a>
                    </li>
                    <li>
                      {" "}
                      <a href="#">Registry</a>
                    </li>
                    <li>
                      {" "}
                      <a href="#">Sell</a>
                    </li> */}
                                    </ul>
                                    {/* list end */}

                                    <div className="Row-search-mobile">
                                        <div className="search-mobile">
                                            <div className="search-div">
                                                <InputGroup className="mb-3">
                                                    <DropdownButton
                                                        as={InputGroup.Prepend}
                                                        variant="outline-secondary"
                                                        title={
                                                            this.state
                                                                .SelectedCat
                                                                ? this.state
                                                                      .SelectedCat
                                                                : this.state
                                                                      .i18n
                                                                      .AllCategories
                                                        }
                                                        id="input-group-dropdown-1"
                                                    >
                                                        {this.state.Categories.map(
                                                            (item, index) => {
                                                                return (
                                                                    <Dropdown.Item
                                                                        onClick={() => {
                                                                            this.setState(
                                                                                {
                                                                                    // CategorieSelected : item.content[0].name,
                                                                                    SelectedCat:
                                                                                        item
                                                                                            .content[0]
                                                                                            .name,
                                                                                    SelectedCatID:
                                                                                        item
                                                                                            .content[0]
                                                                                            .id,
                                                                                }
                                                                            );
                                                                        }}
                                                                        key={
                                                                            index
                                                                        }
                                                                    >
                                                                        {
                                                                            item
                                                                                .content[0]
                                                                                .name
                                                                        }
                                                                    </Dropdown.Item>
                                                                );
                                                            }
                                                        )}
                                                    </DropdownButton>
                                                    <FormControl
                                                        aria-describedby="basic-addon1"
                                                        placeholder={
                                                            this.state.i18n
                                                                .SearchText
                                                        }
                                                        onChange={(key) => {
                                                            this.setState({
                                                                keyVal: key
                                                                    .target
                                                                    .value,
                                                            });
                                                        }}
                                                        onKeyPress={(e) => {
                                                            if (
                                                                e.key ===
                                                                "Enter"
                                                            ) {
                                                                if (
                                                                    this.state
                                                                        .keyVal ===
                                                                    ""
                                                                ) {
                                                                } else {
                                                                    this.Search(
                                                                        this
                                                                            .state
                                                                            .keyVal
                                                                    );
                                                                }
                                                            }
                                                        }}
                                                    />{" "}
                                                    <InputGroup.Append>
                                                        <Button
                                                            variant="outline-secondary"
                                                            onClick={() => {
                                                                if (
                                                                    this.state
                                                                        .keyVal ==
                                                                    ""
                                                                ) {
                                                                } else {
                                                                    this.Search(
                                                                        this
                                                                            .state
                                                                            .keyVal
                                                                    );
                                                                }
                                                            }}
                                                        >
                                                            <img
                                                                src="/img/search.png"
                                                                alt="1"
                                                            />
                                                        </Button>
                                                    </InputGroup.Append>
                                                </InputGroup>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                {/* bottom header  end */}
                {/* bottom header  */}
                {/*} <div className="bottom-header-handmade">
          <Container fluid>
            <Row className="justify-content-space-between">
              <Col xs={10} md={10} lg={10}>
                <div className="bottom-header-content-handmade">
                  {/* toggle */}
                {/* {this.state.mainCategoryDataLoader ? (
                    <Skeleton duration={2} height={40} width={300} />
                  ) : ( */}
                <>
                    {/*}        <div className="toggle-header">
                      <h5>{this.state.i18n.HANDMADE}</h5>
                    </div>
                    {/* toggle */}

                    {/* list  */}

                    {/* <ul>
                        {this.state.mainCategoryData.map((item, index) => {
                          return (
                            <li key={index}>
                              <a href={"/Categories/" + item.id}>{item.name}</a>
                            </li>
                          );
                        })}
                      </ul> */}
                </>
                {/* )} 
                  {/* list end 
                </div>
              </Col>
              <Col xs={2} lg={2}>
                <span
                  onClick={() => {
                    window.location.href = "https://my.souqjo.net/seller";
                  }}
                >
                  {this.state.i18n.SellOnHandmade}
                </span>
              </Col>
            </Row>
          </Container>
        </div>*/}
                {/* bottom header  end */}
            </div>
        );
    }

    /*** Functions ***/
    Logout() {
        localStorage.removeItem("user_id");
        localStorage.setItem("user_id", 0);
        this.setState({
            cartNum: 0,
        });
        window.location.href = "/";
    }

    /** Home Page Categories */
    getCategories() {
        fetch(Global.api_link + "getCategoriesForWeb", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                token: Global.token,
                lang: this.state.language,
            }),
        })
            .then((response) => response.json())
            .then((result) => {
                this.setState({
                    options: [this.all, ...result.data],
                    Categories: result.data,
                    CategoriesLoder: false,
                });
            });
    }
    /** Home Page Categories End */

    Search(key) {
        if (key.includes("%")) {
            window.location.href =
                "/Search/" +
                key.replace("%", "%25") +
                "/" +
                this.state.SelectedCatID;
        } else {
            window.location.href =
                "/Search/" + key + "/" + this.state.SelectedCatID;
        }
    }
}

export default Header;
