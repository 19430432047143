import React, { Component } from "react";
import Img from "react-cool-img";
import Global from "../Global.json";
import Block from "./ProductThumb";
import Block2 from "./ProductBlock2";
import InputRange from "react-input-range";
import "react-input-range/lib/css/index.css";
import Skeleton from "react-loading-skeleton";
import swal from "sweetalert";

export default class CategoriesOld extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user_id: localStorage.getItem("user_id"),
      language: localStorage.getItem("language")
        ? localStorage.getItem("language")
        : "english",
      value: { min: 0, max: 0 },
      Block2: false,
      CatID: props.match.params.CatID,
      SortID: props.match.params.SortID,
      isProductDone: true,
      CategoryDone: true,
      Categories: [],
      MaxValue: 0,
      MinValue: 0,
      HasChild: false,
      isPageLoadPrice: true,
      scrollDataFlag: false,
      offset: 0,
      SortPrice: 0,
      no_more_data: false,
      activePage: 1,
      Options: [],
    };
    this.handleScroll = this.handleScroll.bind(this);
  }

  Change() {
    localStorage.removeItem("language");
    localStorage.setItem("language", "arabic");
    window.location.reload();
  }

  ChangeEn() {
    localStorage.removeItem("language");
    localStorage.setItem("language", "english");
    window.location.reload();
  }

  componentWillMount() {
    if (this.state.language !== "english") {
      this.setState({
        i18n: Global.ar,
      });
    } else {
      this.setState({
        i18n: Global.en,
      });
    }
    this.getProducts();
    this.getCategories();
    this.getSingleCategoryPricesFilter();
  }

  componentDidMount() {
    //window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    //window.removeEventListener("scroll", this.handleScroll);
  }

  render() {
    return (
      <div>
        {" "}
        {/** Wrapper Div  Start */}
        <div>
          {" "}
          {/** Stores Section Start */}
          <div className="Stores">
            {/** Stores Div Start  */}
            <div className="container">
              <div className="row">
                <div className="col-lg-12  col-md-12 col-sm-12 col-xs-12 no_pad">
                  <div className="col-lg-12  col-md-12 col-sm-12 col-xs-12 FilterBar">
                    <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12 no_pad hidden-xs">
                      <ul className="InLineList">
                        <li>
                          <a onClick={() => this.setState({ Block2: false })}>
                            <img src="/img/Icon_apps.png" />
                          </a>
                        </li>
                        <li>
                          <a onClick={() => this.setState({ Block2: true })}>
                            <img src="/img/Icon_menu_point.png" />
                          </a>
                        </li>
                      </ul>
                    </div>

                    <div className="col-lg-9 col-md-9 col-sm-9 col-xs-12 FilterTags no_pad">
                      <ul className="InLineList">
                        <li>
                          <a
                            href={"/Categories/" + this.state.CatID + "/" + 1}
                            className={
                              this.state.SortID == 1
                                ? "FilterType active"
                                : "FilterType"
                            }
                          >
                            {this.state.i18n.Newest}
                          </a>
                        </li>
                        <li>
                          <a
                            href={"/Categories/" + this.state.CatID + "/" + 2}
                            className={
                              this.state.SortID == 2
                                ? "FilterType  active"
                                : "FilterType"
                            }
                          >
                            {this.state.i18n.Popular}
                          </a>
                        </li>
                        {/*<li><a href={"/Categories/" + this.state.CatID + "/" + 3}  className={this.state.SortID == 3 ? "FilterType  active" : "FilterType"}>{this.state.i18n.Offers}</a></li>*/}
                        <li>
                          <div className="dropdown">
                            <button
                              className="btn btn-primary dropdown-toggle AccountButton"
                              type="button"
                              data-toggle="dropdown"
                            >
                              {this.state.SortPrice == 0 ?this.state.i18n.FilterByPrice : this.state.SortPrice == 1 ? this.state.i18n.Descending :this.state.i18n.Ascending }
                              <span>
                                <i
                                  className="fa fa-angle-down"
                                  aria-hidden="true"
                                ></i>
                              </span>{" "}
                            </button>
                            <ul className="dropdown-menu">
                              <li>
                                <a
                                  onClick={() =>
                                    this.setState(
                                      { SortPrice: 1, isProductDone: true },
                                      () => this.getProducts()
                                    )
                                  }
                                >
                                  {this.state.i18n.Descending}
                                </a>
                              </li>
                              <li>
                                <a
                                  onClick={() =>
                                    this.setState(
                                      { SortPrice: 2, isProductDone: true },
                                      () => this.getProductsAscending()
                                    )
                                  }
                                >
                                  {this.state.i18n.Ascending}
                                </a>
                              </li>
                            </ul>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="container">
              <div className="row">
                <div className="col-lg-12  col-md-12 col-sm-12 col-xs-12 no_pad">
                  {" "}
                  {/**  Stores Products Start*/}
                  <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12 no_pad  HomeCat">
                    {" "}
                    {/** Categories Start  */}
                    {this.state.CategoryDone == true ? (
                      <>
                        <Skeleton duration={2} height={40} />
                        <Skeleton duration={2} height={40} count={8} />
                      </>
                    ) : (
                        <div className="StoreCategories">
                        <>
                          <h1>{this.state.i18n.Categories}</h1>
                          <ul>
                            {this.state.Categories.map((Cat) => {
                              return (
                                <>
                                  <li key={Cat.id}>
                                    {" "}
                                    <a onClick={() => this.LinkHandel(Cat)}>
                                      {Cat.childs.length > 0 ? (
                                        <i
                                          className={this.state.selectCats != Cat ? "fa fa-plus" : "fa fa-minus"}
                                          aria-hidden="true"
                                        ></i>
                                      ) : null}{" "}
                                      {Cat.name}{" "}
                                      {Cat.childs.length > 0 ? null : (
                                        <span>{Cat.productsCount}</span>
                                      )}
                                    </a>{" "}
                                  </li>
                                  {this.state.selectCats == Cat ? (
                                    <ol className={"ChildsList"}>
                                      {Cat.childs.map((child) => {
                                        if (
                                          child.parent == Cat.id 
                                        ) {
                                          return (
                                            <li key={child.id}>
                                              <a
                                                href={
                                                  "/Categories/" + child.id + "/1"
                                                }
                                              >
                                                {child.name}{" "}
                                                <span>{child.childCount}</span>
                                              </a>
                                            </li>
                                          );
                                        }
                                      })}
                                    </ol>
                                  ) : null}
                                </>
                              );
                            })}
                          </ul>
                        </>
                      </div>
                    )}
                    {this.state.isPageLoadPrice ? (
                      <>
                        <Skeleton duration={2} height={40} />
                        <Skeleton duration={2} height={30} />
                        <br /> <br /> <br />
                      </>
                    ) : (
                      <div className="FiltersProducts">
                        {" "}
                        {/** Filters Statr  */}
                        <h3>{this.state.i18n.Filters}</h3>
                        <div className="FilterItem">
                          <>
                            <h4>{this.state.i18n.Price}</h4>
                            <div className="PriceSlider">
                              <InputRange
                                maxValue={this.state.maxVal}
                                minValue={this.state.minVal}
                                formatLabel={(value) => `${value}JOD`}
                                value={this.state.value}
                                onChange={(value) => {
                                  this.setState({
                                    value: value,
                                    scrollDataFlag: true,
                                    activePage: 1,
                                    Products: [],
                                  });
                                }}
                                onChangeComplete={() => this.getProducts()}
                              />
                            </div>
                          </>
                        </div>
                        {/**  
                                            <div className="FilterItem">
                                                <h4>{this.state.i18n.Colors} </h4>
                                                <div className="ColorWrapper">
                                                    <label className="containerColor">
                                                        <input type="checkbox" checked="checked" />
                                                        <span className="checkmark" style={{ background: "#fff" }}></span>
                                                    </label>

                                                    <label className="containerColor">
                                                        <input type="checkbox" />
                                                        <span className="checkmark" style={{ background: "#FFBBB3" }}></span>
                                                    </label>

                                                    <label className="containerColor">
                                                        <input type="checkbox" />
                                                        <span className="checkmark" style={{ background: "#47C2E2" }}></span>
                                                    </label>

                                                    <label className="containerColor">
                                                        <input type="checkbox" />
                                                        <span className="checkmark" style={{ background: "#FBB371" }}></span>
                                                    </label>

                                                    <label className="containerColor">
                                                        <input type="checkbox" />
                                                        <span className="checkmark" style={{ background: "#99D92A" }}></span>
                                                    </label>

                                                    <label className="containerColor">
                                                        <input type="checkbox" />
                                                        <span className="checkmark" style={{ background: "#97AED0" }}></span>
                                                    </label>


                                                    <label className="containerColor">
                                                        <input type="checkbox" />
                                                        <span className="checkmark" style={{ background: "#EB7092" }}></span>
                                                    </label>



                                                    <label className="containerColor">
                                                        <input type="checkbox" />
                                                        <span className="checkmark" style={{ background: "#FF6D84" }}></span>
                                                    </label>



                                                    <label className="containerColor">
                                                        <input type="checkbox" />
                                                        <span className="checkmark" style={{ background: "#212121" }}></span>
                                                    </label>


                                                    <label className="containerColor">
                                                        <input type="checkbox" />
                                                        <span className="checkmark" style={{ background: "#FF0000" }}></span>
                                                    </label>


                                                    <label className="containerColor">
                                                        <input type="checkbox" />
                                                        <span className="checkmark" style={{ background: "#FFDD6D" }}></span>
                                                    </label>


                                                    <label className="containerColor">
                                                        <input type="checkbox" />
                                                        <span className="checkmark" style={{ background: "#B197D0" }}></span>
                                                    </label>

                                                </div>
                                            </div>


                                            <div className="FilterItem">
                                                <h4>{this.state.i18n.Sizes} </h4>
                                                <div className="select-size">
                                                    <input type="checkbox" name="s-size" id="small" />
                                                    <input type="checkbox" name="s-size1" id="medium" />
                                                    <input type="checkbox" name="s-size2" id="large" />
                                                    <input type="checkbox" name="s-size3" id="x-large" />
                                                    <input type="checkbox" name="s-size4" id="xx-large" />
                                                    <label for="small">S</label>
                                                    <label for="medium">M</label>
                                                    <label for="large">L</label>
                                                    <label for="x-large">XL</label>
                                                    <label for="xx-large">XXL</label>

                                                </div>
                                            </div>

                                        */}
                      </div>
                    )}
                    <div className="ImageOfferFilter">
                      {" "}
                      {/** Offer Image */}
                      <a href="">
                        <Img src="/img/Group643.png" width="100%" />
                      </a>
                    </div>{" "}
                    {/** Offer Image End */}
                  </div>
                  {/** Categories End  */}
                  <div className="col-lg-9 col-md-9 col-sm-9 col-xs-12 no_pad">
                    {" "}
                    {/** Products */}
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 no_pad">
                      {this.state.Block2 == false ? (
                        <>
                          {this.state.isProductDone ? (
                            <>
                              <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                <Skeleton height={300} />
                              </div>

                              <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                <Skeleton height={300} />
                              </div>

                              <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                <Skeleton height={300} />
                              </div>

                              <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                <Skeleton height={300} />
                              </div>

                              <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                <Skeleton height={300} />
                              </div>

                              <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                <Skeleton height={300} />
                              </div>
                            </>
                          ) : (
                            <>
                              {this.state.Products.map((product) => {
                                return (
                                  <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                    <Block
                                      id={product.id}
                                      title={product.title}
                                      favor={product.is_favor}
                                      price={product.price}
                                      image={product.image}
                                      onPress={() => {
                                        this.setState(
                                          {
                                            Options: [],
                                          },
                                          () => {
                                            this.Options(product.id);
                                          }
                                        );
                                      }}
                                      onClickFavor={() => {
                                        this.AddToFavour(product.id);
                                      }}
                                    />
                                  </div>
                                );
                              })}
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          {this.state.isProductDone ? (
                            <>
                              <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                <Skeleton height={300} />
                              </div>

                              <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                <Skeleton height={300} />
                              </div>

                              <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                <Skeleton height={300} />
                              </div>

                              <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                <Skeleton height={300} />
                              </div>

                              <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                <Skeleton height={300} />
                              </div>

                              <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                <Skeleton height={300} />
                              </div>
                            </>
                          ) : (
                            this.state.Products.map((product) => {
                              return (
                                <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 Block2">
                                  <Block2
                                    id={product.id}
                                    favor={product.is_favor}
                                    title={product.title}
                                    price={product.price}
                                    image={product.image}
                                    onPress={() => {
                                      this.setState(
                                        {
                                          Options: [],
                                        },
                                        () => {
                                          this.Options(product.id);
                                        }
                                      );
                                    }}
                                    onClickFavor={() => {
                                      this.AddToFavour(product.id);
                                    }}
                                  />
                                </div>
                              );
                            })
                          )}
                        </>
                      )}

                      {this.state.scrollDataFlag ? (
                        <div
                          className="col-lg-12 col-md-12 col-sm-12 col-xs-12 no_pad"
                          style={{ textAlign: "center" }}
                        >
                          <Img
                            src={"/img/loads2.gif"}
                            placeholder={"/img/loads2.gif"}
                            error={"/img/loads2.gif"}
                            className="card-img-top image"
                          />
                        </div>
                      ) : null}
                    </div>
                    {/** Add To Cart Modal Start */}
                    <div className="modal fade CartModal" id="myModal">
                      <div className="modal-dialog">
                        <div className="modal-content">
                          <div className="modal-header">
                            <button
                              type="button"
                              className="close"
                              data-dismiss="modal"
                              aria-hidden="true"
                            >
                              ×
                            </button>
                            <h5 className="modal-title">
                              {this.state.i18n.AddToCart}
                            </h5>
                          </div>
                          <div className="container"></div>
                          <div className="modal-body">
                            <ul className="OptionCart">
                              {this.state.Options.map((option) => {
                                return (
                                  <div>
                                    <li key={option.id}>
                                      <h5>
                                        {option.title}
                                        <br /> <br />
                                      </h5>
                                      <a
                                        onClick={() =>
                                          this.state.user_id !== "0"
                                            ? this.AddToCart(option.pid)
                                            : (window.location.href = "/Login")
                                        }
                                      >
                                        <div>
                                          {/* {this.state.i18n.JOD} */}
                                          { localStorage.getItem("Currency")}
                                          {(option.price*localStorage.getItem("CurrentCurrency")).toFixed(2)}
                                        </div>
                                      </a>
                                    </li>
                                  </div>
                                );
                              })}
                            </ul>
                          </div>

                          <div className="modal-footer">
                            <a
                              className="btn btn-primary ResPas"
                              data-dismiss="modal"
                            >
                              {this.state.i18n.ContinueShopping}
                            </a>

                            <a
                              className="btn btn-primary ResPas"
                              onClick={() => (window.location.href = "/Cart")}
                            >
                              {this.state.i18n.GoToCart}
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/** ِAdd To Cart  Modal End */}
                  </div>
                </div>{" "}
                {/**  Stores Products End */}
              </div>
            </div>
          </div>{" "}
          {/** Stores Div End */}
        </div>{" "}
        {/** Stores Section End */}
      </div> /** Wrapper Div  End */
    );
  }

  /****************** Functions  ****************/

  AddToFavour(id) {
    fetch(Global.api_link + "AddRemoveFavorite", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: Global.token,
        pid: id,
        uid: this.state.user_id,
      }),
    })
      .then((response) => response.json())
      .then((result) => {
        swal({
          title: this.state.i18n.DoneSuccess,
          text: this.state.i18n.AddedToFavourite,
          icon: "success",
          button: this.state.i18n.OK,
        });
        this.getProducts();
        setTimeout(100);
      });
  }

  CartCount() {
    fetch(Global.api_link + "getCartCount", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: Global.token,
        user_id: this.state.user_id,
      }),
    })
      .then((response) => response.json())
      .then((result) => {
        this.setState(
          {
            cartNum: result.data,
          },
          () => {}
        );
      });
  }

  AddToCart(id) {
    fetch(Global.api_link + "addToCart", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: Global.token,
        product_id: id,
        user_id: this.state.user_id,
        qty: 1,
      }),
    })
      .then((response) => response.json())
      .then((result) => {
        swal({
          title: this.state.i18n.DoneSuccess,
          text: this.state.i18n.ProductCart,
          icon: "success",
          button: this.state.i18n.OK,
        });

        this.CartCount();
      });
  }

  /**   Category Product */
  getProducts() {
  
    fetch(Global.api_link + "getSingleCategory", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: Global.token,
        lang: this.state.language,
        category_id: this.state.CatID,
        Min:
          parseInt(this.state.value.min) != 0
            ? parseInt(this.state.value.min)
            : -1,
        Max:
          parseInt(this.state.value.max) != 0
            ? parseInt(this.state.value.max)
            : -1,
        activePage: parseInt(this.state.activePage),
        Sort: parseInt(this.state.SortPrice),
        user_id: this.state.user_id,
      }),
    })
      .then((response) => response.json())
      .then((result) => {
       
        if (this.state.activePage == 1) {
          this.setState({
            Products: result.data,
            isProductDone: false,
            scrollDataFlag: false,
          });
        } else {
          if (result.data.length > 0) {
            let old_data = this.state.Products;
            let new_data = old_data.concat(result.data);
            this.setState({
              Products: new_data,
              isProductDone: false,
              scrollDataFlag: false,
            });
          } else {
            this.setState({
              no_more_data: true,
              scrollDataFlag: false,
            });
          }
        }
      });
  }

  /**   Category Product */
  getProductsAscending() {
    fetch(Global.api_link + "getProductsAscending", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: Global.token,
        lang: this.state.language,
        category_id: this.state.CatID,
        Min: this.state.value.min ? parseInt(this.state.value.min) : -1,
        Max: this.state.value.max ? parseInt(this.state.value.max) : -1,
        activePage: parseInt(this.state.activePage),
        Sort: parseInt(this.state.SortPrice),
        user_id: this.state.user_id,
      }),
    })
      .then((response) => response.json())
      .then((result) => {
        if (this.state.activePage == 1) {
          this.setState({
            Products: result.data,
            isProductDone: false,
            scrollDataFlag: false,
          });
        } else {
          if (result.data.length > 0) {
            let old_data = this.state.products;
            let new_data = old_data.concat(result.data);
            this.setState({
              Products: new_data,
              isProductDone: false,
              scrollDataFlag: false,
            });
          } else {
            this.setState({
              no_more_data: true,
              scrollDataFlag: false,
            });
          }
        }
      });
  }

  getCategories() {
    /** Home Page Categories */
    fetch(Global.api_link + "getCategories", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: Global.token,
        lang: this.state.language,
      }),
    })
      .then((response) => response.json())
      .then((result) => {
        this.setState({
          Categories: result.data,
          CategoryDone: false,
        });
      });
    /** Home Page Categories End */
  }

  LinkHandel(Cat) {
    if (Cat.childs.length > 0) {
      this.setState({
        HasChild: !this.state.HasChild
      });
      if(this.state.selectCats == Cat){
        this.setState({
          selectCats: null
        });
      }else{
        this.setState({
          selectCats: Cat,
        });
      }
    } else {
      window.location.href = "/Categories/" + Cat.id + "/1";
    }
  }


  Options(PID) {
    fetch(Global.api_link + "OfferDetails", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: Global.token,
        lang: this.state.language,
        offer_id: PID,
        user_id: this.state.user_id,
      }),
    })
      .then((response) => response.json())
      .then((result) => {
        this.setState({
          Options: result.Options,
          isPageLoad: false,
          PID: PID,
        });
        //console.log(result);
      });
  }

  getSingleCategoryPricesFilter() {
    fetch(Global.api_link + "getSingleCategoryPricesFilter", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: Global.token,
        category_id: this.state.CatID,
      }),
    })
      .then((response) => response.json())
      .then((result) => {
        this.setState({
          value: {
            min: result.MinValue.price ? parseInt(result.MinValue.price) : 0,
            max: result.MaxValue.price ? parseInt(result.MaxValue.price) : 0,
          },
          maxVal: result.MaxValue.price
            ? parseInt(result.MaxValue.price)
            : 1000,
          minVal: result.MinValue.price ? parseInt(result.MinValue.price) : 0,
          Age: {
            min: 0,
            max: 80,
          },
          isPageLoadPrice: false,
        });
      });
    }

  handleScroll() {
    const windowHeight =
      "innerHeight" in window
        ? window.innerHeight
        : document.documentElement.offsetHeight;
    const body = document.body;
    const html = document.documentElement;
    const docHeight = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight
    );
    const windowBottom = windowHeight + window.pageYOffset;
    if (
      windowBottom >= parseInt(docHeight) - 400 &&
      !this.state.isProductDone &&
      !this.state.no_more_data &&
      !this.state.scrollDataFlag
    ) {
      alert('l')
      this.setState(
        {
          scrollDataFlag: true,
          activePage: parseInt(parseInt(this.state.activePage) + 1),
        },
        () => {
          this.getProducts();
         // this.getCategories();
          //this.getSingleCategoryPricesFilter();
        }
      );
    } else {
    }
  }
}
