import React, { Component } from "react";
import Global from "../Global.json"; 

export default  class Success extends Component  {
    constructor(props) {
      super(props)
      // the initial application state
      this.state = { 
        rating : 3, 
        isPageLoad: false,
        DataArray: [],
        user_id: localStorage.getItem('user_id') ?localStorage.getItem('user_id') : 0,
        HomeProduct : [],
        AID : props.match.params.AID,
        language: localStorage.getItem('language') ? localStorage.getItem('language') : "english",
      }
    }
  

    componentWillMount() {
      if (this.state.language != "english") {
        this.setState({
          i18n: Global.ar
        });
      } else {
        this.setState({
          i18n: Global.en
        });
      } 
        
}
 
 
    Change() {
      localStorage.removeItem('language')
      localStorage.setItem('language' ,  "العربية")
      window.location.reload()
    }

    ChangeEn() {
      localStorage.removeItem('language')
      localStorage.setItem('language' ,  "english")
      window.location.reload()
    }
    

    render() { 
      if (this.state.isPageLoad) {

        return <div> 
            <div className="Loader">
                <img alt="JaDough" src="img/giphy.gif" />
            </div>
        </div>;
    }else {   
      return (
        <div> 
            <div className="LoginPage">
                <div className="container">
                    <div className="row" style={{justifyContent: 'center' , height:'500px' , alignItems : 'center'}}> 
                        <div className="  no_pad" >  
                          <div className="LoginForm About"> 
                            <h1>{this.state.i18n.ConfirmationOfSuccess}</h1>
                            <br/>
                            <h4 className="SuccessMsg">{this.state.i18n.Awesome}</h4> 
                            <br/>
                            {
                            <a className="SuccessOrder" href="/Profile#ORDER">{this.state.i18n.Review}</a> 
                            } 
                          </div> 
                        </div>
                    </div>
                </div>
            </div>
        </div> 
    ); 
  }
}
}

