import React, { Component } from "react";
import Img from "react-cool-img";
import Global from "../Global.json";
import swal from "sweetalert";
import { GoogleApiWrapper, Map, Marker, InfoWindow } from "google-maps-react";
//import LocationPicker from "react-location-picker";
import Skeleton from "react-loading-skeleton";
import { Form } from "react-bootstrap";
// import { Button, Modal } from "bootstrap";

/* Default position */
let defaultPosition = {
    lat: 31.963158,
    lng: 35.930359,
};

let isLoadMap = true;

export class Cart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pohneNumberInvaled: false,
            countries: [],
            user_id: localStorage.getItem("user_id") ? localStorage.getItem("user_id") : 0,
            Done2: false,
            Fill2: false,
            CityAreas: [],
            Fill: false,
            address: "Amman",
            area: [],
            Cities: [],
            position: {
                lat: 0,
                lng: 0,
            },
            user_location_id: 0,
            AddLoc: false,
            area: [],
            Cities: [],
            IsCartDone: true,
            ShowAlert: false,
            Latitude: 31.963158,
            Long: 35.930359,
            AllCart: [],
            AllOrders: [],
            area: [],
            Areas: [],
            Cities: [],
            TotalPrice: 0,
            QTYCart: 1,
            Cost: 0,
            prevStoreB: 0,
            LoadCash: false,
            show: false,
            language: localStorage.getItem("language") ? localStorage.getItem("language") : "english",
            zipCode: window.localStorage.getItem("currentCountry") ? JSON.parse(window.localStorage.getItem("currentCountry"))["callingCodes"][0] : "962",
            delivery: "",
            addressDesc: "",
        };

        // binding this to event-handler functions
        this.onMarkerClick = this.onMarkerClick.bind(this);
        this.onMapClick = this.onMapClick.bind(this);
        // Bind
        this.handleLocationChange = this.handleLocationChange.bind(this);
    }

    getCountries() {
        fetch(Global.api_link + "getCountries", {
            method: "POST",
            headers: {
                Accept: "application/json",
            },
            body: JSON.stringify({
                token: Global.token,
                lang: this.state.language,
            }),
        })
            .then((response) => response.json())
            .then((result) => {
                this.setState({
                    countries: result.data,
                });
            })
            .catch((error) => {
                //console.error(error);
            });
    }

    Change() {
        localStorage.removeItem("language");
        localStorage.setItem("language", "arabic");
        window.location.reload();
    }

    ChangeEn() {
        localStorage.removeItem("language");
        localStorage.setItem("language", "english");
        window.location.reload();
    }

    handleLocationChange({ position, address, places }) {
        // Set new location
        this.setState({ position, address });
    }

    onMarkerClick = (props, marker, e) => {
        this.setState({
            selectedPlace: props,
            activeMarker: marker,
            showingInfoWindow: true,
            windowProps: props,
        });
    };
    onMapClick = (props) => {
        if (this.state.showingInfoWindow) {
            this.setState({
                showingInfoWindow: false,
                activeMarker: null,
            });
        }
    };

    handleClose = () => this.setState({ show: false });
    handleShow = () => this.setState({ show: true });
    componentWillMount() {
        if (this.state.user_id != 0 && this.state.user_id && this.state.user_id != null && this.state.user_id != "null") {
            if (this.state.language != "english") {
                this.setState({
                    i18n: Global.ar,
                });
            } else {
                this.setState({
                    i18n: Global.en,
                });
            }
            this.Cart();
            this.getUserArea();
            // this.getCities();
            this.getCountries();
        } else {
            localStorage.removeItem("user_id");
            localStorage.setItem("user_id", 0);

            localStorage.setItem("redirectTo", "/Cart");
            window.location.href = "/Login";
        }
    }

    Cart() {
        fetch(Global.api_link + "getUserCartProducts", {
            method: "POST",
            headers: {
                Accept: "application/json",
            },
            body: JSON.stringify({
                token: Global.token,
                user_id: this.state.user_id,
                user_location_id: this.state.user_location_id,
                lang: this.state.language,
            }),
        })
            .then((response) => response.json())
            .then((result) => {
                console.log(result);
                this.setState({
                    AllCart: result.data,
                    TotalPrice: result.totalPrice,
                    Balance: result.Balance,
                    AmountCashBack: result.AmountCashBack,
                    IsCartDone: false,
                    storeDelivery: result.storeDelivery,
                    service: result.service,
                });

                this.Prices();
                //console.log(this.state.AllCart)
            })
            .catch((error) => {
                console.error(error);
            });
    }

    updateCartQTY(cart_id, qty) {
        fetch(Global.api_link + "updateCartQTY", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                token: Global.token,
                cart_id: cart_id,
                qty: qty,
            }),
        })
            .then((response) => response.json())
            .then((result) => {
                this.setState({
                    IsCartDone: false,
                });
                this.Cart();
                this.Prices();
            });
    }

    setCities(coutry_id) {}

    /** Cart Prices */
    Prices() {
        fetch(Global.mobile_epi_link + "getUserCheckoutamounts", {
            method: "POST",
            headers: {
                Accept: "application/json",
            },
            body: JSON.stringify({
                token: Global.mobile_token,
                user_id: this.state.user_id,
                lang: this.state.language,
            }),
        })
            .then((response) => response.json())
            .then((result) => {
                var thisTime = new Date().getTime();
                var refTxn = this.state.user_id + "_" + thisTime;

                this.setState({
                    Balance: result.balance,
                    cashBack: result.cashBack,
                    totalWithoutDiscount: result.totalWithoutDiscount,
                    deliveryCost: result.deliveryCost,
                    refTxn: refTxn,
                    productsAmount: result.productsAmount,
                    isPageLoad: false,
                    cash_available: result.cash_available,
                });

                this.RandomOffer();
            })
            .catch((error) => {
                //console.error(error);
            });
    }

    render() {

        return (
            <div>
                {" "}
                {/** Wrapper Div  Start */}
                <div>
                    {" "}
                    {/** Profile Section Start */}
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12  col-md-12 col-sm-12 col-xs-12  Profile no_pad">
                                <ul className="nav nav-tabs CartTabs " id="myTab">
                                    <li className="active">
                                        <a data-toggle="tab" data-toggle="tab" href="#cart">
                                            <img src="img/Layer1.png" alt="Sooq" /> {this.state.i18n.MyCart}
                                        </a>
                                    </li>
                                </ul>

                                <div className="col-lg-12  col-md-12 col-sm-12 col-xs-12  no_pad">
                                    <div className="">
                                        <div className="tab-content " id="tabs">
                                            {" "}
                                            {/**  Tabs Contect Start  */}
                                            <div className="tab-pane active" id="cart">
                                                {" "}
                                                {/** Cart Tab Start  */}
                                                {this.state.AllCart.length > 0 ? (
                                                    <>
                                                        <div className="col-lg-9 col-md-12 col-sm-12 col-xs-12 no_pad ">
                                                            {this.state.IsCartDone ? (
                                                                <>
                                                                    <div className="item">
                                                                        <Skeleton height={60} count={8} />
                                                                    </div>
                                                                </>
                                                            ) : (
                                                                <div className="BoxData">
                                                                    <div className="table-responsive">
                                                                        <table className="table table-borderless">
                                                                            <thead>
                                                                                <tr>
                                                                                    <th scope="col">{this.state.i18n.Products}</th>
                                                                                    <th scope="col">{this.state.i18n.Price}</th>
                                                                                    <th scope="col">{this.state.i18n.Count}</th>
                                                                                    <th scope="col">{this.state.i18n.Total}</th>
                                                                                    <th></th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {this.state.AllCart.length > 0
                                                                                    ? this.state.AllCart.map((Cart) => {
                                                                                          return (
                                                                                              <tr>
                                                                                                  <th scope="row cart-item__img-tit-container" style={{ display: "flex" }}>
                                                                                                      <Img alt="Sooq" placeholder={"img/loader.gif"} error={"img/loader.gif"} className="CartProdImg" src={Cart.option_image ? Global.image_link + Cart.option_image : Global.image_link + Cart.image} style={{ maxWidth: "100px" }} />
                                                                                                      <p className="cart-item__title" style={{}}>
                                                                                                          {Cart.main_title + " / " + Cart.title !== Cart.main_title ? Cart.title : null}
                                                                                                      </p>
                                                                                                  </th>
                                                                                                  <td>
                                                                                                      {/* {Cart.price}{" "} */}
                                                                                                      {/* {this.state.i18n.JOD} */}
                                                                                                      {(Cart.price * localStorage.getItem("CurrentCurrency")).toFixed(2)} {this.state.i18n[localStorage.getItem("Currency")]}
                                                                                                  </td>
                                                                                                  <td>
                                                                                                      <div className="form-group">
                                                                                                          <div className="quantity buttons_added">
                                                                                                              <input
                                                                                                                  type="button"
                                                                                                                  value="-"
                                                                                                                  className="minus"
                                                                                                                  onClick={() => {
                                                                                                                      if (this.state["Cart" + Cart.cid] > 1 || Cart.qty > 1) {
                                                                                                                          this.setState({
                                                                                                                              ["Cart" + Cart.cid]: parseInt(Cart.qty) - 1,
                                                                                                                          });
                                                                                                                          this.updateCartQTY(Cart.cid, parseInt(Cart.qty) - 1);
                                                                                                                      }
                                                                                                                  }}
                                                                                                              />
                                                                                                              <input
                                                                                                                  type="number"
                                                                                                                  step="1"
                                                                                                                  min="1"
                                                                                                                  max={Cart.poqty}
                                                                                                                  value={this.state["Cart" + Cart.cid] ? (parseInt(this.state["Cart" + Cart.cid]) < parseInt(Cart.poqty) ? (parseInt(this.state["Cart" + Cart.cid]) < 1 ? 1 : parseInt(this.state["Cart" + Cart.cid])) : parseInt(Cart.poqty)) : Cart.qty}
                                                                                                                  onBlur={this.state["Cart" + Cart.cid] < 1 ? 1 : this.state["Cart" + Cart.cid]}
                                                                                                                  name="qty"
                                                                                                                  className="input-text qty text"
                                                                                                                  size="4"
                                                                                                                  pattern=""
                                                                                                                  inputmode=""
                                                                                                                  onChange={(e) => {
                                                                                                                      if (parseInt(e.target.value) <= parseInt(Cart.poqty)) {
                                                                                                                          this.setState(
                                                                                                                              {
                                                                                                                                  ["Cart" + Cart.cid]: e.target.value,
                                                                                                                              },
                                                                                                                              () => {
                                                                                                                                  if (parseInt(["Cart" + Cart.cid]) > 0) {
                                                                                                                                      this.updateCartQTY(Cart.cid, this.state["Cart" + Cart.cid] ? parseInt(this.state["Cart" + Cart.cid]) : Cart.qty);
                                                                                                                                  }
                                                                                                                              }
                                                                                                                          );
                                                                                                                      }
                                                                                                                  }}
                                                                                                              />
                                                                                                              <input
                                                                                                                  type="button"
                                                                                                                  value="+"
                                                                                                                  className="plus"
                                                                                                                  onClick={() => {
                                                                                                                      if ((this.state["Cart" + Cart.cid] && parseInt(this.state["Cart" + Cart.cid]) < parseInt(Cart.poqty)) || parseInt(Cart.qty) < parseInt(Cart.poqty)) {
                                                                                                                          this.setState(
                                                                                                                              {
                                                                                                                                  ["Cart" + Cart.cid]: this.state["Cart" + Cart.cid] ? parseInt(this.state["Cart" + Cart.cid]) + 1 : parseInt(Cart.qty) + 1,
                                                                                                                              },
                                                                                                                              () => {
                                                                                                                                  this.updateCartQTY(Cart.cid, this.state["Cart" + Cart.cid] ? parseInt(this.state["Cart" + Cart.cid]) : Cart.qty);
                                                                                                                              }
                                                                                                                          );
                                                                                                                      }
                                                                                                                  }}
                                                                                                              />
                                                                                                          </div>
                                                                                                      </div>
                                                                                                  </td>
                                                                                                  <td>
                                                                                                      {" "}
                                                                                                      {(parseFloat(Cart.price * parseInt(Cart.qty)) * localStorage.getItem("CurrentCurrency")).toFixed(2)} {this.state.i18n[localStorage.getItem("Currency")]}
                                                                                                      {/* {this.state.i18n.JOD} */}
                                                                                                  </td>
                                                                                                  <td>
                                                                                                      <a onClick={() => this.DeleteFromCart(Cart.pid)}>
                                                                                                          <i className="fa fa-times" aria-hidden="true"></i>
                                                                                                      </a>
                                                                                                  </td>
                                                                                              </tr>
                                                                                          );
                                                                                      })
                                                                                    : null}
                                                                                <tr>
                                                                                    <td colSpan={5}>
                                                                                        <div className="ButtonCost">
                                                                                            <a
                                                                                                className="has-options-button ShowOp"
                                                                                                // onClick={this.handleShow}
                                                                                                data-toggle="modal"
                                                                                                data-target="#myModalDelivery"
                                                                                            >
                                                                                                {this.state.i18n.PlaceOrder}
                                                                                            </a>
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>

                                                                        {/** Request Delivery start  */}
                                                                        {/* <Modal
                                      show={this.state.show}
                                      onHide={this.handleClose}
                                    >
                                      <Modal.Header closeButton>
                                        <Modal.Title>Modal heading</Modal.Title>
                                      </Modal.Header>
                                      <Modal.Body>
                                        Woohoo, you're reading this text in a
                                        modal!
                                      </Modal.Body>
                                      <Modal.Footer>
                                        <Button
                                          variant="secondary"
                                          onClick={this.handleClose}
                                        >
                                          Close
                                        </Button>
                                        <Button
                                          variant="primary"
                                          onClick={this.handleClose}
                                        >
                                          Save Changes
                                        </Button>
                                      </Modal.Footer>
                                    </Modal> */}
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                        <div className="col-lg-3 col-md-12 col-sm-12 col-xs-12 Ads text-center">
                                                            {this.state.IsCartDone ? (
                                                                <>
                                                                    <div className="item">
                                                                        <Skeleton height={20} count={3} />
                                                                    </div>
                                                                </>
                                                            ) : (
                                                                <div className="TotalBox">
                                                                    <h3>{this.state.i18n.TotalPrice}</h3>
                                                                    <div className="table-responsive">
                                                                        <table className="table table-borderless">
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td>{this.state.i18n.TotalPrice}</td>
                                                                                    <td>
                                                                                        {parseFloat(this.state.TotalPrice * localStorage.getItem("CurrentCurrency")).toFixed(2)} {/* {this.state.i18n.JOD} */}
                                                                                        {this.state.i18n[localStorage.getItem("Currency")]}
                                                                                    </td>
                                                                                </tr>
                                                                                {/* <tr>
                                          <td>{this.state.i18n.Discount}</td>
                                          <td>
                                            <span className="Complet">
                                           0 {this.state.i18n[localStorage.getItem("Currency")]}
                                            </span>
                                          </td>
                                        </tr> */}
                                                                                <tr>
                                                                                    <td>{this.state.i18n.Service}</td>
                                                                                    <td>
                                                                                        <span className="Hold">
                                                                                            {" "}
                                                                                            {parseFloat(this.state.service * localStorage.getItem("CurrentCurrency")).toFixed(3)} {/* {this.state.i18n.JOD} */}
                                                                                            {this.state.i18n[localStorage.getItem("Currency")]}
                                                                                        </span>
                                                                                    </td>
                                                                                </tr>

                                                                                <tr>
                                                                                    <td>{this.state.i18n.Total}</td>
                                                                                    <td>
                                                                                        <strong>
                                                                                            {(
                                                                                                parseFloat(
                                                                                                    this.state.TotalPrice +
                                                                                                        0 +
                                                                                                        // parseFloat(
                                                                                                        //   this.state.storeDelivery
                                                                                                        // )

                                                                                                        +parseFloat(this.state.service)
                                                                                                ) * localStorage.getItem("CurrentCurrency")
                                                                                            ).toFixed(3)}{" "}
                                                                                            {this.state.i18n[localStorage.getItem("Currency")]}
                                                                                            {/* {this.state.i18n.JOD} */}
                                                                                        </strong>
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                            )}
                                                            <a className="BackShopping" href={"/Categories/" + "__All"}>
                                                                <Img src="img/Group660.png" width="100%" />
                                                            </a>
                                                        </div>
                                                    </>
                                                ) : (
                                                    <div className="col-md-12 col-sm-12 col-xs-12 no_pad EmptyCart ">
                                                        <Img src="img/cartempty.png" />
                                                    </div>
                                                )}
                                            </div>{" "}
                                            {/** Cart Tab End   */}
                                        </div>
                                        <div id={"myModalDelivery"} className="modal fade myModalDelivery modal-body-Verification" role="dialog">
                                            <div className="modal-dialog">
                                                <div className="modal-content ">
                                                    <div className="modal-header">
                                                        <h4 className="modal-title">{this.state.i18n.SelectLocation}</h4>
                                                        <button
                                                            type="button"
                                                            className="close"
                                                            data-dismiss="modal"
                                                            data-bs-target="#myModalDelivery"
                                                            onClick={() =>
                                                                this.setState({
                                                                    AddLoc: false,
                                                                    Methods: 0,
                                                                    Showoption: false,
                                                                    UserPhone: "",
                                                                    city_id_selected: "",
                                                                    AreaCityName: "",
                                                                    AreaCityName2: "",
                                                                })
                                                            }
                                                        >
                                                            &times;
                                                        </button>
                                                    </div>
                                                    <div className="modal-body modal-body-cart-ChooseLocation modal-add-loc__">
                                                        <div className="deliverySelect">
                                                            <div>
                                                                <a
                                                                    className="AddLoc AddLocCart mb-3"
                                                                    onClick={() =>
                                                                        this.setState({
                                                                            AddLoc: !this.state.AddLoc,
                                                                            Methods: 0,
                                                                            Showoption: false,
                                                                            UserPhone: "",
                                                                            city_id_selected: "",
                                                                            AreaCityName: "",
                                                                            AreaCityName2: "",
                                                                        })
                                                                    }
                                                                >
                                                                    {this.state.i18n.AddNewLoc}
                                                                </a>
                                                                {this.state.AddLoc == false ? (
                                                                    <div className="form-group">
                                                                        <label>{this.state.i18n.AreaSelect}</label> <br />
                                                                        <select
                                                                            className="form-control"
                                                                            name="gender"
                                                                            onChange={(area) =>
                                                                                this.setState(
                                                                                    {
                                                                                        Area: area.target.value,
                                                                                    },
                                                                                    () => this.DeliveryCost()
                                                                                )
                                                                            }
                                                                        >
                                                                            <option value={0} disabled selected>
                                                                                {this.state.i18n.SelectAre}
                                                                            </option>
                                                                            {this.state.area.map((area) => {
                                                                                return (
                                                                                    <option key={area.id} value={area.id}>
                                                                                        {area.name}
                                                                                    </option>
                                                                                );
                                                                            })}
                                                                        </select>
                                                                        <p className="mt-2">{this.state.delivery === "" ? "" : `${this.state.i18n.DeliveryCost} : ${this.state.delivery} ${this.state.i18n[localStorage.getItem("Currency")]}`}</p>
                                                                        {/** Area Delivery Price End*/}
                                                                    </div>
                                                                ) : (
                                                                    <div className="row">
                                                                        <br />
                                                                        {/* Country */}
                                                                        <div className="form-group col-md-6 col-sm-6 col-xs-12 mt-3">
                                                                            <label>{this.state.i18n.Country}</label> <br />
                                                                            <select
                                                                                style={{ height: "auto" }}
                                                                                className="form-control"
                                                                                name="country"
                                                                                onChange={(country) => {
                                                                                    this.getCities(country.target.value);
                                                                                    this.getCityAreas(country.target.value);
                                                                                    this.setState({
                                                                                        city_id_selected: country.target.value,
                                                                                    });
                                                                                    this.getAreaName(country.target.value);
                                                                                }}
                                                                            >
                                                                                <option value={0}>{this.state.i18n.Choose}</option>
                                                                                {this.state.countries.map((country) => {
                                                                                    return (
                                                                                        <option key={country.id} value={country.id}>
                                                                                            {country.name}
                                                                                        </option>
                                                                                    );
                                                                                })}
                                                                            </select>
                                                                        </div>

                                                                        {/* Cities */}
                                                                        <div className="form-group col-md-6 col-sm-6 col-xs-12 mt-3">
                                                                            <label>{this.state.i18n.City}</label> <br />
                                                                            <select
                                                                                className="form-control"
                                                                                name="City"
                                                                                onChange={(city) => {
                                                                                    this.getCityAreas(city.target.value);
                                                                                    this.setState({
                                                                                        city_id_selected: city.target.value,
                                                                                    });
                                                                                    this.getAreaName(city.target.value);
                                                                                }}
                                                                            >
                                                                                <option value={0}>{this.state.i18n.Choose}</option>
                                                                                {this.state.Cities.map((city) => {
                                                                                    return (
                                                                                        <option key={city.id} value={city.id}>
                                                                                            {city.name}
                                                                                        </option>
                                                                                    );
                                                                                })}
                                                                            </select>
                                                                        </div>

                                                                        <div className="form-group col-md-6 col-sm-6 col-xs-12">
                                                                            <label>{this.state.i18n.Area}</label> <br />
                                                                            <select
                                                                                className="form-control"
                                                                                name="gender"
                                                                                onChange={(area) => {
                                                                                    this.setState({
                                                                                        AreaCity: area.target.value,
                                                                                        //AreaCityName: area.target.value
                                                                                    });

                                                                                    this.getAreaName2(area.target.value, this.state.city_id_selected);
                                                                                }}
                                                                            >
                                                                                <option value={0}>{this.state.i18n.SelectAre}</option>
                                                                                {this.state.CityAreas.map((area) => {
                                                                                    return (
                                                                                        <option key={area.name} value={area.id}>
                                                                                            {area.name}
                                                                                        </option>
                                                                                    );
                                                                                })}
                                                                            </select>
                                                                        </div>

                                                                        <div className="form-group  col-md-6 col-sm-6 col-xs-12">
                                                                            <label>{this.state.i18n.LocName}</label>
                                                                            <input
                                                                                className="form-control"
                                                                                type="text"
                                                                                ref="name"
                                                                                name="name"
                                                                                onChange={(name) => {
                                                                                    this.setState({
                                                                                        LocationName: name.target.value,
                                                                                    });
                                                                                }}
                                                                            />
                                                                        </div>

                                                                        <div className="form-group  col-md-6 col-sm-6 col-xs-12">
                                                                            <label>{this.state.i18n.building}</label>
                                                                            <input
                                                                                className="form-control"
                                                                                type="text"
                                                                                ref="Building"
                                                                                name="Building"
                                                                                onChange={(building) => {
                                                                                    this.setState({
                                                                                        building: building.target.value,
                                                                                    });
                                                                                }}
                                                                            />
                                                                        </div>

                                                                        <div className="form-group  col-md-6 col-sm-6 col-xs-12">
                                                                            <label>{this.state.i18n.Floor}</label>
                                                                            <input
                                                                                className="form-control"
                                                                                type="text"
                                                                                ref="Floor"
                                                                                name="Floor"
                                                                                onChange={(Floor) => {
                                                                                    this.setState({
                                                                                        Floor: Floor.target.value,
                                                                                    });
                                                                                }}
                                                                            />
                                                                        </div>

                                                                        <div className="form-group  col-md-6 col-sm-6 col-xs-12">
                                                                            <label>{this.state.i18n.Appartment}</label>
                                                                            <input
                                                                                className="form-control"
                                                                                type="text"
                                                                                ref="Appartment"
                                                                                name="Appartment"
                                                                                onChange={(Appartment) => {
                                                                                    this.setState({
                                                                                        Appartment: Appartment.target.value,
                                                                                    });
                                                                                }}
                                                                            />
                                                                        </div>

                                                                        <div className="form-group  col-md-6 col-sm-6 col-xs-12">
                                                                            <label>{this.state.i18n.Phone}</label>

                                                                            <div
                                                                                style={{
                                                                                    width: "100%",
                                                                                    display: "flex",
                                                                                }}
                                                                            >
                                                                                <input
                                                                                    onInput={({ target }) => (target.value = Math.max(0, parseInt(target.value)).toString().slice(0, 9))}
                                                                                    className="form-control m-0 mr-0"
                                                                                    type="text"
                                                                                    style={{
                                                                                        borderBottomLeftRadius: "0",
                                                                                        borderTopLeftRadius: "0",
                                                                                        width: "100%",
                                                                                    }}
                                                                                    placeholder={this.state.i18n.Phone}
                                                                                    name="phone"
                                                                                    onChange={(phone) => {
                                                                                        this.setState({
                                                                                            UserPhone: +phone.target.value,
                                                                                        });
                                                                                    }}
                                                                                    value={
                                                                                        isNaN(this.state.UserPhone)
                                                                                            ? () => {
                                                                                                  this.setState({ UserPhone: "" });
                                                                                                  return this.state.UserInfo;
                                                                                              }
                                                                                            : this.state.UserPhone
                                                                                    }
                                                                                />
                                                                            </div>
                                                                            {
                                                                                this.state.pohneNumberInvaled === true && <small style={{color: "red", fontSize: "13px"}}>
                                                                                Invaled Phone Number
                                                                            </small>
                                                                            }
                                                                            
                                                                        </div>

                                                                        {/* <label style={{ margin: "8px 15px" }}>{this.state.i18n.MapLabel}</label> */}
                                                                        <div className="form-group  col-md-12 col-sm-12 col-xs-12">
                                                                            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                                                                <Form.Label>{this.state.i18n.addressDescription}</Form.Label>
                                                                                <Form.Control
                                                                                    as="textarea"
                                                                                    rows={4}
                                                                                    style={{ resize: "none" }}
                                                                                    ref="addressDesc"
                                                                                    name="addressDesc"
                                                                                    onChange={({ target }) => {
                                                                                        this.setState({
                                                                                            addressDesc: target.value,
                                                                                        });
                                                                                    }}
                                                                                />
                                                                            </Form.Group>
                                                                            {/* <Map
                                        google={this.props.google}
                                        style={{ width: '100%', height: 300 }}
                                        initialCenter={defaultPosition}
                                        containerStyle={{
                                          width: '100%', height: 300, position: 'relative'
                                        }}
                                        zoom={15}
                                        // onClick={this.onMapClicked}
                                        onReady={this.handleLocationChange}
                                        center={this.state.position ? this.state.position : defaultPosition}
                                      >
                                        <Marker
                                        id={1}
                                          //title={'The marker`s title will appear as a tooltip.'}
                                          // name={'SOMA'}
                                          draggable={true}
                                          position={this.state.position ? this.state.position : defaultPosition} 
                                          onDragend={this.handleLocationChange}
                                        
                                          />
                                      </Map> */}
                                                                            {/*
                                        isLoadMap ?
                                        
                                          <LocationPicker
                                            containerElement={
                                              <div
                                                style={{
                                                  height: "100%",
                                                }}
                                              />
                                            }
                                            mapElement={
                                              <div
                                                style={{
                                                  height: "400px",
                                                }}
                                              />
                                            }
                                            defaultPosition={defaultPosition}
                                            onChange={this.handleLocationChange}
                                            circleOptions={{
                                              visible: false,
                                            }}
                                            zoom={15}
                                          />
                                          :
                                          null*/}
                                                                        </div>

                                                                        <br />
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="modal-footer ">
                                                        {this.state.Fill ? (
                                                            <div className="form-group  col-md-12 col-sm-12 col-xs-12 Done Wrong ">
                                                                <span>{this.state.i18n.Required}</span>
                                                            </div>
                                                        ) : null}
                                                        {this.state.AddLoc ? (
                                                            <button type="button" className="btn btn-default" onClick={() => this.AddArea()}>
                                                                {this.state.i18n.Add}
                                                            </button>
                                                        ) : this.state.Area !== 0 && this.state.Area != null ? (
                                                            <>
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-default has-options-button ShowOpDenark loc-btn w-100 p-3"
                                                                    style={{ fontSize: "12px" }}
                                                                    onClick={() => {
                                                                        this.OrderByCreditCard();
                                                                    }}
                                                                >
                                                                    {this.state.i18n.PlaceOrderByCreditCard}
                                                                </button>

                                                                <a className="has-options-button ShowOpDenark text-center w-100 " data-toggle="modal" data-target="#myModalDenark">
                                                                    {this.state.i18n.PlaceOrderByDenark}
                                                                </a>
                                                            </>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/** Request Delivery Modal End */}
                                        {/** Denark start  */}

                                        <div id={"myModalDenark"} className="modal fade myModalDenark modal-body-Verification" role="dialog">
                                            <div className="modal-dialog">
                                                <div className="modal-content ">
                                                    <div className="modal-header">
                                                        <h4 className="modal-title">{this.state.i18n.DenarkWallet}</h4>
                                                        <button
                                                            type="button"
                                                            className="close"
                                                            data-dismiss="modal"
                                                            onClick={() =>
                                                                this.setState({
                                                                    AddLoc: false,
                                                                    Methods: 0,
                                                                    Showoption: false,
                                                                })
                                                            }
                                                        >
                                                            &times;
                                                        </button>
                                                    </div>
                                                    <div className="modal-body">
                                                        <div className="deliverySelect">
                                                            <div>
                                                                <div className="row">
                                                                    <div className="form-group  col-md-6 col-sm-6 col-xs-12">
                                                                        <label>{this.state.i18n.WalletID}</label>
                                                                        <input
                                                                            className="form-control"
                                                                            type="text"
                                                                            ref="phone"
                                                                            name="phone"
                                                                            onChange={(phone) => {
                                                                                this.setState({
                                                                                    PhoneDenark: phone.target.value,
                                                                                });
                                                                            }}
                                                                        />
                                                                    </div>

                                                                    <div className="form-group  col-md-6 col-sm-6 col-xs-12">
                                                                        <label>{this.state.i18n.OTP}</label>
                                                                        <input
                                                                            className="form-control"
                                                                            type="text"
                                                                            ref="code"
                                                                            name="code"
                                                                            onChange={(code) => {
                                                                                this.setState({
                                                                                    codeDenark: code.target.value,
                                                                                });
                                                                            }}
                                                                        />
                                                                    </div>

                                                                    <br />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="modal-footer ">
                                                        {!this.state.LoadCash ? (
                                                            <button
                                                                type="button"
                                                                className="btn btn-default"
                                                                onClick={() =>
                                                                    this.setState(
                                                                        {
                                                                            LoadCash: true,
                                                                        },
                                                                        () => {
                                                                            this.OrderCashDenark();
                                                                        }
                                                                    )
                                                                }
                                                            >
                                                                {this.state.i18n.Confirm}
                                                            </button>
                                                        ) : (
                                                            <span>
                                                                <img alt="Loader" src="/img/loads2.gif" />
                                                            </span>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/** Denark Modal End */}
                                    </div>{" "}
                                    {/**  Tabs Contect End   */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>{" "}
                {/** Profile Section End */}
            </div> /** Wrapper Div  End */
        );
    }

    /************* Functions  ******************/

    /** Delete From Cart  */
    DeleteFromCart(oid) {
        fetch(Global.api_link + "DeleteFromCart", {
            method: "POST",
            headers: {
                Accept: "application/json",
            },
            body: JSON.stringify({
                token: Global.token,
                user_id: this.state.user_id,
                product_id: oid,
            }),
        })
            .then((response) => response.json())
            .then((result) => {
                this.setState({
                    Loader: true,
                });
                this.Cart();
            })
            .catch((error) => {
                //console.error(error);
            });
    }
    /** Delete From Cart End */

    AddToCart(id) {
        fetch(Global.api_link + "addToCart", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                token: Global.token,
                product_id: id,
                user_id: this.state.user_id,
                qty: 1,
            }),
        })
            .then((response) => response.json())
            .then((result) => {
                swal({
                    title: this.state.i18n.DoneSuccess,
                    text: this.state.i18n.ProductCart,
                    icon: "success",
                    button: this.state.i18n.OK,
                });
            });
    }

    /*** Cash On Delivery ***/
    OrderCash() {
        fetch(Global.mobile_epi_link + "QpayPaymentComplete", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                token: Global.mobile_token,
                user_id: this.state.user_id,
                amount: parseFloat(this.state.TotalPrice + parseFloat(this.state.storeDelivery) + parseFloat(this.state.service)).toFixed(2),
                amountOrginal: this.state.productsAmount,
                txn_ref: this.state.refTxn,
                paymentMethod: 2,
                location_id: this.state.Area,
                service: this.state.service,
            }),
        })
            .then((response) => response.json())
            .then((result) => {
                this.setState({
                    LoadCash: false,
                });
                window.location.href = "/Success";
            })
            .catch((error) => {
                //console.log(error);
            });
    }

    OrderByCreditCard() {
        fetch(Global.api_link + "createCreditCardOrder", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                token: Global.token,
                user_id: this.state.user_id,
                amount: parseFloat(this.state.TotalPrice + parseFloat(this.state.storeDelivery) + parseFloat(this.state.service)).toFixed(2),
                txn_ref: this.state.refTxn,
                location_id: this.state.Area,
                service: this.state.service,
            }),
        })
            .then((response) => response.json())
            .then((result) => {
                if (result.data == false) {
                    swal({
                        title: this.state.i18n.Error,
                        text: this.state.i18n.somethingWrong,
                        icon: "warning",
                        button: this.state.i18n.OK,
                    });
                } else {
                    window.location.href = "https://my.souqjo.net/Webapi/getPaymentLink?txn_ref=" + this.state.refTxn;
                }
            })
            .catch((error) => {
                //console.log(error);
            });
    }

    getUserArea() {
        fetch(Global.api_link + "getUserArea", {
            method: "POST",
            headers: {
                Accept: "application/json",
            },
            body: JSON.stringify({
                token: Global.token,
                user_id: this.state.user_id,
                lang: this.state.language,
            }),
        })
            .then((response) => response.json())
            .then((result) => {
                this.setState({
                    area: result.data,
                });
            })
            .catch((error) => {
                //console.error(error);
            });
    }

    getCities(country_id) {
        fetch(Global.api_link + "getCities", {
            method: "POST",
            headers: {
                Accept: "application/json",
            },
            body: JSON.stringify({
                token: Global.token,
                lang: this.state.language,
                country_id: country_id,
            }),
        })
            .then((response) => response.json())
            .then((result) => {
                this.setState({
                    Cities: result.data,
                });
            })
            .catch((error) => {
                //console.error(error);
            });
    }

    /***  Delivery Cost */
    DeliveryCost() {
        if (this.state.Area != null) {
            fetch(Global.mobile_epi_link + "getDeliveryCostNew", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                },
                body: JSON.stringify({
                    token: Global.mobile_token,
                    user_location: this.state.Area,
                }),
            })
                .then((response) => response.json())
                .then((result) => {
                    this.setState({
                        Cost: result.data,
                        prevStoreB: result.prevStoreB,
                        delivery: result.deivery,
                    });
                })
                .catch((error) => {
                    console.error(error);
                });
        } else {
            swal({
                title: this.state.i18n.Error,
                text: this.state.i18n.WrongPass,
                icon: "warning",
                button: this.state.i18n.OK,
            });
        }
    }
    /***  Delivery Cost End */

    /*** City Areas */
    getCityAreas(cid) {
        fetch(Global.api_link + "getCityAreas", {
            method: "POST",
            headers: {
                Accept: "application/json",
            },
            body: JSON.stringify({
                token: Global.token,
                lang: this.state.language,
                city_id: cid,
            }),
        })
            .then((response) => response.json())
            .then((result) => {
                this.setState({
                    CityAreas: result.data,
                });
            })
            .catch((error) => {
                console.error(error);
            });
    }

    validPhoneNumberRegx(number) {
        const regx = /^[0-9]{9}$/;
        if (!regx.test(number)) {
            this.setState({
                isInvalidPhoneNumber: true,
            });
        }

        return regx.test(number);
    }

    /** Add Area */
    AddArea() {
        const { building, Floor, Appartment, UserPhone, addressDesc } = this.state;
        if (this.state.LocationName != null && this.state.AreaCity != null && building != "" && Floor != "" && Appartment != "" && this.validPhoneNumberRegx(UserPhone) && addressDesc != "" && this.state.position.lat != 0) {
            fetch(Global.api_link + "AddArea", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                },
                body: JSON.stringify({
                    token: Global.token,
                    user_id: this.state.user_id,
                    area_id: this.state.AreaCity,
                    name: this.state.LocationName,
                    // address: building ? "Building : " + building : Floor ? "Floor : " + Floor : Appartment ? "Appartment : " + Appartment : "Amman",
                    address: `Address Description : ${addressDesc}, Building : ${building}, Floor : ${Floor}, Appartment : ${Appartment}, Phone :${UserPhone}`,
                    latitude: this.state.position.lat,
                    longitude: this.state.position.lng,
                }),
            })
                .then((response) => response.json())
                .then((result) => {
                    this.getUserArea();
                    this.setState({
                        AddLoc: false,
                        user_location_id: result.data,
                    });

                    this.getUserArea();
                })
                .catch((error) => {
                    console.error(error);
                });
        } else {
            if(!this.validPhoneNumberRegx(UserPhone)) {
                this.setState({
                    pohneNumberInvaled: true
                })
            } else {
                swal({
                    title: this.state.i18n.Error,
                    text: this.state.i18n.Required,
                    icon: "warning",
                    button: this.state.i18n.OK,
                });
            }
            setTimeout(100);
        }
    }
    /** Add Area End */

    getAreaName(AreaCity) {
        fetch(Global.api_link + "getCities", {
            method: "POST",
            headers: {
                Accept: "application/json",
            },
            body: JSON.stringify({
                token: Global.token,
                lang: this.state.language,
            }),
        })
            .then((response) => response.json())
            .then((result) => {
                let areaname = "";
                result.data.map((area) => {
                    if (area.id == AreaCity) {
                        areaname = area.name;
                        this.setState(
                            {
                                AreaCityName: areaname,
                                AreaCityName2: null,
                            },
                            () => {
                                this.SearchOnMap();
                                console.log(this.state.AreaCityName);
                            }
                        );
                    }
                });
            })
            .catch((error) => {
                //console.error(error);
            });
    }
    getAreaName2(AreaCity, cid) {
        console.log(AreaCity);
        fetch(Global.api_link + "getCityAreas", {
            method: "POST",
            headers: {
                Accept: "application/json",
            },
            body: JSON.stringify({
                token: Global.token,
                lang: this.state.language,
                city_id: cid,
            }),
        })
            .then((response) => response.json())
            .then((result) => {
                let areaname = "";
                result.data.map((area) => {
                    if (area.id == AreaCity) {
                        areaname = area.name;
                        this.setState(
                            {
                                AreaCityName2: areaname,
                            },
                            () => {
                                this.SearchOnMap();
                                console.log(this.state.AreaCityName);
                            }
                        );
                    }
                });
            })
            .catch((error) => {
                //console.error(error);
            });
    }

    SearchOnMap() {
        console.log(this.state.AreaCityName);
        let areaname = this.state.AreaCityName2 ? this.state.AreaCityName2 : "";
        let fullname = areaname ? this.state.AreaCityName + ", " + areaname : this.state.AreaCityName;
        fetch("https://maps.googleapis.com/maps/api/geocode/json?address=Jordan, " + fullname + "&key=AIzaSyDwMMc_5DQ0ZZEs9Gqb1SKuVd3MOjrSsIE", {
            method: "POST",
            headers: {
                Accept: "application/json",
            },
        })
            .then((response) => response.json())
            .then((result) => {
                defaultPosition = {
                    lat: result.results[0].geometry.location.lat,
                    lng: result.results[0].geometry.location.lng,
                };
                // Set new location
                this.setState({ position: defaultPosition });
            })
            .catch((error) => {
                console.error(error);
            });
    }
    /** Send Code */
    OrderCashDenark() {
        if (this.state.PhoneDenark && this.state.codeDenark) {
            fetch(Global.api_link + "Denark", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                },
                body: JSON.stringify({
                    user_id: this.state.user_id,
                    amount: parseFloat(this.state.TotalPrice + 0 + parseFloat(this.state.storeDelivery) + parseFloat(this.state.service)).toFixed(2),
                    wallet_id: this.state.PhoneDenark,
                    OTP: this.state.codeDenark,
                }),
            })
                .then((response) => response.json())
                .then((result) => {
                    console.log(result);
                    if (result !== null) {
                        if (result.status.id == 1) {
                            this.OrderCash();
                        } else {
                            this.setState({
                                LoadCash: false,
                            });
                            swal({
                                title: this.state.i18n.Error,
                                text: result.status.errorMessage,
                                icon: "warning",
                                button: this.state.i18n.OK,
                            }).then(() => window.location.reload());
                        }
                    } else {
                        swal({
                            title: this.state.i18n.Error,
                            text: this.state.i18n.Error,
                            icon: "warning",
                            button: this.state.i18n.OK,
                        }).then(() => window.location.reload());

                        this.setState({
                            LoadCash: false,
                        });
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        } else {
            swal({
                title: this.state.i18n.Error,
                text: this.state.i18n.Required,
                icon: "warning",
                button: this.state.i18n.OK,
            });
            this.setState({
                LoadCash: false,
            });
        }
    }
}

export default GoogleApiWrapper({
    apiKey: "AIzaSyDwMMc_5DQ0ZZEs9Gqb1SKuVd3MOjrSsIE",
})(Cart);
