import React, { Component } from "react";
import FacebookLogin from "react-facebook-login";
import { GoogleLogin } from "react-google-login";
import Global from '../Global.json';
import swal from 'sweetalert';
import ReCAPTCHA from "react-google-recaptcha";
import { Link } from "react-router-dom";

export default class Login extends Component {
  constructor(props) {
    super(props)
    this.state = {
      user_id: localStorage.getItem('user_id'),
      language: localStorage.getItem('language') ? localStorage.getItem('language') : "english",
      Fill: false,
      Captcha : false,
      code: null,
      WrongEmail: false,
      invalidEmail: false,
      passwordResetTabs: 1
    }
  }


  Change() {
    localStorage.removeItem('language')
    localStorage.setItem('language', "arabic")
    window.location.reload()
  }

  ChangeEn() {
    localStorage.removeItem('language')
    localStorage.setItem('language', "english")
    window.location.reload()
  }


  componentWillMount() {
    if(window.localStorage.getItem("user_id") != 0) {
      window.location.href = "/"
    }
    if (this.state.language !== "english") {
      this.setState({
        i18n: Global.ar
      });
    } else {
      this.setState({
        i18n: Global.en
      });
    }
  }


  responseFacebook = response => {
    // console.log(response);
    if (response.id) {
      fetch(Global.api_link + "facebookLogin", {
        method: "POST",
        headers: {
          Accept: "application/json"
        },
        body: JSON.stringify({
          token: Global.token,
          email: response.email ? response.email : "",
          name: response.name ? response.name : "",
          image: response.image ? response.image : "",
          facebook_id: response.id ? response.id : ""
        })
      })
        .then(response => response.json())
        .then(async result => {
          await localStorage.setItem("user_id", result.data);
          this.handleBack();
          // console.log(response);
        })
        .catch(error => {
          //console.error(error);
        });
    }
  };

  responseGoogle = response => {
    //console.error(response);
    if (response.googleId) {
      fetch(Global.api_link + "googleLogin", {
        method: "POST",
        headers: {
          Accept: "application/json"
        },
        body: JSON.stringify({
          token: Global.token,
          email: response.profileObj.email ? response.profileObj.email : "",
          name: response.profileObj.givenName
            ? response.profileObj.givenName +
            " " +
            response.profileObj.familyName
            : "",
          google_id: response.profileObj.googleId
            ? response.profileObj.googleId
            : ""
        })
      })
        .then(response => response.json())
        .then(result => {
          localStorage.setItem("user_id", result.data);
          this.handleBack();
        })
        .catch(error => {
          //console.error(error);
        });
    }
  };

  onChange(value) { 
    console.log("Captcha value:", value); 
  }

  validEmailRegex(email) {
    const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!re.test(String(email).toLowerCase())) {
        this.setState({
            isInvalidEmail: true,
        });
    }
    return re.test(String(email).toLowerCase());
}

  render() {

    return (
      <div> {/** Wrapper Div  Start */}

        <div> {/** Login  Start */}
          <div className="LoginPage">
            <div className="container">
              <div className="row">
                <div className="col-lg-12  col-md-12 col-sm-12 col-xs-12 LoginForm " >

                  <h3>{this.state.i18n.WelcomeBack}</h3>
                  <h1>{this.state.i18n.SignInTo}</h1>
                  <span>{this.state.i18n.LoginQues} <a href="/Signup">{this.state.i18n.Registration}</a></span>

                  <div className="Form">
                    <div className="form-group">
                      <input type="email" ref="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder={this.state.i18n.Email} onChange={e => {
                        this.setState({ email: e.target.value, Fill: false });
                      }} />
                    </div>
                    <div className="form-group">
                      <input type="password" ref="password" className="form-control" id="exampleInputPassword1" placeholder={this.state.i18n.Password} onChange={e => {
                        this.setState({ password: e.target.value, Fill: false });
                      }} />
                    </div>
                    <div className="form-check">
                      <a data-toggle="modal"
                        data-target={"#myModal"}
                        onPointerOver={() => {
                          //alert('aa');
                          this.setState({
                            passwordResetTabs: 1,
                            VerCode: null,
                            ReEmail: "",
                            WrongEmail: false
                          });
                        }}>{this.state.i18n.ForgetPass}</a>
                    </div>
                    <br />
                    {this.state.Fill ? (
                      <div className="form-group  col-md-12 col-sm-12 col-xs-12 Done Wrong ">
                        <span>{this.state.i18n.Wrong}</span>
                      </div>
                    ) : null}

                    {/* <ReCAPTCHA
                      sitekey="6LceXR0bAAAAAMYcmJDUSkEmxRHLCkNrn5ac2kFJ"
                      onChange={this.onChange()}
                    /> */}

                    {this.state.email != null && this.state.password && this.state.Captcha != null ? (
                      <button type="submit" className="btn btn-primary" onClick={this.handleSignIn.bind(this)}>{this.state.i18n.Login}</button>
                    ) : null}
                  </div>




                  <div className="SocialLogin">
                    <h2>{this.state.i18n.SocialLogin}</h2>
                    <ul className="">
                      <li>
                        <FacebookLogin
                          appId="624444132044654"
                          autoLoad={false}
                          fields="id,name,email,picture"
                          callback={this.responseFacebook}
                          className="facebookLgin"
                          isMobile={false}
                          icon="fa-facebook"
                          textButton={this.state.i18n.Facebook}
                        />
                      </li>
                      <li><GoogleLogin
                        clientId="1052289206560-qqv5jpurf5aso88ib4qimsd1n76in16u.apps.googleusercontent.com"
                        buttonText={this.state.i18n.Google}
                        onSuccess={this.responseGoogle}
                        onFailure={(error) => { console.log(error) }}
                        cookiePolicy={"single_host_origin"}
                        className="GoogleLoginIcon"
                        style={{fontFamily: "RegularRoman"}}
                      /></li>

                    </ul>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div> {/** Login  End */}
        {/** Modal Forget Password */}

        <div className="modal fade" id="myModal">
          <div className="modal-dialog">
            <div className="modal-content">
              <div class="modal-header modal-header-forgetPassword">
                
              <h4 class="modal-title">
                  {this.state.i18n.ResetPassword}
                </h4>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-hidden="true"
                >
                  ×
                          </button>
                
              </div>
              <div class="container"></div>
              {this.state.passwordResetTabs == 1 ? (
                <div class="modal-body modal-body-Verification modal-body-forgetPassword p-3">
                  <h2>{this.state.i18n.ForgetPassword}</h2>
                  <p>{this.state.i18n.ForgottenText} </p>

                  <div className="form-group NewPass">
                    <label> {this.state.i18n.Email} </label>
                    <input
                      className="form-control"
                      type="email"
                      name="phone"
                      placeholder={this.state.i18n.Email}
                      onChange={e => {
                        this.setState({
                          ReEmail: e.target.value,
                          WrongEmail: false,
                          invalidEmail: false
                        });
                      }}
                      value={this.state.ReEmail}
                    />
                    {
                      this.state.invalidEmail && <smal style={{color: "red"}}>{this.state.i18n.invalidEmail}</smal>
                    }
                    
                  </div>

                  {this.state.ReEmail != null &&
                    this.state.WrongEmail == false ? (
                      <>
                      {/* <a
                        data-toggle="modal"
                        href="#myModal2"
                        class="btn btn-primary"
                        onClick={() => this.CheckCode()}
                      >
                        {this.state.i18n.SendVerificationCode}
                      </a> */}
                     <div className="ResPas-container"><Link to="#myModal2"
                      data-toggle="modal"
                      className="btn ResPas"
                      onClick={() => this.CheckCode()}
                    >
                      {this.state.i18n.SendVerificationCode}
                    </Link></div>
                    </>
                  ) : this.state.WrongEmail == true ? (
                    <h5>{this.state.i18n.EmailFound}</h5>
                  ) : null}
                </div>
              ) : this.state.passwordResetTabs == 2 ? (
                <div class="modal-body modal-body-Verification p-0">
                  <div className="form-group p-3">
                    <label>
                      {this.state.i18n.VerificationCode}
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      name="code"
                      placeholder="Verification Code"
                      onChange={e => {
                        this.setState({ VerCode: e.target.value });
                      }}
                    />
                  </div>
                  {this.state.code == this.state.VerCode ? (
                    <a
                      //data-toggle="modal"
                      //href="#myModal3"
                      class="btn ResPas m-3"
                      onClick={() =>
                        this.setState({ passwordResetTabs: 3 })
                      }
                    >
                      {this.state.i18n.VerificationCode}
                    </a>
                  ) : this.state.VerCode ? (
                    <h5>{this.state.i18n.CodeFound}</h5>
                  ) : null}
                </div>
              ) : this.state.passwordResetTabs == 3 ? (
                <div>
                  <div class="modal-body modal-body-Verification  p-3">


                    <div className="form-group ">
                      <label>{this.state.i18n.NewPassword} </label>
                      <input
                        className="form-control"
                        type="password"
                        name="phone"
                        placeholder={this.state.i18n.Password}
                        onChange={e => {
                          this.setState({
                            OPassword: e.target.value
                          });
                        }}
                      />
                    </div>

                    <div className="form-group NewPass ">
                      <label>

                        {this.state.i18n.RewritePassword}
                      </label>
                      <input
                        className="form-control"
                        type="password"
                        name="phone"
                        placeholder={this.state.i18n.Password}
                        onChange={e => {
                          this.setState({
                            NewPassword: e.target.value
                          });
                        }}
                      />
                      {this.state.Password != null ? (
                        this.state.Password ==
                          this.state.NewPassword ? (
                          <h5>
                            <i class="fa fa-check"></i>
                          </h5>
                        ) : (
                          <h5>
                            <i class="fa fa-times"></i>
                          </h5>
                        )
                      ) : null}
                    </div>

                    {this.state.Fill2 ? (
                      <div className="form-group  col-md-12 col-sm-12 col-xs-12 Done Wrong ">
                        <span>{this.state.i18n.WrongPass}</span>
                      </div>
                    ) : null}
                  </div>
                </div>
              ) : null}

              {this.state.passwordResetTabs == 3 ? (
                <>
                <div class="modal-footer">
                  <a
                    class="btn ResPas pt-0"
                    onClick={() => this.ChangePass()}
                  >
                    {this.state.i18n.ChangePassword}
                  </a>
                </div>
                
              </>
              ) : null}
            </div>
          </div>
        </div>
        {/** Modal Password */}

      </div >/** Wrapper Div  End */
    );
  }

  /*** Functions  ***/
  handleBack = async () => {
    //this.props.history.goBack();
    let redirectTo = await localStorage.getItem("redirectTo");
        if(redirectTo && redirectTo != ""){
          await localStorage.removeItem("redirectTo");
            window.location.href = redirectTo;
        }else{
            window.location.href = "/";
        }
  };

  handleSignIn(e) {
    this.setState({ isPageLoad: true })
    e.preventDefault();
    let email = this.refs.email.value;
    let password = this.refs.password.value;

    fetch(Global.api_link + "userLogin", {
      method: "POST",
      headers: {
        Accept: "application/json"
      },
      body: JSON.stringify({
        token: Global.token,
        email: email,
        password: password
      })
    })
      .then(response => response.json())
      .then(result => {



        if (result.data !== false) {

          if (result.isFound !== false) {
            this.setState({
              email: result.data.email,
              password: result.data.password
            });

            localStorage.setItem("user_id", result.data);
            this.handleBack();
            this.setState({
              isPageLoad: false
            })

          } else {
            swal({
              title: this.state.i18n.blockedUser,
              icon: "warning",
              button: this.state.i18n.OK,
            })
          }
        } else {
          this.setState({
            Fill: true,
            isPageLoad: false
          });
        }
      })
      .catch(error => {
        //console.error(error);
      });
  }

  responseFacebook = response => {
    //console.log(response);
    if (response.id) {
      fetch(Global.api_link + "facebookLogin", {
        method: "POST",
        headers: {
          Accept: "application/json"
        },
        body: JSON.stringify({
          token: Global.token,
          email: response.email ? response.email : "",
          name: response.name ? response.name : "",
          image: response.image ? response.image : "",
          facebook_id: response.id ? response.id : ""
        })
      })
        .then(response => response.json())
        .then(async result => {
          await localStorage.setItem("user_id", result.data);
          this.handleBack();
          //console.log(response);
        })
        .catch(error => {
          //console.error(error);
        });
    }
  };

  responseGoogle = response => {
    //console.error(response);
    if (response.googleId) {
      fetch(Global.api_link + "googleLogin", {
        method: "POST",
        headers: {
          Accept: "application/json"
        },
        body: JSON.stringify({
          token: Global.token,
          email: response.profileObj.email ? response.profileObj.email : "",
          image: response.profileObj.imageUrl ? response.profileObj.imageUrl : "",
          name: response.profileObj.givenName
            ? response.profileObj.givenName +
            " " +
            response.profileObj.familyName
            : "",
          google_id: response.profileObj.googleId
            ? response.profileObj.googleId
            : ""
        })
      })
        .then(response => response.json())
        .then(result => {
          localStorage.setItem("user_id", result.data);
          this.handleBack();
          //console.log(response.profileObj.imageUrl)
        })
        .catch(error => {
          //console.error(error);
        });
    }
  };


  /**Check Email Code */
  CheckCode() {

    if(this.validEmailRegex(this.state.ReEmail)) {
      fetch(Global.api_link + "sendEmailOTP", {
        method: "POST",
        headers: {
          Accept: "application/json"
        },
        body: JSON.stringify({
          token: Global.token,
          email: this.state.ReEmail
        })
      })
        .then(response => response.json())
        .then(result => {
          this.setState({
            code: result.data
          });

          if (result.data == 0) {
            this.setState({
              WrongEmail: true
            });
          } else {
            this.setState({
              passwordResetTabs: 2
            });
          }
          console.log(this.state.code)
        })
        .catch(error => {
          console.error(error);
        });
    } else {
      this.setState({
        invalidEmail: true
      })
    }

    // if (this.state.ReEmail != null) {
    // } else {
    //   this.setState({
    //     Fill: true
    //   });
    // }
  }

  /**Check Email Code */

  /**Change Passwword */
  ChangePass() {
    // if (this.state.email)
    if (this.state.OPassword == this.state.NewPassword) {
      fetch(Global.api_link + "resetPasswordConfirm", {
        method: "POST",
        headers: {
          Accept: "application/json"
        },
        body: JSON.stringify({
          token: Global.token,
          email: this.state.ReEmail,
          password: this.state.NewPassword
        })
      })
        .then(response => response.json())
        .then(result => {
          this.setState({
            Fill2: false
          });

          swal({
            title: this.state.i18n.DoneSuccess,
            text: this.state.i18n.Done,
            icon: "success",
            button: this.state.i18n.OK,
          })

          setTimeout(function () {
            window.location.reload();
          }, 1000);
        })
        .catch(error => {
          console.error(error);
        });
    } else {
      this.setState({
        Fill2: true
      });
    }
  }

}

